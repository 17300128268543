import { buryPoint } from '@/apis/common/buryPoint'
import { addFeedback, getFeedback } from '@/apis/common/feedback'
import { ADSORPTION_RANGE } from '@/data/constant'
import { webId } from '@/util/configure'
import { expandPath } from '@/util/dealPlankDataFuncs'
import {
  BooleanOperations,
  Polygon,
  Relations,
  point,
} from '@/util/flattenJS/index.cjs'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import {
  addIdForItems as addIdForItemsDefault,
  diffList as diffListDefault,
  genUniId,
  getUrlParams,
} from '@yige/utils'
import { Modal } from 'ant-design-vue'
import ElementUI from 'element-ui'

import store from '../store'
import { dealRecord } from './buryPointDeal'
import ClipperLib from './clipper_unminified'
import { getCrashPlankByActivePlank } from './plankCommonFuncs'

export { arrMapObj, genUniId, getUrlParams } from '@yige/utils'

// import { getUrlVars } from './commonFun'
// blob转为base64
export function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = (reader?.result + '').split(',')[1]
      resolve(base64String)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

// import { Message } from 'element-ui'
// 对象数组排序
export function compare(propertyName, type, propertyName2) {
  return function (object1, object2) {
    var value1 = Number(object1[propertyName])
    var value2 = Number(object2[propertyName])
    if (type == 'up') {
      if (value1 == value2 && propertyName2) {
        const value3 = Number(object1[propertyName2])
        const value4 = Number(object2[propertyName2])
        return value4 - value3
      }
      return value1 - value2
    } else if (type == 'down') {
      if (value1 == value2 && propertyName2) {
        const value3 = Number(object1[propertyName2])
        const value4 = Number(object2[propertyName2])
        return value3 - value4
      }
      return value2 - value1
    }
  }
}

// 保留有效数字
export function toDecimal(num, decimal) {
  if (typeof num == 'string') {
    num = Number(num)
  }
  num = num.toFixed(decimal)
  num = Number(num)
  return num
}

// 打印当前的内容
export function currentLog(...content) {
  for (let i = 0; i < content.length; ++i) {
    if (typeof content[i] != 'undefined') {
      content[i] = JSON.parse(JSON.stringify(content[i]))
    }
  }
}

// 判断两个值的大小
export function compareTowNum(num1, num2, type) {
  var a = Number(num1)
  var b = Number(num2)
  if (type === '<=') {
    if (a < b || Math.abs(a - b) < 0.0001) return true
  }
  if (type === '>=') {
    if (a > b || Math.abs(a - b) < 0.0001) return true
  }
  if (type === '=') {
    if (Math.abs(a - b) < 0.0001) return true
  }
  if (type === '<') {
    if (a < b && Math.abs(a - b) > 0.0001) return true
  }
  if (type === '>') {
    if (a > b && Math.abs(a - b) > 0.0001) return true
  }
  return false
}

/** 处理Qt操作完成后的回调 */
export function handleQtCB(instance, cb, ...args) {
  if (typeof window.webToQt !== 'undefined') {
    window.signalFromQt.connect((res) => {
      if (res) {
        setTimeout(() => {
          cb.call(instance, ...args)
        })
      }
    })
  }
}

/**
 *
 * @param { string | number } val 值
 * @param { object } verifyObj 验证对象
 * @param { string[] } keys 验证的key
 * @param { string } rule 验证规则  2-3 俩位整数三位小数 4-2 四位整数两位小数
 * @param { boolean } isMinus 是否负数
 * @param {number} min 最小值/默认值
 * @param {boolean} isEmpty 是否允许为空
 */
export function verifyInputNumber(
  val,
  verifyObj,
  keys,
  rule,
  isMinus = false,
  min = 0,
  isEmpty = false,
  max = 0
) {
  val += ''
  val = val.replace(/[^\d\\.]/g, '') // 去掉除数字及.的其它字符
  let finalObj = verifyObj[keys[0]]
  if (keys.length == 1) {
    finalObj = verifyObj
  }
  let lastKey = keys.slice(-1)[0]
  // 删除最后一个key，获取到最后一个key所在的对象
  keys.length = keys.length - 1
  keys.forEach((key, idx) => {
    if (!idx) return
    finalObj = finalObj[key]
  })
  // 增加判断条件，防止在输入为空值时，赋值为0
  if ((!val || (!isMinus && val == '0-')) && !isEmpty) {
    finalObj[lastKey] = 0
    return 0
  }
  const ruleArr = rule.split('-')
  let res
  if (ruleArr.length > 1) {
    // 是否负数对应不同的正则
    const s = isMinus
      ? `(^\\-?)\\D*(\\d{0,${ruleArr[0]}})\\d*(\\.?)(\\d{0,${ruleArr[1]}})\\d*`
      : `\\D*(\\d{0,${ruleArr[0]}})\\d*(\\.?)(\\d{0,${ruleArr[1]}})\\d*`
    const d = isMinus ? '$1$2$3$4' : '$1$2$3'
    const reg = RegExp(s)
    res = val
      .replace(/\.+?/, '(')
      .replace(/\./g, '')
      .replace(/\(/, '.')
      .replace(reg, d)
      .replace(/[^\d\.\-]/, '')
      .replace(/\-+?/, '^')
      .replace(/\-/, '')
      .replace(/\^/, '-')
    // 最后一位是否是小数点,小数点直接返回不是则返回数字
    let strArr = res.split('.')
    strArr[0] = Number(strArr[0])
    res = res ? strArr.join('.') : min
  } else {
    // 整数输入限制
    res = val.replace(/[^\d]/g, '').substring(0, ruleArr[0])
    res = parseInt(res)
  }
  if (max && Number(res) > max) res = max
  finalObj[lastKey] = res
  return res
}

export function checkPlankDataIsCorrect(plank) {
  if (!plank) return
  // 检查大板和小板的stockNum是否一致，不一致将大板stockNum赋值给小板
  const plankStockNum = plank.stockNum
  plank.parts.forEach((part) => {
    const partStockNum = part.stockNum
    if (partStockNum != plankStockNum) {
      part.stockNum = plankStockNum
    }
  })
  return plank
}

// 柜门key对应的云排版字段
const _mapData = {
  成品尺寸: 'oRect',
  开料尺寸: 'rect',
  板材颜色: 'texture',
  材质: 'matCode',
}
export function mapGuimenToYPB(part, guimenKey, mapData) {
  mapData = mapData ? mapData : _mapData
  guimenKey.forEach((item) => {
    const { value, label } = item
    const partKey = mapData[label]
    if (partKey) {
      if (partKey == 'oRect' || partKey == 'rect') {
        part[value] = `${part[partKey].height}*${part[partKey].width}*1`
      } else {
        part[value] = part[partKey]
      }
    }
  })
  return part
}

/**
 * 比较两个列表，找出其中的新增项、删除项、剩余项（标记已编辑）
 * @param {*} newArr 新的数据
 * @param {*} oriArr
 * @param {*} keys 比较编辑
 * @param {*} idKey 唯一id
 */
export function diffList(newArr, oriArr, keys, idKey = webId) {
  return diffListDefault(newArr, oriArr, keys, idKey)
}

export function Message(msg) {
  if (typeof msg == 'string') {
    ElementUI.Message({ duration: 1500, offset: 60, message: msg })
  } else {
    ElementUI.Message({ duration: 1500, offset: 60, ...msg })
  }
}

/**
 * 生成操作的table
 * @param {*} addArr // 新增加的数组
 * @param {*} editArr // 编辑的数组
 * @param {*} deleteArr // 删除的数组
 * @param {*} realShowArr // 最终进行显示的数组（暂时应该包括leaveArr + addArr）
 * @param {*} type // 类型
 * @returns
 */
export function genOperateTable(addArr, editArr, deleteArr, realShowArr, type) {
  addArr = addArr.filter((item) => {
    return item
  })
  editArr = editArr.filter((item) => {
    return item
  })
  deleteArr = deleteArr.filter((item) => {
    return item
  })
  realShowArr = realShowArr.filter((item) => {
    return item
  })

  let nameTemp = ''
  const valueArr = []
  if (addArr.length && !editArr.length) {
    // 只有添加
    nameTemp = '添加'
  } else if (addArr.length && editArr.length) {
    // 既有添加又有编辑
    nameTemp = '添加/编辑'
  } else if (!addArr.length && editArr.length) {
    nameTemp = '编辑'
  }
  // 只有编辑项加增加项的长度大于0的时候，才表明有编辑的内容
  if (
    [...addArr, ...editArr].filter((child) => {
      return child
    }).length
  ) {
    if (type === 'hole_slot_table') {
      // 当是开料机设置的表格时，将内容进行聚合
      let arrHole = []
      let arrSlot = []
      realShowArr.forEach((item) => {
        if (item.item_type === 'hole') {
          arrHole.push(item)
        } else {
          arrSlot.push(item)
        }
      })
      realShowArr = [...arrHole, ...arrSlot]
    }
    valueArr.push({
      key: nameTemp,
      type: type,
      value: realShowArr,
    })
  }
  if (deleteArr.length) {
    valueArr.push({
      key: '删除',
      type: type,
      value: deleteArr,
    })
  }
  return valueArr
}

// 将对象中的属性顺序统一
const sortObj = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      acc[key] = obj[key]
      return acc
    }, {})
}

const diffDetail = (newList, oldList, id, options) => {
  id = id || 'webId'
  const diffList = {
    edit: [],
    add: [],
    delete: [],
  }
  const allExcludeKeys = options.excludeKeys.concat([
    'operate_type',
    'uniqueSign',
    'index',
    '_selected',
    'isOld',
  ])
  // 会默认删除掉operate_type属性
  const defaultOptions = {
    ...options,
    excludeKeys: allExcludeKeys,
  }
  const diffDetailList = {
    edit: [],
  }
  const { excludeKeys, includeKeys } = defaultOptions
  newList.forEach((newItem) => {
    const tempNewItem = JSON.parse(JSON.stringify(newItem))
    const oldItem = oldList.find((item) => item[id] === tempNewItem[id])
    // 没找到这个值
    if (!oldItem) {
      // 说明是新增的
      diffList.add.push({ ...newItem, operate_type: 'add' })
      return
    }
    const tempOldItem = JSON.parse(JSON.stringify(oldItem))
    // includeKeys和excludeKeys只能二选一，excludeKeys优先级更高
    if (excludeKeys?.length) {
      excludeKeys.forEach((key) => {
        delete tempNewItem[key]
        delete tempOldItem[key]
      })
    }
    if (includeKeys?.length && !excludeKeys?.length?.length < 4) {
      Object.keys(tempNewItem).forEach((key) => {
        if (!includeKeys.includes(key)) {
          delete tempNewItem[key]
        }
      })
      Object.keys(tempOldItem).forEach((key) => {
        if (!includeKeys.includes(key)) {
          delete tempOldItem[key]
        }
      })
    }

    // tmeNewItem和tempOldItem由于对象中的属性顺序不一样，所以在转成字符串进行比较时会出问题，因此需要先将两个对象中的相同属性的位置统一
    const sortTempNewItem = sortObj(tempNewItem)
    const sortTempOldItem = sortObj(tempOldItem)
    const isSimple =
      JSON.stringify(sortTempNewItem) === JSON.stringify(sortTempOldItem)
    if (tempOldItem && !isSimple) {
      diffList.edit.push([
        newItem,
        { ...oldItem, isOld: true, operate_type: 'edit' },
      ])
      const values = compareObj(tempNewItem, tempOldItem, {}, defaultOptions)
      if (Object.keys(values).length) diffDetailList.edit.push(values)
    }
    // else {
    //   diffList.add.push({ ...tempNewItem, type: 'add' })
    // }
  })
  oldList.forEach((oldItem) => {
    const newItem = newList.find(
      (item) => item[id] !== undefined && item[id] === oldItem[id]
    )
    if (!newItem) {
      diffList.delete.push({ ...oldItem, operate_type: 'delete' })
    }
  })
  return { diffList, diffDetailList }
}

// 比较两个对象是否相等，并且比较属性也是对象的情况
function compareObj(nweItem, oldItem, values = {}, options = {}) {
  const newValues = { ...values }
  // 合并配置，
  options = Object.assign({}, options)
  const { excludeKeys, tableIdentify, includeKeys } = options
  if (!nweItem || !oldItem) return values
  const tempNewItem = JSON.parse(JSON.stringify(nweItem))
  const tempOldItem = JSON.parse(JSON.stringify(oldItem))
  Object.keys(tempNewItem).forEach((key) => {
    if (tempNewItem[key] !== tempOldItem[key]) {
      if (
        Object.prototype.toString.call(tempNewItem[key]) ===
          '[object Object]' &&
        Object.prototype.toString.call(tempOldItem[key]) === '[object Object]'
      ) {
        compareObj(tempNewItem[key], tempOldItem[key], newValues, options)
      } else if (
        Array.isArray(tempNewItem[key]) &&
        Array.isArray(tempOldItem[key])
      ) {
        tempNewItem[key].forEach((item, index) => {
          compareObj(item, tempOldItem[key][index], newValues, options)
        })
      } else {
        newValues[nweItem[tableIdentify]] =
          newValues[nweItem[tableIdentify]] || []
        newValues[nweItem[tableIdentify]].push({
          key,
          oldValue: tempOldItem[key],
          value: tempNewItem[key],
        })
      }
    }
  })
  const newValue = Object.keys(newValues).map((key) => {
    return {
      key,
      value: values[key],
    }
  })
  return newValue
}
// 为items的每一项添加item
export function addIdForItems(items, idKey = webId, flag = '') {
  // 兼容之前写法，不修改原有的逻辑
  // if (change) return addIdForItemsDefault(items, idKey)
  // 生成不会更改的id
  if (Array.isArray(items)) {
    items.forEach((item) => {
      if (!item[idKey]) {
        item[idKey] = genUniId()
      }
      if (item.items) {
        addIdForItems(item.items, idKey, false)
      }
    })
  }
  return items
}

/**
 * 遍历compareMsg，取出keys里面的key，比较当前数据与原始数据是否发生变化
 * @param {*} current
 * @param {*} ori
 * @param {*} compareMsg  根据compareMsg里面的内容进行设置
 * { // 格式
      title: '工位开始',
      keys: ['autoSendStockCode', 'rollCode'],
    }
 * @param {*} record
 */
export function compareDiffForm(
  current,
  ori,
  compareMsg,
  formTextObj,
  formValueObj = {}
) {
  let record = []
  compareMsg.forEach((item) => {
    let arr = []
    item.keys.forEach((key) => {
      const currentItem =
        typeof current[key] === 'object'
          ? JSON.stringify(current[key])
          : current[key]
      const oriItem =
        typeof ori[key] === 'object' ? JSON.stringify(ori[key]) : ori[key]
      if (currentItem !== oriItem) {
        if (typeof current[key] === 'object' && !Array.isArray(current[key])) {
          Object.keys(current[key]).forEach((k) => {
            if (current[key][k] !== ori[key][k]) {
              arr.push({
                key: formValueObj[key][k],
                value: current[key][k],
                oldValue: ori[key][k] === undefined ? '' : ori[key][k],
              })
            }
          })
        } else if (Array.isArray(current[key])) {
          arr.push({
            key: formTextObj[key],
            value: currentItem,
            // 表单最后匹配的数据不同时，保存旧的值
            oldValue: oriItem === undefined ? '' : oriItem,
          })
        } else {
          const currentV = formValueObj[key]
            ? formValueObj[key][current[key]]
            : current[key]
          const oldV = formValueObj[key]
            ? formValueObj[key][ori[key]]
            : ori[key]

          arr.push({
            key: formTextObj[key],
            value: currentV,
            // 表单最后匹配的数据不同时，保存旧的值
            oldValue: oldV === undefined ? '' : oldV,
          })
        }
      }
    })
    if (arr.length) {
      record.push({
        key: item.title,
        value: arr,
      })
    }
  })
  return record
}

// 平铺对象，只平铺一层
export function flatObj(temp) {
  let tempFlat = {}
  // 将当前对象铺平
  for (let key in temp) {
    if (isPlainObject(temp[key])) {
      tempFlat = {
        ...tempFlat,
        ...temp[key],
      }
    } else {
      tempFlat[key] = temp[key]
    }
  }
  return tempFlat
}

/**
 * 给record添加操作表格记录
 * @param {*} newList // 新列表
 * @param {*} oriList // 旧列表
 * @param {*} keys // 要比较的keys
 * @param {*} tableType // 表格对应的type
 * @param {*} recordKeyDesc // 操作记录对应的key
 * @param {*} record // 记录
 */
export function addOperateTableToRecord(
  newList,
  oriList,
  keys = [],
  tableType,
  recordKeyDesc,
  record,
  idKey = '',
  tableIdentify = 'desc',
  excludeKeys = []
) {
  // const { addArr, deleteArr, leaveArr, editArr } = idKey
  //   ? diffList(newList, oriList, keys, idKey)
  //   : diffList(newList, oriList, keys)
  const {
    diffList: { edit, add, delete: deleteArr },
    diffDetailList: { edit: editDetail },
  } = diffDetail(newList, oriList, idKey, {
    tableIdentify,
    excludeKeys,
    includeKeys: keys,
  })
  // 生成最终的操作table
  // const valueArr = genOperateTable(
  //   addArr,
  //   [],
  //   deleteArr,
  //   [...leaveArr, ...addArr],
  //   tableType
  // )
  // if (valueArr.length) {
  //   record.push({
  //     key: recordKeyDesc,
  //     value: valueArr,
  //   })
  // }
  if (editDetail.length) {
    record.push({
      key: recordKeyDesc,
      value: editDetail,
    })
  }
  if (edit.length) {
    record.push({
      key: recordKeyDesc,
      value: [
        {
          key: '编辑',
          type: tableType,
          value: edit.flat(),
        },
      ],
    })
  }
  if (add.length) {
    record.push({
      key: recordKeyDesc,
      value: [
        {
          key: '新增',
          type: tableType,
          value: add.flat(),
        },
      ],
    })
  }
  if (deleteArr.length) {
    record.push({
      key: recordKeyDesc,
      value: [
        {
          key: '删除',
          type: tableType,
          value: deleteArr.flat(),
        },
      ],
    })
  }
}

// 比较操作item
function compareOperateItem(operateItem) {
  const record = []
  operateItem.dataArr.forEach((dataItem) => {
    if (dataItem.type === 'form') {
      // 处理form的情况
      const compareMsg = dataItem.compareMsg
      const formChangeArr = compareDiffForm(
        dataItem.current,
        dataItem.ori,
        compareMsg,
        dataItem.formTextObj,
        dataItem.formValueObj
      )

      if (formChangeArr.length) {
        record.push(...formChangeArr)
      }
    } else if (dataItem.type === 'table') {
      // 处理table情况
      addOperateTableToRecord(
        dataItem.list,
        dataItem.oriList,
        dataItem.keys,
        dataItem.tableType,
        dataItem.key,
        record,
        dataItem.idKey || 'webId', // 兼容大多数默认情况，使用webId
        dataItem.tableIdentify,
        dataItem.excludeKeys || []
      )
    }
  })

  return record
}

/**
 * 产生最终的操作记录
 * @param {*} recordData 
 * let recordData = {
      key: "", // 操作记录最外层的名字，比如：雕刻机
      operateArr: [ // 应对多个操作页面时，比如雕刻机的操作页面
        {
          key: "",
          dataArr: [ // 氛围表单和table两种
            ({
              type: "form",
              current, // 当前表单数据
              ori, // 原始表单数据
              compareMsg, // 要比较的内容
              formTextObj, // key对应的意思
              formValueObj, // value对应的意思
            },
            {
              type: "table",
              list, // 当前列表
              oriList, // 原始列表
              keys: [], // 列表要比较的keys
              tableType, // table 的 type
              key, // 表格对应的key
            }),
          ],
        },
      ],
    };
 * @param {*} cb 
 */
export function genFinalRecord(recordData, action_type, id, cb) {
  if (typeof id === 'function') {
    cb = id
  }
  let operateRecordArr = []
  const record = [
    {
      key: recordData.key,
      value: operateRecordArr,
    },
  ]
  // 遍历所有的操作页面
  recordData.operateArr.forEach((operateItem) => {
    const compareRecord = compareOperateItem(operateItem)
    if (compareRecord.length) {
      const buryPointArr = []
      compareRecord.forEach((item) => {
        if (item.key == '其他') {
          item.value.forEach((valueItem) => {
            switch (valueItem.key) {
              case '工位1自动上料代码':
                buryPointArr.push({
                  function_module: 'engraving',
                  function_point: 'autoSendStockCode',
                })
                break
              case '工位2自动上料代码':
                buryPointArr.push({
                  function_module: 'engraving',
                  function_point: 'evenAutoSendStockCode',
                })
                break
            }
          })
        }
        if (item.key == '台面设置') {
          item.value.forEach((valueItem) => {
            switch (valueItem.key) {
              case '大工位只加工小工位无法加工的大板':
                buryPointArr.push({
                  function_module: 'engraving',
                  function_point: 'smallStationFirst',
                })
                break
            }
          })
        }
      })
      if (buryPointArr.length) buryPoint(buryPointArr)
      operateRecordArr.push({
        key: operateItem.key,
        value: compareRecord,
      })
    }
  })
  if (operateRecordArr.length) {
    const setting_id = getUrlParams('setting_id')
    let finalId = setting_id
    if (typeof id === 'string' || typeof id === 'number') {
      finalId = id
    }
    const isForbidOss = false
    dealRecord(record)
    if (isForbidOss) {
      if (typeof cb === 'function') {
        cb()
      }
    } else {
      saveDataJsonToOssForRecord(
        record,
        action_type,
        { id: finalId }, // getUrlParams('line')是对应的生产线名字
        cb
      )
    }
  }
}
/**
 * 将genFinalRecord使用promise改写
 */
export function genFinalRecordPromise(recordData, action_type, id) {
  return new Promise((resolve, reject) => {
    try {
      if (typeof id === 'function') {
        cb = id
      }
      let operateRecordArr = []
      const record = [
        {
          key: recordData.key,
          value: operateRecordArr,
        },
      ]
      // 遍历所有的操作页面
      recordData.operateArr.forEach((operateItem) => {
        const compareRecord = compareOperateItem(operateItem)
        if (compareRecord.length) {
          const buryPointArr = []
          compareRecord.forEach((item) => {
            if (item.key == '其他') {
              item.value.forEach((valueItem) => {
                switch (valueItem.key) {
                  case '工位1自动上料代码':
                    buryPointArr.push({
                      function_module: 'engraving',
                      function_point: 'autoSendStockCode',
                    })
                    break
                  case '工位2自动上料代码':
                    buryPointArr.push({
                      function_module: 'engraving',
                      function_point: 'evenAutoSendStockCode',
                    })
                    break
                }
              })
            }
            if (item.key == '台面设置') {
              item.value.forEach((valueItem) => {
                switch (valueItem.key) {
                  case '大工位只加工小工位无法加工的大板':
                    buryPointArr.push({
                      function_module: 'engraving',
                      function_point: 'smallStationFirst',
                    })
                    break
                }
              })
            }
          })
          if (buryPointArr.length) buryPoint(buryPointArr)
          operateRecordArr.push({
            key: operateItem.key,
            value: compareRecord,
          })
        }
      })

      if (operateRecordArr.length) {
        const setting_id = getUrlParams('setting_id')
        let finalId = setting_id
        if (typeof id === 'string' || typeof id === 'number') {
          finalId = id
        }
        dealRecord(record)
        resolve({
          record,
          finalId,
          action_type,
        })
      }
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * 第三方设备权限
 * @param { string } path 第三方进入的路径
 * @param { boolean } auth 权限
 */
const verify = {
  '/labeling': 'labeling',
  '/dockFiveSix': 'dock56F',
}
export function thirdPermission(path) {
  let { edition_id, third_permissions } = store.state.userInfo
  const key = verify[path]
  let flag = true
  if (!key) return flag
  if (![5, 6, 14].includes(edition_id)) {
    flag = !!third_permissions[key]
  }
  if (!flag)
    Message({
      type: 'info',
      message: '暂无此权限, 请升级账号版本!',
      duration: 1500,
      offset: 70,
    })
  return flag
}

/**
 * 计算板件尺寸
 * @param { array } plank
 */
export function calcPlankSize(plank, isAbnormity = false) {
  const { xyReverse } = store.state.ncSetting

  if (isAbnormity) {
    const obj = plank.surplusInfo
    const xArr = Object.keys(obj)
      .filter((key) => key[0] === 'x')
      .map((key) => obj[key])
      .concat([0])
    const yArr = Object.keys(obj)
      .filter((key) => key[0] === 'y')
      .map((key) => obj[key])
      .concat([0])
    const maxX = Math.max(...xArr)
    const minX = Math.min(...xArr)
    const middleX = xArr.find((x) => x !== maxX && x !== minX)
    const maxY = Math.max(...yArr)
    const minY = Math.min(...yArr)
    const middleY = yArr.find((y) => y !== maxY && y !== minY)
    return {
      longW: plank.plankWidth,
      shortW: xyReverse ? middleY : middleX,
      longH: plank.plankHeight,
      shortH: xyReverse ? middleX : middleY,
    }
  } else {
    return { width: plank.plankWidth, height: plank.plankHeight }
  }
}

const modalIcon = {
  warning: 'question-circle',
  error: 'exclamation-circle',
  success: 'check-circle  ',
  info: 'info-circle',
}
export function modalMsg(
  msg,
  type = 'warning',
  title = '提示',
  cb = null,
  other = {},
  from
) {
  if (typeof msg === 'string') {
    msg =
      from === 'getLayoutData' && msg.includes('\n') ? msg.split('\n') : [msg]
  }
  const config = {
    title,
    type,
    icon: modalIcon[type],
    centered: true,
    content: (h) =>
      h('div', null, [
        ...msg.map((str, index) =>
          h(
            'p',
            from === 'getLayoutData' && index % 2 === 0
              ? { style: { color: 'red' } }
              : null,
            str
          )
        ),
      ]),
    okText: '确定',
    cancelButtonProps: { style: { display: 'none' } },
    onOk() {
      cb && cb()
    },
    ...other,
  }
  const modal = Modal.confirm(config)
  return modal
}

/**
 *  板件吸附功能
 * @param {array} activePlank 移动的板件
 * @param {array} parts 该大板内所有的板件
 */
export function calcClosePoint(activePlank, parts) {
  if (!parts.length) return
  const rect = [
    { x: activePlank.startX, y: activePlank.startY },
    {
      x: activePlank.startX + activePlank.rect.width,
      y: activePlank.startY,
    },
    {
      x: activePlank.startX,
      y: activePlank.startY + activePlank.rect.height,
    },
    {
      x: activePlank.startX + activePlank.rect.width,
      y: activePlank.startY + activePlank.rect.height,
    },
  ]
  const otherReact = parts
    .filter(
      (part) =>
        part.plankID !== activePlank.plankID || part.index !== activePlank.index
    )
    .map((part) => {
      return [
        { x: part.startX, y: part.startY },
        {
          x: part.startX + part.rect.width,
          y: part.startY,
        },
        {
          x: part.startX,
          y: part.startY + part.rect.height,
        },
        {
          x: part.startX + part.rect.width,
          y: part.startY + part.rect.height,
        },
      ]
    })
    .flat(2)
  let xArr = []
  let yArr = []
  rect.forEach((e) => {
    xArr.push(Math.min(...otherReact.map((o) => Math.abs(o.x - e.x))))
    yArr.push(Math.min(...otherReact.map((o) => Math.abs(o.y - e.y))))
  })
  const minX = Math.min(...xArr)
  const minY = Math.min(...yArr)
  const xPoints = Array.from(
    new Set(
      rect
        .map((e) => {
          return otherReact.filter((o) => Math.abs(o.x - e.x) == minX)
        })
        .flat(1)
    )
  )
  const yPoints = Array.from(
    new Set(
      rect
        .map((e) => {
          return otherReact.filter((o) => Math.abs(o.y - e.y) == minY)
        })
        .flat(1)
    )
  )
  // flagX  true说明移动板件处于右边 false 在左边
  let flagX = xPoints[0].x - activePlank.startX <= 0
  // flagY  true在下 false在上 （都是相对于startX,startY）
  let flagY = yPoints[0].y - activePlank.startY <= 0

  let x = rect[0].x
  let y = rect[0].y
  if (minX <= 20) {
    x = flagX
      ? xPoints[0].x
      : xPoints[0].x - activePlank.startX == minX //顶部靠近
      ? xPoints[0].x
      : xPoints[0].x - activePlank.rect.width
  }
  if (minY <= 20) {
    y = flagY
      ? yPoints[0].y
      : yPoints[0].y - activePlank.startY == minY //顶部靠近
      ? yPoints[0].y
      : yPoints[0].y - activePlank.rect.height
  }

  return {
    x,
    y,
  }
}
/**
 * 生成一个唯一的plankNum 使用需要在执行完成后清空set
 */
function createUniquePlankNum(part = null) {
  if (part && part.plankNum) return part.plankNum
  let finalPlankNum = ''
  do {
    let date = new Date()
    let time = date.getTime()
    let plankNum = ('' + time).slice(-12)
    let strArr = plankNum.split('')
    let num1 = 0
    let num2 = 0
    for (let i = 0; i < strArr.length; ++i) {
      if (i == 0) {
        if (strArr[i] == '0') {
          strArr[i] = Math.floor(Math.random() * 9 + 1)
        }
      }
      if ((i + 1) % 2) {
        num2 += Number(strArr[i])
      } else {
        num1 += Number(strArr[i])
      }
    }
    let finalNum = (10 - ((num1 * 3 + num2) % 10)) % 10
    strArr.push(finalNum)
    finalPlankNum = strArr.join('')
  } while (this.plankNumCollect.has(finalPlankNum))
  this.plankNumCollect.add(finalPlankNum)
  return finalPlankNum
}
export const uniquePlankNum = {
  plankNumCollect: new Set(),
  createUniquePlankNum,
}
/**
 *
 * @param { Array } arr 当前数组
 * @param {*} curIdx 当前下标
 * @param {*} next 需要取出的下标位数  如 -2 向后两位下标取值 2 向前两位下标取值
 * @returns index 找到的下标 下标对应的值
 */
export function getArrayItem(arr, curIdx, next) {
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw new Error('arguments[0] is not Array')
  }
  let index
  if (next < 0) {
    index = (arr.length + curIdx - Math.abs(next)) % arr.length
  } else {
    index = (curIdx + next) % arr.length
  }
  return { index, res: arr[index] }
}
// // 处理封边一类的信息在板件变化时的处理
// export function dealEdgeInfoChange(edgeInfo, way) {
//   if (!edgeInfo) return
//   let res
//   const [Left, Bottom, Right, Top] = edgeInfo
//     .split(/←|↓|→|↑/)
//     .filter((it) => it)
//   switch (way) {
//     case 'xyReverse':
//       res = '←' + Top + '↓' + Right + '→' + Bottom + '↑' + Left
//       break
//     case 90:
//       res = '←' + Bottom + '↓' + Right + '→' + Top + '↑' + Left
//       break
//     case 180:
//       res = '←' + Right + '↓' + Top + '→' + Left + '↑' + Bottom
//       break
//     case 'fan':
//       res = '←' + Left + '↓' + Top + '→' + Right + '↑' + Bottom
//       break
//   }
//   return res
// }
// // 处理前封边和开门在板件变化的处理
// const openMap = {
//   上开: '下开',
//   下开: '上开',
// }
// const edgeMap = {
//   '↑': '↓',
//   '↓': '↑',
// }
// export function dealFormerEdgeChange(former, way, isOpen = false) {
//   if (!former) return
//   const edge = isOpen ? ['左开', '下开', '右开', '上开'] : ['←', '↓', '→', '↑']
//   const idx = edge.findIndex((it) => it == former)
//   let next
//   let res
//   switch (way) {
//     case 90:
//       next = -1
//       break
//     case 180:
//       next = -2
//       break
//     case 'fan':
//       res = isOpen ? openMap[former] : edgeMap[former]
//       res = res ?? former
//       break
//   }
//   if (!res) {
//     const result = getArrayItem(edge, idx, next)
//     res = result.res
//   }
//   return res
// }
// 延时
export function delay(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

// 生成simplePlankNum
export function generateSimplePlankNum(part) {
  const { simplePlankNum: preSimplePlankNum, plankID, orderNo, roomID } = part
  if (preSimplePlankNum && preSimplePlankNum !== 'None') return
  let simplePlankNum = `000-${plankID}`
  if (orderNo && roomID) {
    const [first] = orderNo.split(/[*\-_/]/).slice(-1)
    const last = roomID.toString().substring(0, 2)
    simplePlankNum = `${first.substring(0, 3)}${last}-${plankID}`
  }
  // 生成正反面条码
  part.simplePlankNum = simplePlankNum
  part.simplePlankNumF = simplePlankNum + 'K'
}

// 生成simplePlankNum
export function generateSimplePlankNumFn(part) {
  const { simplePlankNum: preSimplePlankNum, plankID, orderNo, roomID } = part
  if (preSimplePlankNum && preSimplePlankNum !== 'None') return {}
  let simplePlankNum = `000-${plankID}`
  if (orderNo && roomID) {
    const [first] = orderNo.split(/[*\-_/]/).slice(-1)
    const last = roomID.toString().substring(0, 2)
    simplePlankNum = `${first.substring(0, 3)}${last}-${plankID}`
  }
  return {
    simplePlankNum,
    simplePlankNumF: simplePlankNum + 'K',
  }
}

// 获取原始顺序的宽高
export function getOriginRect(part, field = 'oRect') {
  const { specWidth, specHeight } = part
  if (!specHeight || !specWidth) return part[field]
  const { width, height } = part[field]
  const { width: checkWidth, height: checkHeight } = part.oRect
  return checkWidth - specWidth === checkHeight - specHeight
    ? { width, height }
    : { width: height, height: width }
}

// 是否是相同的板件
export function isSamePart(part1, part2) {
  return part1.partUniqueId === part2.partUniqueId
}
export function generatePlankId(parts) {
  let maxPlankId = Math.max(
    ...parts.map((item) => (+item.plankID ? item.plankID : -999))
  )
  if (maxPlankId == -999) maxPlankId = 0
  return maxPlankId + 1
}

/**
 * 以某个key对数组内数据进行归类
 * @param {array} data 数据源
 * @param {string} key 以此key进行聚类
 * @param {string} itemKey 被聚类的item存储的key
 * @param {Function} cb 每一步聚类的回调 参数：是否存在，当前数据，当前对象，当前key
 * @param {string} returnFormat 返回的数据格式
 * @returns
 */
export function dataClustering(
  data,
  key,
  itemKey,
  cb = null,
  returnFormat = 'Object'
) {
  let hash = {}
  for (const item of data) {
    const targetKey = item[key]
    const targetItemKey = item[itemKey]
    if (hash[targetKey]) {
      const target = hash[targetKey][targetItemKey]
      // 内部单个数据是否存在，存在则直接使用旧数据
      if (!target) {
        hash[targetKey][targetItemKey] = item
        cb && cb('notExists', item)
      } else {
        cb && cb('exists', target)
      }
    } else {
      // 整体不存在
      hash[targetKey] = {
        [targetItemKey]: item,
      }
      cb && cb(null, item)
    }
  }
  switch (returnFormat) {
    case 'Array':
      hash = Object.values(hash).map((it) => Object.values(it))
      break
  }
  return hash
}
export const buryPointApi = async (function_module, function_point) => {
  const buryPointData = {
    function_module,
    function_point,
  }

  await buryPoint(buryPointData)
}
export const addFeedbackApi = async (addFeedbackData) => {
  return await addFeedback(addFeedbackData)
}
export const getFeedbackApi = async (getFeedbackData) => {
  return await getFeedback(getFeedbackData)
}
export const judgeEnv = () => {
  let env
  const envObj = {
    test: 'https://test-api.eggrj.com/bluen',
    master: 'https://server.bluen.cn',
    online: 'https://online-api.eggrj.com/bluen',
  }
  for (const key in envObj) {
    if (process.env.VUE_APP_BASE_URL == envObj[key]) {
      env = key
    }
  }
  return env
}
export const getTryProductionRoomId = () => {
  const env = judgeEnv()
  let roomID
  switch (env) {
    case 'test':
      roomID = 3017767
      break
    case 'online':
      roomID = 14462333
      break
    case 'master':
      roomID = 17264591
      break
    default:
      roomID = 3017767
  }
  return roomID
}
export const canvas2Image = (function () {
  // check if support sth.
  let $support = (function () {
    let canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d')

    return {
      canvas: !!ctx,
      imageData: !!ctx.getImageData,
      dataURL: !!canvas.toDataURL,
      btoa: !!window.btoa,
    }
  })()

  let downloadMime = 'image/octet-stream'

  function scaleCanvas(canvas, width, height) {
    let w = canvas.width,
      h = canvas.height
    if (width == undefined) {
      width = w
    }
    if (height == undefined) {
      height = h
    }

    let retCanvas = document.createElement('canvas')
    let retCtx = retCanvas.getContext('2d')
    retCanvas.width = width
    retCanvas.height = height
    retCtx.drawImage(canvas, 0, 0, w, h, 0, 0, width, height)
    return retCanvas
  }

  function getDataURL(canvas, type, width, height) {
    canvas = scaleCanvas(canvas, width, height)
    return canvas.toDataURL(type)
  }

  function saveFile(strData) {
    document.location.href = strData
  }

  function genImage(strData) {
    let img = document.createElement('img')
    img.src = strData
    return img
  }
  function fixType(type) {
    type = type.toLowerCase().replace(/jpg/i, 'jpeg')
    let r = type.match(/png|jpeg|bmp|gif/)[0]
    return 'image/' + r
  }
  function encodeData(data) {
    if (!window.btoa) {
      throw 'btoa undefined'
    }
    let str = ''
    if (typeof data == 'string') {
      str = data
    } else {
      for (let i = 0; i < data.length; i++) {
        str += String.fromCharCode(data[i])
      }
    }

    return btoa(str)
  }
  function getImageData(canvas) {
    let w = canvas.width,
      h = canvas.height
    return canvas.getContext('2d').getImageData(0, 0, w, h)
  }
  function makeURI(strData, type) {
    return 'data:' + type + ';base64,' + strData
  }

  /**
   * create bitmap image
   * 按照规则生成图片响应头和响应体
   */
  const genBitmapImage = function (oData) {
    //
    // BITMAPFILEHEADER: http://msdn.microsoft.com/en-us/library/windows/desktop/dd183374(v=vs.85).aspx
    // BITMAPINFOHEADER: http://msdn.microsoft.com/en-us/library/dd183376.aspx
    //

    let biWidth = oData.width
    let biHeight = oData.height
    let biSizeImage = biWidth * biHeight * 3
    let bfSize = biSizeImage + 54 // total header size = 54 bytes

    const BITMAPFILEHEADER = [
      0x42,
      0x4d,
      bfSize & 0xff,
      (bfSize >> 8) & 0xff,
      (bfSize >> 16) & 0xff,
      (bfSize >> 24) & 0xff,
      0,
      0,
      0,
      0,
      54,
      0,
      0,
      0,
    ]

    const BITMAPINFOHEADER = [
      40,
      0,
      0,
      0,
      biWidth & 0xff,
      (biWidth >> 8) & 0xff,
      (biWidth >> 16) & 0xff,
      (biWidth >> 24) & 0xff,
      biHeight & 0xff,
      (biHeight >> 8) & 0xff,
      (biHeight >> 16) & 0xff,
      (biHeight >> 24) & 0xff,
      1,
      0,
      24,
      0,
      0,
      0,
      0,
      0,
      biSizeImage & 0xff,
      (biSizeImage >> 8) & 0xff,
      (biSizeImage >> 16) & 0xff,
      (biSizeImage >> 24) & 0xff,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ]

    let iPadding = (4 - ((biWidth * 3) % 4)) % 4

    let aImgData = oData.data

    let strPixelData = ''
    let biWidth4 = biWidth << 2
    let y = biHeight
    let fromCharCode = String.fromCharCode

    do {
      let iOffsetY = biWidth4 * (y - 1)
      let strPixelRow = ''
      for (let x = 0; x < biWidth; x++) {
        let iOffsetX = x << 2
        strPixelRow +=
          fromCharCode(aImgData[iOffsetY + iOffsetX + 2]) +
          fromCharCode(aImgData[iOffsetY + iOffsetX + 1]) +
          fromCharCode(aImgData[iOffsetY + iOffsetX])
      }

      for (let c = 0; c < iPadding; c++) {
        strPixelRow += String.fromCharCode(0)
      }

      strPixelData += strPixelRow
    } while (--y)

    const strEncoded =
      encodeData(BITMAPFILEHEADER.concat(BITMAPINFOHEADER)) +
      encodeData(strPixelData)

    return strEncoded
  }

  /**
   * saveAsImage
   * @param canvasElement
   * @param {String} image type
   * @param {Number} [optional] png width
   * @param {Number} [optional] png height
   */
  const saveAsImage = function (canvas, width, height, type) {
    if ($support.canvas && $support.dataURL) {
      if (typeof canvas == 'string') {
        canvas = document.getElementById(canvas)
      }
      if (type == undefined) {
        type = 'png'
      }
      type = fixType(type)
      let strData
      if (/bmp/.test(type)) {
        const data = getImageData(scaleCanvas(canvas, width, height))
        strData = genBitmapImage(data)
        saveFile(makeURI(strData, downloadMime))
      } else {
        strData = getDataURL(canvas, type, width, height)
        saveFile(strData.replace(type, downloadMime))
      }
    }
  }

  const convertToImage = function (canvas, width, height, type) {
    if ($support.canvas && $support.dataURL) {
      if (typeof canvas == 'string') {
        canvas = document.getElementById(canvas)
      }
      if (type == undefined) {
        type = 'png'
      }
      type = fixType(type)
      let strData
      if (/bmp/.test(type)) {
        const data = getImageData(scaleCanvas(canvas, width, height))
        strData = genBitmapImage(data)
        return genImage(makeURI(strData, 'image/bmp'))
      } else {
        strData = getDataURL(canvas, type, width, height)
        return genImage(strData)
      }
    }
  }

  return {
    saveAsImage: saveAsImage,
    saveAsPNG: function (canvas, width, height) {
      return saveAsImage(canvas, width, height, 'png')
    },
    saveAsJPEG: function (canvas, width, height) {
      return saveAsImage(canvas, width, height, 'jpeg')
    },
    saveAsGIF: function (canvas, width, height) {
      return saveAsImage(canvas, width, height, 'gif')
    },
    saveAsBMP: function (canvas, width, height) {
      return saveAsImage(canvas, width, height, 'bmp')
    },
    convertToImage,
    convertToPNG: function (canvas, width, height) {
      return convertToImage(canvas, width, height, 'png')
    },
    convertToJPEG: function (canvas, width, height) {
      return convertToImage(canvas, width, height, 'jpeg')
    },
    convertToGIF: function (canvas, width, height) {
      return convertToImage(canvas, width, height, 'gif')
    },
    convertToBMP: function (canvas, width, height) {
      return convertToImage(canvas, width, height, 'bmp')
    },
  }
})()
export function cvs2image(src) {
  const img = new Image()
  // 当图像加载完成时执行以下操作
  let oCanvas = document.createElement('canvas')
  img.onload = function () {
    // 创建一个 Canvas 元素，并获取其上下文
    const ctx = oCanvas.getContext('2d')
    // 设置 Canvas 尺寸与图像一致
    oCanvas.width = img.width
    oCanvas.height = img.height
    // 将图像绘制到 Canvas 上
    ctx.drawImage(img, 0, 0)
    // 在此之后，您可以对 Canvas 进行其他操作，如保存为图片或处理图像数据
  }
  // 设置 Image 元素的 src 属性为图像 URL，触发加载
  img.src = src // 替换为您的图像 URL
  return oCanvas
}

export const isSideMachineHole = (hole) => {
  return (
    hole.isSide == 1 ||
    (store.state.ncSetting.sideHoleDrill &&
      store.state.ncSetting.side_machine_hole_slot.HINGE &&
      hole.symbol === 'HINGE')
  )
}
export const isSideMachineSlot = (slot) => {
  return (
    slot.isSide == 1 ||
    (store.state.ncSetting.sideHoleDrill &&
      store.state.ncSetting.side_machine_hole_slot.STRENTCH &&
      ['STRENTCH', 'STRENTCHTWO', 'MIDSTRENTCH'].includes(slot.symbol))
  )
}
export const isOtherSideMachineHole = (hole) => {
  return (
    hole.isSide == '1' ||
    (store.state.ncSetting.sideHoleDrill &&
      store.state.ncSetting.side_machine_hole_slot.HINGE &&
      hole.symbol === 'HINGE')
  )
}
export const isOtherSideMachineSlot = (slot) => {
  return (
    slot.isSide == '1' ||
    (store.state.ncSetting.sideHoleDrill &&
      store.state.ncSetting.side_machine_hole_slot.STRENTCH &&
      ['STRENTCH', 'STRENTCHTWO', 'MIDSTRENTCH'].includes(slot.symbol))
  )
}

/**
 *
 * @param {Number} num 处理的数字
 * @returns 不足三位补0
 */
export function dealNumber(num) {
  let numStr = ''
  const len = String(num).length
  if (len < 2) numStr = '00' + num
  else if (len < 3) numStr = '0' + num
  else numStr = String(num)
  return numStr
}
/**
 * @description 滚动至元素位置
 * @param {HTMLElement} dom
 * @returns *
 */
export function scrollToElementFn(dom) {
  dom.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}
export const delayTimer = async (delay = 0) => {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true)
      clearTimeout(timer)
    }, delay)
  })
}
export const splitTime = () => {
  const date = new Date()
  const second = ('0' + date.getSeconds()).substr(-2, 2)
  const minute = ('0' + date.getMinutes()).substr(-2, 2)
  const hour = ('0' + date.getHours()).substr(-2, 2)
  const day = ('0' + date.getDate()).substr(-2, 2)
  const month = ('0' + (date.getMonth() + 1)).substr(-2, 2)
  const year = date.getFullYear()
  const dealTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`
  return dealTime
}

export function calcClosePlank(activePlank, bigPart) {
  const { parts, plankHeight, plankWidth, otherPlate } = bigPart
  // 获取存在碰撞的板件,减少运算
  const crashPlanks = getCrashPlankByActivePlank(activePlank, parts, {
    activePlankOffset: ADSORPTION_RANGE,
  })
  const re = calcClosePoint(activePlank, crashPlanks)
  if (!re) return
  const copyPlank = JSON.parse(JSON.stringify(activePlank))
  copyPlank.startX = re.x
  copyPlank.startY = re.y
  const res = calcClosePlankPoint(copyPlank, crashPlanks, {
    plankWidth,
    plankHeight,
    otherPlate,
  })
  copyPlank.startX = res.x
  copyPlank.startY = res.y
  const finalRes = calcClosePlankPoint(copyPlank, crashPlanks, {
    plankWidth,
    plankHeight,
    otherPlate,
  })
  return finalRes
}
function calcClosePlankPoint(activePlank, parts, options) {
  const layoutGap = store.state.ncSetting.panelSize.layoutGap
  const rectPoints = getRectPath(activePlank)
  const otherParts = parts.filter(
    (part) =>
      part.plankID !== activePlank.plankID || part.index !== activePlank.index
  )
  const otherPartsRectList = otherParts.map((part) => {
    return getRectPath(part)
  })

  let area = ClipperLib.JS.AreaOfPolygon(rectPoints)
  if (area > 0) {
    rectPoints.reverse()
  }

  const upperPoints = rectPoints.map((p) => ({ X: p.x, Y: p.y }))
  // 对板子进行扩板
  const gapRect = expandPath(layoutGap, upperPoints)
  // 扩板之后的startX,startY
  const gapStartPoint = {
    x: Math.min(...gapRect[0].map((p) => p.x)),
    y: Math.min(...gapRect[0].map((p) => p.y)),
  }

  //是否离得太近，太近了弹开她
  let isOverClose = false

  const gapShape = new Polygon(gapRect[0].map((p) => point(p.x, p.y)))

  const otherShape = otherPartsRectList.map((rect) => {
    return new Polygon(rect.map((p) => point(p.x, p.y)))
  })

  const shortestRes = calcShortestDistance(gapShape, otherShape)
  const shortestDis = shortestRes.shortestDis
  let closeRes = shortestRes.closeRes
  // 大板修边
  const plankEdge =
    options?.otherPlate && Object.keys(options?.otherPlate).length
      ? options.otherPlate.trim_edge
      : store.state.historyPlankEdgeOff
  // 刀径
  const diameter = Number(store.state.ncSetting.knife.diameter)

  // 板件没有重叠时
  if (shortestDis > 0) {
    let newStartX =
      gapStartPoint.x + closeRes.point2.x - closeRes.point1.x + layoutGap
    let newStartY =
      gapStartPoint.y + closeRes.point2.y - closeRes.point1.y + layoutGap
    if (shortestDis <= ADSORPTION_RANGE) {
      return {
        x:
          newStartX < 0
            ? plankEdge - diameter / 2
            : newStartX + activePlank.rect.width > options.plankWidth
            ? options.plankWidth - activePlank.rect.width
            : newStartX,
        y:
          newStartY < 0
            ? plankEdge - diameter / 2
            : newStartY + activePlank.rect.height > options.plankHeight
            ? options.plankHeight - activePlank.rect.height
            : newStartY,
        p1: closeRes.point1,
        p2: closeRes.point2,
      }
    } else {
      return {
        x: activePlank.startX,
        y: activePlank.startY,
        p1: closeRes.point1,
        p2: closeRes.point2,
      }
    }
  } else {
    const copyPlank = JSON.parse(JSON.stringify(activePlank))
    let closeResult = {
      x: copyPlank.startX,
      y: copyPlank.startY,
      p1: closeRes.point1,
      p2: closeRes.point2,
    }
    // 当前选中小板的真实轮廓的polygon
    const realRectPolygon = new Polygon(rectPoints.map((p) => point(p.x, p.y)))
    const realShortestRes = calcShortestDistance(realRectPolygon, otherShape)
    let samePoint = point(
      realShortestRes.closeRes.point2.x,
      realShortestRes.closeRes.point2.y
    )
    // realRectPolygon.contains()
    // 扩板重叠的板件在otherShape中的index
    const overlapShapeIndex = otherShape.findIndex((s) =>
      s.findEdgeByPoint(samePoint)
    )
    // 得到重叠板件
    const overlapPart = otherParts[overlapShapeIndex]
    // 转为polygon
    const overlapShape = new Polygon(
      getRectPath(overlapPart).map((p) => point(p.x, p.y))
    )
    // 判断真实小板是否与该板件重叠
    const intersect = BooleanOperations.intersect(
      realRectPolygon,
      overlapShape
    ).edges
    const isRealOverlap = Boolean(intersect.size)
    // 如果重叠，返回原始位置
    if (isRealOverlap) {
      // 多边形重叠的线条
      let shapeList = []
      intersect.forEach((shape) => {
        shapeList.push(shape.shape)
      })
      //过滤出X轴方向的线条
      const allPoints = shapeList.map((shape) => [shape.ps, shape.pe]).flat(1)
      const maxX = Math.max(...allPoints.map((point) => point.x))
      const minX = Math.min(...allPoints.map((point) => point.x))
      const maxY = Math.max(...allPoints.map((point) => point.y))
      const minY = Math.min(...allPoints.map((point) => point.y))
      // 判断板件的方位
      // if (minX > overlapPart.startX) {
      // 距左、距右
      let leftDis = Math.abs(maxX - overlapPart.startX)
      let rightDis = Math.abs(
        overlapPart.startX + overlapPart.rect.width - minX
      )
      // 距上、距下
      let topDis = Math.abs(maxY - overlapPart.startY)
      let bottomDis = Math.abs(
        overlapPart.startY + overlapPart.rect.height - minY
      )

      if (topDis < ADSORPTION_RANGE) {
        closeResult.y = minY - activePlank.rect.height - layoutGap
        // activePlank.startY = minY - activePlank.rect.height
      } else if (bottomDis < ADSORPTION_RANGE) {
        closeResult.y = maxY + layoutGap
      }
      if (leftDis < ADSORPTION_RANGE) {
        closeResult.x = minX - activePlank.rect.width - layoutGap
      } else if (rightDis < ADSORPTION_RANGE) {
        closeResult.x = maxX + layoutGap
      }

      // }
      // 过滤出
      return {
        ...closeResult,
        intersect: shapeList,
      }
    } else {
      const realClosePoint = realRectPolygon.distanceTo(overlapShape)
      samePoint = realClosePoint[1].pe
      // 否则判断小板的所处的方向进行点位偏移
      if (activePlank.startX > overlapPart.startX) {
        // 大于起始点的上下方向
        if (activePlank.startX < overlapPart.startX + overlapPart.rect.width) {
          closeResult = {
            x: copyPlank.startX,
            y:
              copyPlank.startY > overlapPart.startY
                ? samePoint.y + layoutGap
                : samePoint.y - copyPlank.rect.height - layoutGap,
            p1: realClosePoint[1].ps,
            p2: realClosePoint[1].pe,
          }
        } else {
          //小板右侧,向右弹开
          closeResult = {
            x: samePoint.x + layoutGap,
            y: copyPlank.startY,
            p1: realClosePoint[1].ps,
            p2: realClosePoint[1].pe,
          }
        }
      } else {
        if (copyPlank.startX < overlapPart.startX - copyPlank.rect.width) {
          closeResult = {
            x: samePoint.x - layoutGap - copyPlank.rect.width,
            y: copyPlank.startY,
            p1: realClosePoint[1].ps,
            p2: realClosePoint[1].pe,
          }
        } else {
          closeResult = {
            x: copyPlank.startX,
            y:
              copyPlank.startY > overlapPart.startY
                ? samePoint.y + layoutGap
                : samePoint.y - layoutGap - copyPlank.rect.height,
            p1: realClosePoint[1].ps,
            p2: realClosePoint[1].pe,
          }
        }
      }
    }
    return closeResult
  }
}

// 计算板件与其他板件的最短距离
function calcShortestDistance(currentShape, otherShape = []) {
  const res = otherShape.map((shape) => currentShape.distanceTo(shape))
  const formatRes = res.map((r) => {
    return {
      distance: r[0], //两个板子之间的距离
      point1: r[1].ps, //activePlank上的点
      point2: r[1].pe, //吸附板上的点
    }
  })

  const shortestDis = Math.min(...formatRes.map((res) => res.distance))
  return {
    shortestDis,
    closeRes: formatRes.find((res) => res.distance == shortestDis),
  }
}

// 获取小板逆时针的rect点位路径
function getRectPath(part) {
  return part.path
    ? part.path[0].map((p) => ({ x: p.x + part.startX, y: p.y + part.startY }))
    : [
        { x: part.startX, y: part.startY },
        {
          x: part.startX + part.rect.width,
          y: part.startY,
        },
        {
          x: part.startX + part.rect.width,
          y: part.startY + part.rect.height,
        },
        {
          x: part.startX,
          y: part.startY + part.rect.height,
        },
        { x: part.startX, y: part.startY },
      ]
}

/**
 * @description 将rect转换为path
 * @param {*} rect 轮廓
 * @returns
 */
export function rectToPath(rect) {
  const { x, y, width, height } = rect
  return [
    { x: x, y: y },
    { x: x + width, y: y },
    { x: x + width, y: y + height },
    { x: x, y: y + height },
  ]
}
