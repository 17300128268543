export default {
  common: {
    localFileIsBeingWrite: 'Tệp cục bộ đang được ghi',
    taskMonitor: 'Giám sát nhiệm vụ',
    downloadByType: 'Tải xuống {type}',
    anewChooseCatalog: 'Chọn lại thư mục',
    downloadSetting: 'Cài đặt tải xuống',
    attention: 'Chú ý',
    unit: 'Khối',
    defaultUse: 'Sử dụng mặc định',
    defaultChooseUse: 'Chọn mặc định sử dụng',
    requestFail: 'Yêu cầu thất bại',
    downloadDoneByType: 'Đã tải xuống {type} xong',
    sysTip: 'Thông báo hệ thống',
    pleaseChoose: 'Vui lòng chọn',
    confirmDelete: 'Xác nhận xóa',
    notDataTipByType: 'Chưa có dữ liệu, vui lòng {msg} trước',
    paginationTotalTip: 'Dòng {current}-{page} trong tổng số {total}',
    chooseAmountData: 'Đã chọn {number} dữ liệu',
    moreOperate: 'Thao tác thêm',
    transferByType: 'Chuyển {type}',
    clickCopy: 'Nhấp để sao chép {type}',
    currentLine: 'Dòng hiện tại',
    remark: 'Ghi chú',
    customFunction: 'Chức năng tùy chỉnh',
    limitedTimeTrial: 'Dùng thử có thời hạn',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
    startTime: 'Thời gian bắt đầu',
    endTime: 'Thời gian kết thúc',
    lastMonth: 'Tháng trước',
    lastSixMonth: 'Sáu tháng gần đây',
    lastYear: 'Năm trước',
    searchByType: 'Vui lòng nhập {type} để tìm kiếm',
    addTime: 'Thêm thời gian',
    startArrange: 'Bắt đầu điều hình',
    select: 'Chọn',
    name: 'Tên',
    plankNo: 'Mã ván',
    plankName: 'Tên ván',
    orderNo: 'Mã đơn hàng',
    order: 'Đơn hàng',
    plankRemark: 'Ghi chú ván',
    room: 'Phòng',
    orderAddress: 'Địa chỉ dự án',
    matCode: 'Chất liệu',
    matCode2: 'Vật liệu',
    plankMatCode: 'Chất liệu ván',
    count: 'Số lượng',
    customer: 'Khách hàng',
    customerInfo: 'Thông tin khách hàng',
    factoryOrderNo: 'Mã đơn hàng nhà máy',
    roomName: 'Tên phòng',
    color: 'Màu sắc',
    plankColor: 'Màu sắc ván',
    plankThick: 'Độ dày ván',
    plankHighGloss: 'Đánh dấu các thuộc tính',
    thick: 'Độ dày',
    front: 'Mặt trước',
    back: 'Mặt sau',
    slot: 'Khe',
    hole: 'Lỗ',
    hsInfo: '{hole} lỗ {slot} khe',
    diameter: 'Đường kính',
    finishedWidth: 'Chiều rộng thành phẩm',
    finishedHeight: 'Chiều dài thành phẩm',
    texture: 'Kết cấu bề mặt',
    normal: 'Kết cấu dọc',
    reverse: 'Kết cấu ngang',
    notCare: 'Không có kết cấu',
    holeSlotsStastic: 'Thống kê số lượng lỗ và khe',
    edge: {
      title: 'Đóng biên',
      info: 'Thông tin đóng biên',
      front: 'Đóng biên trước',
      back: 'Đóng biên sau',
      left: 'Đóng biên trái',
      right: 'Đóng biên phải',
      up: 'Đóng biên trên',
      down: 'Đóng biên dưới',
    },
    isSpecial: 'Hình dáng khác',
    notSpecial: 'Hình dáng thông thường',
    isDoorPlank: 'Tấm cửa',
    notDoorPlank: 'Không phải là tấm cửa',
    width: 'Chiều rộng',
    long: 'Chiều dài',
    plankWidth: 'Chiều rộng ván',
    plankHeight: 'Chiều dài ván',
    shortLong: 'Chiều dài cạnh ngắn',
    shortWidth: 'Chiều rộng cạnh ngắn',
    plankShape: 'Hình dáng ván',
    detail: 'Chi tiết',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    deep: 'Độ sâu',
    other: 'Khác',
    heighGloss: 'Màu sáng',
    heighGlossPlank: 'Ván màu sáng',
    notHeighGloss: 'Không phải màu sáng',
    notHeighGlossPlank: 'Ván không phải màu sáng',
    saveSuccess: 'Lưu thành công',
    deleteSuccess: 'Xóa thành công',
    addSuccess: 'Thêm thành công',
    editSuccess: 'Sửa thành công',
    engraving: 'Máy khắc',
    electronicSaw: 'Máy cưa điện tử',
    glassEquip: 'Máy cắt kính',
    labelingMachine: 'Máy dán nhãn',
    copy: 'Sao chép',
    paste: 'Dán',
    undo: 'Hủy thao tác',
    move: 'Di chuyển',
    PTP: 'PTP',
    dock56FDrill: 'Máy khoan 5-6 mặt',
    sideHoleDrill: 'Máy khoan lỗ bên',
    combination: 'Thiết bị kết hợp',
    integration: 'Thiết bị tích hợp',
    standardPlank: 'Ván tiêu chuẩn lớn',
    standardMaterial: 'Vật liệu tiêu chuẩn',
    specialMaterial: 'Vật liệu đặc biệt',
    specialPlank: 'Ván đặc biệt lớn,',
    singlePlank: 'Ván đơn miệng',
    previous: 'Trước',
    next: 'Tiếp',
    rate: 'Tỷ lệ sử dụng',
    paibanBatch: 'đợt đặt chữ',
    return: 'Quay lại',
    FGNo: 'Mã hợp đồng',
    refresh: 'Làm mới',
    inde: 'Số thứ tự',
    productionLine: 'Dây chuyền sản xuất',
    operation: 'Hoạt động',
    colorPlaceHolder: 'Nhấn Enter để tự động tạo màu',
    saveAndContinue: 'Lưu và tiếp tục',
    reset: 'Đặt lại',
    check: 'Tra cứu',
    inputPlaceholder: 'Vui lòng nhập',
    filter: 'Lọc',
    all: 'Tất cả',
    exportPDF: 'Xuất PDF',
    exportPic: 'Xuất hình ảnh',
    add: 'Thêm',
    edit: 'Sửa',
    delete: 'Xóa',
    save: 'Lưu',
    saveSetting: 'Lưu cài đặt',
    isHeightGloss: 'Vui lòng chọn ván có màu sáng hay không',
    noSync: 'Không đồng bộ',
    sync: 'Đồng bộ',
    formatSuccess: '{type} thành công',
    dataLoading: 'Dữ liệu đang tải...',
    warmTip: 'Gợi ý ấm áp',
    wellcome: 'Chào mừng sử dụng Cloud Layout',
    timeOut: 'Layout đã hết hạn, có muốn thực hiện lại không?',
    warning: 'Cảnh báo',
    export: 'Xuất',
    mark: 'Đánh dấu',
    noMark: 'Không đánh dấu',
    sawArrange: 'Bố trí cưa',
    directArrange: 'Bố trí trực tiếp',
    area: 'Diện tích',
    plankNum: 'Mã vạch ván',
    loc: 'Tủ',
    cutHeight: 'Chiều dài cắt',
    cutWidth: 'Chiều rộng cắt',
    defaultProduction: 'Dây chuyền sản xuất mặc định',
    tip: 'Gợi ý',
    history: 'Lịch sử',
    hinge: 'Lỗ nối cửa',
    stretch: 'Kẹp phẳng',
    stretcher: 'Khoan kéo phẳng',
    clothole: 'Lỗ đeo quần áo',
    backPlank: 'Khoan tấm đuôi',
    ALS: 'Khoan tay cầm tối',
    doorSlot: 'Khoan kim loại tấm cửa',
    drawerSlot: 'Khoan kim loại ngăn kéo',
    KC: 'Lỗ khoan quần',
    stealth: 'Bộ phận vô hình',
    allHole: 'Tất cả các lỗ trên ván hình dáng khác',
    allSlot: 'Tất cả các khoan trên ván hình dáng khác',
    millInfo: 'Sừng bò nắm tay nhau',
    FSDrill: 'Máy khoan 5/6 mặt',
    engraving2: 'Máy chế biến',
    xyReverse: 'Đổi trục XY',
    rect: 'Hình chữ nhật',
    Ltype: 'Hình chữ L',
    archive: 'Lưu trữ',
    tellPlaceholder: 'Vui lòng nhập số điện thoại',
    knivesStore: 'Kho dao',
    roll: 'Lật ván',
    pause: 'Tạm dừng',
    editTrans: 'Sửa dịch',
    translateManager: 'Quản lý dịch',
    gratingSlot: 'Khoan lưới',
    addressEditErr: 'Không hỗ trợ sửa đổi địa chỉ dự án với nhiều đơn hàng',
    customerEditErr:
      'Không hỗ trợ sửa đổi thông tin khách hàng với nhiều đơn hàng',
    factoryEditErr: 'Không hỗ trợ sửa đổi đơn hàng nhà máy với nhiều đơn hàng',
    roomNameEditErr: 'Không hỗ trợ sửa đổi tên phòng với nhiều đơn hàng',
    remarkEditErr: 'Không hỗ trợ sửa đổi ghi chú đơn hàng với nhiều đơn hàng',
    toMaterialList: 'Đi đến danh sách chế biến',
    deleteFail: 'Xóa thất bại, vui lòng thử lại sau!',
    batchDelete: 'Xóa hàng loạt',
    workShop: 'Sản xuất trong xưởng',
    FGAdrress: 'Địa chỉ dự án/Mã hợp đồng',
    cuttingSize: 'Kích thước cắt',
    finishedSize: 'Kích thước thành phẩm',
    labelImg: 'Hình ảnh nhãn',
    layoutImg: 'Hình ảnh bố trí',
    file: 'Tệp',
    folder: 'Thư mục',
    reName: 'Đổi tên',
    to: 'Đến',
    plankUnit: 'Trang',
    tagTemp: 'Mẫu nhãn',
    roomRemark: 'Ghi chú phòng',
    noModificationPermission: 'Không có quyền sửa đổi',
    modificationRecord: 'Sửa đổi hồ sơ',
    page: 'trang',
    strip: 'đường',
    jump: 'nhảy',
    freeAgreement: 'Giao thức miễn trách nhiệm phần mềm',
    preStep: 'Bước trước',
    nextStep: 'nextStep',
    upload: 'Tải lên',
    uploading: 'đang tải lên',
    iAgree: 'Tôi đồng ý',
    pleaseSelectPlank: 'Hãy chọn ít nhất một tấm bảng',
    saveToStoreTip: 'Vui lòng chọn ít nhất một tấm không cắt',
    fileIsNotExist: 'Tập tin không tồn tại',
    selectAll: 'Chọn tất cả',
    pdfTag: 'Thẻ PDF',
    tagImage: 'Hình ảnh thẻ',
    projectName: 'Bán hình đám mây',
    guigui: 'guigui',
    gratingSlot: 'Rãnh lưới',
    deleteTip:
      'Sau khi xóa dữ liệu này, nó sẽ không thể khôi phục lại, bạn có chắc chắn muốn xóa dữ liệu này không?',
    deleteError: 'Đã xảy ra lỗi không xác định và xóa không thành công',
    cancelDelete: 'Đã hủy xóa',
    confirmDeleteTip:
      'Sau khi xóa, bảng sẽ được nhập vào góc dưới bên phải của trang trong "Bảng được sắp chữ - không được lưu trữ", có xác nhận xóa dữ liệu hay không?',
    deleteEmptyTip: 'Có nên xóa bảng lớn này không',
    notCanDelete: 'Các nguyên liệu còn lại bị cắt xén không hỗ trợ nhập kho',
    new: 'thêm mới',
    innerBluneFailure: 'Енгізілген бұлтты теру күшіне енбейді.',
    deleteBigPlankTip:
      'Một tấm lớn phải được giữ dưới độ dày màu vật liệu hiện tại và không thể bị xóa!',
    continueExport: 'Bỏ qua và tiến hành tệp đầu ra',
    toCheck: 'Đi kiểm tra',
    pleaseCheck:
      'Vui lòng kiểm tra: Kết quả sắp xếp hiện tại có xung đột lỗ khe/mảnh nhỏ bay ra/sự bất thường về độ sâu. Xin hãy kiểm tra!',
    colorNotUndefined: 'Màu sắc không được để trống',
    materialNotUndefined: 'Vật liệu không được để trống',
    newSetting: 'thêm mới',
  },
  home: {
    head: {
      production: 'Giới thiệu sản phẩm',
      resolve: 'Giải pháp',
      about: 'Về chúng tôi',
      equipment: 'Thiết bị máy móc',
      tell: 'Điện thoại khiếu nại',
      enter: 'Đi vào Bán hình đám mây',
    },
    swiper: {
      case1: {
        tag: 'Phiên bản nâng cấp hoàn toàn mới',
        title: 'Bán hình đám mây',
        subTitle: 'Trí tuệ của máy móc',
        description:
          'Phần mềm cải cách ngành công nghiệp để tối ưu hóa bán hình trực tuyến trên đám mây',
      },
      case2: {
        title: 'Sản xuất tủ',
        subTitle: 'Công nghệ đổi mới',
        description:
          'Hỗ trợ nhiều loại máy dán nhãn, máy chạy đuôi, máy khoan bên, máy PTP năm mặt, máy khoan năm/sáu mặt',
      },
      case3: {
        title: 'Trí tuệ công nghiệp',
        subTitle: 'Giải pháp',
        description:
          'Thuật toán bán hình thông minh giúp đạt được tỉ lệ sử dụng ván cao hơn',
      },
    },
    statistics: {
      today: 'Lượng phân ban hôm nay',
      toMounth: 'Lượng phân ban trong tháng',
      outturn: 'Tỷ lệ xuất tài nguyên',
      cumulative: 'Người dùng tích lũy',
    },
    intro: {
      title:
        'Phần mềm phân ban trực tuyến trên đám mây với dữ liệu lớn cấp doanh nghiệp',
      subTitle:
        'Thông thái ngành công nghiệp, tập trung vào nhu cầu khách hàng, trao quyền cho doanh nghiệp chuyển đổi kỹ thuật số, đẩy mạnh cách mạng sản xuất đồ nội thất trực tuyến. Đối với hiện trạng sản xuất ngành đồ nội thất bảng hiện có, giới thiệu thuật toán hỗ trợ AI, liên tục đóng góp tăng trưởng mới cho doanh nghiệp',
      menue: {
        case1: {
          title: 'Phân ban trực tuyến',
          subTitle: 'Phân ban bất cứ lúc nào',
          description:
            'Không cần tải xuống phần mềm, có thể phân ban bất cứ lúc nào, điều khiển từ xa không phụ thuộc vào máy tính',
        },
        case2: {
          title: 'Sản xuất đa dòng',
          subTitle: 'Chuyển đổi nhanh chóng giữa các dòng sản xuất',
          description:
            'Hỗ trợ nhiều dòng sản xuất chế biến nhanh chóng đồng thời, có thể chuyển đổi nhanh chóng giữa các dòng sản xuất',
        },
        case3: {
          title: 'Sản xuất chi tiết',
          subTitle: 'Phân ban chi tiết, cài đặt tham số chi tiết',
          description:
            'Phân ban chi tiết, cài đặt tham số chi tiết, làm cho sản xuất tấm trở nên chính xác hơn',
        },
        case4: {
          title: 'Sản xuất tùy chỉnh',
          subTitle: 'Nhãn tùy chỉnh, máy khoan năm/sáu mặt, hình dạng dư liệu',
          description:
            'Nhãn tùy chỉnh, máy khoan năm/sáu mặt tùy chỉnh, hình dạng dư liệu tùy chỉnh, kết hợp các thiết bị chế biến',
        },
      },
    },
    fangan: {
      title: 'Giải pháp chuyên nghiệp trong ngành',
      subTitle:
        'Chi phí thấp, sản lượng cao, thuật toán thông minh tăng cường tỷ lệ sử dụng tấm, tương thích với nhiều phần mềm thiết kế, cung cấp cho người dùng giải pháp sản xuất tối ưu',
      menue: {
        case1: {
          title: 'Hòan thành sản xuất đơn hàng trực tuyến',
          description:
            'Thao tác trực tuyến từ xa, vượt qua trở ngại không gian, đồng bộ hóa đơn hàng đến nhà máy bất cứ lúc nào, thiết kế và sản xuất hoàn thành đồng bộ',
        },
        case2: {
          title: 'Chuyển đổi dây chuyền sản xuất bằng một nút',
          description:
            'Dây chuyền sản xuất tùy chỉnh, hỗ trợ nhiều dây chuyền sản xuất, kết nối nhiều loại thiết bị',
        },
        case3: {
          title: 'Thuật toán thông minh tăng cường tỷ lệ sử dụng tấm',
          description:
            'Thuật toán truyền thống có tỷ lệ sử dụng tấm thấp, thuật toán thông minh có cài đặt tham số chi tiết, cung cấp giải pháp sản xuất tùy chỉnh',
        },
        case4: {
          title: 'Giải pháp cắt tấm chuyên nghiệp',
          description:
            'Tối ưu hóa thứ tự cắt, điểm cắt, làm cho đường đi ngắn hơn, cắt ổn định hơn',
        },
      },
    },
    aboutUs: {
      title1: 'Công nghệ nghị định cư Tân Giác',
      subTitle: 'Nhà lãnh đạo trong cuộc cách mạng sản xuất đồ nội thất',
      description:
        'Doanh nghiệp Tân Giác, chuyên chú thực hiện cuộc cách mạng internet trong ngành sản xuất đồ nội thất truyền thống',
      culture: {
        case1: {
          title: 'Mục đích kinh doanh',
          subTitle: 'Giúp nhà máy tăng trưởng ba lần',
        },
        case2: {
          title: 'Giá trị văn hóa',
          subTitle:
            'Duy trì khách hàng là trung tâm, lấy người lao động là cốt lõi, nhân vật khổ sở, mang lại phúc lợi cho nhân dân',
        },
        case3: {
          title: 'Hướng dẫn tư tưởng',
          subTitle:
            'Đường lối quần chúng, xây dựng đội ngũ ưu tú có khả năng bán hàng, sản phẩm, tổ chức',
        },
      },
      feature: {
        case1: 'Đoàn ngũ chuyên nghiệp bảo trì',
        case2: 'Cập nhật và thay thế liên tục',
        case3: 'Dịch vụ hậu mãi một-đến-một',
        case4: 'Dịch vụ tùy chỉnh chức năng',
      },
    },
    cooperation: {
      title: 'Hợp tác chân thành, cùng tạo ra tương lai',
      subTitle:
        'Đối tác thiết bị phủ 90% thiết bị phổ biến trong nước, 107554 doanh nghiệp cùng tiến với chúng tôi',
    },
    footer: {
      contact: {
        title: 'Liên hệ với chúng tôi',
        adrres: 'Địa chỉ: 50 Hạm Thùy, Quận Bīn Hú, Vô Sĩ, Giang Tô',
        subAdrres: 'Tầng 16, Tòa nhà 1, Trung tâm dịch vụ K-park',
        tell: 'Điện thoại',
      },
      business: {
        title: 'Các dịch vụ chính của Tân Cách',
        department1: 'Bộ phận phần mềm cửa sổ và cửa ra vào',
        department2: 'Bộ phận phần mềm đồ nội thất',
        department3: 'Bộ phận xây dựng phù hợp',
        department4: 'Trang web chính của Tân Cách',
      },
      brand: {
        title: 'Các thương hiệu của Tân Cách',
        brand1: 'Tài thần Kim Phục',
        brand2: 'Môn Toánet',
        brand3: 'Đinh Hân Đầu tư',
        brand4: 'Tam Tranh Khoa học',
        brand5: 'Thanh Mô Công nghệ Thượng Hải',
      },
      organization: {
        title: 'Các chi nhánh toàn cầu của Tân Cách',
        organization1:
          'Tân Cách - Cục Nam Trung Quốc (Công ty chi nhánh Phú Xiên)',
        organization2: 'Tân Cách - Cục Bắc Phương (Công ty chi nhánh Lâm Yên)',
        organization3: 'Tân Cách - Cục Tây Nam (Công ty chi nhánh Thành Đô)',
        organization4: 'Tân Cách - Trung tâm dịch vụ phía Tây của Quì Quì',
        organization5: 'Tân Cách - Trung tâm dịch vụ phía Đông của Quì Quì',
        organization6: 'Tân Cách - Hệ thống đại lý',
        organization7: 'Tân Cách - Bộ phận thị trường quốc tế',
      },
      follow: {
        title: 'Quét để "Theo dõi Quì Quì"',
      },
      friendLink: {
        link1: 'Phần mềm Tân Cách',
        link2: 'Phần mềm K Show Đạo hàng',
        link3: 'Môn Toánet',
        link4: 'Phần mềm tủ toàn nhôm',
        link5: 'Tải xuống phần mềm ZOL',
        link6: 'Hoa Quân Phần mềm',
        link7: 'Trang tải xuống',
        link8: 'Tải xuống phần mềm ZOL',
        link9: 'Tài nguyên xanh',
        link10: 'Tủ quần áo tham gia',
        link11: 'Đoàn kết nội thất',
        link12: 'Đoàn kết vệ sinh Mỹ tiêu',
      },
    },
  },
  login: {
    title: 'Đăng nhập',
    tell: 'Số điện thoại',
    password: 'Mật khẩu',
    passwordPlaceholder: 'Vui lòng nhập mật khẩu',
    forget: 'Quên mật khẩu',
    register: 'Đăng ký tài khoản',
    backLog: 'Quay lại đăng nhập',
    verifyCode: 'Mã xác nhận',
    getVerifyCode: 'Nhận mã xác nhận',
    confirmPassword: 'Xác nhận mật khẩu',
    confirmPasswordPlaceholder: 'Vui lòng xác nhận mật khẩu',
    editPassword: 'Chỉnh sửa mật khẩu',
    reSend: 'Giây sau nhận lại',
    loginLoadText: 'Đang đăng nhập, vui lòng đợi...',
    loadingPageTextL: 'Đăng nhập thành công, đang tải trang...',
    loginSuccess: 'Đăng nhập thành công',
    tellErr: 'Định dạng số điện thoại không đúng',
    passwordErr: 'Vui lòng nhập mật khẩu',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Tiết kiệm thời gian, nguyên liệu, và công sức',
      task: 'Nhận nhiệm vụ',
      arrange: 'Điều hình',
      surplus: 'Yếu tố dư thừa',
      setting: 'Cài đặt',
      find: 'Khám phá',
      upgrade: 'Nâng cấp ngay',
      editNickName: 'Chỉnh sửa biệt danh',
      editPassword: 'Chỉnh sửa mật khẩu',
      logout: 'Thoát đăng nhập',
      notice: 'Thông báo',
      enterOfficial: 'Đi đến trang web chính thức',
      equipment: 'Thiết bị máy móc',
      help: 'Trợ giúp',
      enterThinkerOfficial: 'Đi vào trang web chính thức của Tân Nghi La',
      history: 'Lịch sử lưu trữ',
      plankManager: 'Quản lý nguyên tấm',
      tagManager: 'Quản lý thẻ',
      suplumentManager: 'Quản lý phụ kiện',
      fileManager: 'Quản lý file',
      productionLine: 'Kết nối thiết bị',
      workeShop: 'Kết nối thiết bị',
      useLesson: 'Hướng dẫn sử dụng',
      videoLesson: 'Hướng dẫn bằng video',
      prolineManager: 'Quản lý dây chuyền sản xuất',
      materialManager: 'Quản lý vật liệu',
    },
    sideBar: {
      title: 'Tối ưu hóa sản xuất',
      materialList: 'Danh sách nguyên liệu',
      paibanResult: 'Kế hoạch điều hình',
      printTag: 'In nhãn',
      history: 'Lịch sử lưu trữ',
      limitDate: 'Thử nghiệm giới hạn thời gian',
      surplusManager: 'Quản lý yếu tố dư thừa',
      translateManager: 'Quản lý dịch thuật',
      moreSettings: 'Thêm cài đặt',
    },
    allRead: 'Tất cả đã đọc',
    learnMore: 'Xem chi tiết',
    noticeEmpty: 'Chưa có thông báo....',
    noticeErr: 'Lỗi khi lấy chi tiết thông báo',
    feedBack: {
      tab1: 'Gửi phản hồi',
      tab2: 'Lịch sử phản hồi',
      type: 'Loại ý kiến',
      typePlaceholder: 'Vui lòng chọn chức năng',
      description: 'Mô tả',
      descriptionPlaceholder:
        'Hi～Vui lòng điền mô tả chi tiết không dưới 15 ký tự về trường hợp sử dụng để chúng tôi có thể hỗ trợ bạn tốt hơn, cảm ơn sự hợp tác!',
      uploadPic:
        'Thêm hình ảnh, tối đa có thể thêm 6 bức ảnh, dung lượng của mỗi bức ảnh không quá 10M',
      tell: 'Số điện thoại liên lạc',
      submit: 'Gửi',
      admin: 'Quản trị viên Điều hình đám mây:',
      picTip: 'Hình ảnh: (Nhấn để mở rộng xem chi tiết hình ảnh)',
      empty: 'Chưa có dữ liệu phản hồi',
      picErr: 'Dung lượng của mỗi bức ảnh không quá 10M',
      contentErr: 'Số ký tự phải trong khoảng 15 đến 300',
      necessary: 'Tùy chọn này là bắt buộc',
    },
  },
  taskPage: {
    tab1: 'Nhiệm vụ sản xuất',
    tab2: 'Hàng loạt sản xuất',

    filter: {
      title: 'Lọc',
      unArrange: 'Chưa điều hình',
      arranged: 'Đã điều hình',
      allArranged: 'Tất cả đã được bố trí',
      preLayout: 'Bố trí trước',
    },
    search: {
      placeholder: 'Vui lòng nhập nội dung tìm kiếm',
      orderName: 'Tên đơn hàng',
      sender: 'Người gửi',
    },
    importTask: 'Nhập nhiệm vụ',
    refuseTask: 'Từ chối nhiệm vụ',
    refreshList: 'Tân trang danh sách',
    refuseRecord: 'Lịch sử từ chối',
    taskCard: {
      sender: 'Đơn hàng nhập liệu',
      delivery: 'Ngày giao hàng',
      delete: 'Xóa',
      addToBatch: 'Thêm vào hàng loạt',
      save: 'Lưu trữ',
      arrange: 'Điều hình trực tiếp',
      deleteTitle: 'Xóa nhiệm vụ',
      checkDetail: 'Nhấn để xem chi tiết',
      refuse: 'Từ chối',
      refuseTime: 'Thời gian từ chối',
      refuseReason: 'Lý do từ chối',
      refuseTask: 'Từ chối nhận nhiệm vụ',
      refuseTaskPlaceholder: 'Vui lòng nhập lý do từ chối',
      addErr: 'Thêm thất bại, vui lòng thử lại sau!',
    },
    batchNo: 'Số lô',
    batchArrange: 'Điều hình hàng loạt',
    clearBatch: 'Xóa hàng loạt',
    taskCount: 'Số lượng nhiệm vụ hiện tại:',
    checkAllTask: 'Xem tất cả đơn hàng',
    clearSuccess: 'Xóa thành công',
    record: {
      batchNo: 'Lô',
      saveDate: 'Ngày lưu trữ',
      materialList: 'Đơn hàng liệu',
      arrangedRecord: 'Kế hoạch điều hình',
      searchPlaceholder: 'Vui lòng nhập số lô',
      tipText: 'Bạn có muốn xóa lưu trữ này?',
    },
    errTip:
      'Không có dữ liệu nhiệm vụ để chế biến, vui lòng đi đến danh sách nhiệm vụ để thêm!',
    emptyErr:
      'Đơn hàng này không có dữ liệu tấm gỗ, không thể tiến hành điều hình sản xuất!',
    operationSuccess: 'Thao tác thành công',
    operationFail: 'Thao tác thất bại, vui lòng thử lại sau!',
    saveSuccess: 'Lưu trữ thành công',
    plankMaxLimit: 'Số lượng tấm gỗ nhỏ vượt quá giới hạn tối đa điều hình',
    batchWorning: 'Vui lòng sản xuất theo lô',
    batchEmptyErr:
      'Danh sách đơn hàng không có dữ liệu tấm gỗ, không thể tiến hành sản xuất hàng loạt!',
    noRoomTip: 'Không có phòng để tiến hành sản xuất hàng loạt!',
    refuseHistory: 'Lịch sử từ chối',
  },
  materialPage: {
    edgeEmptyTip: 'Giá trị cạnh không thể bỏ trống',
    edgeNumberLimit: 'Giá trị cạnh chỉ có thể là số',
    chooseSurplusQuantity:
      'Có tổng cộng {total} loại vật liệu dư có sẵn, đã chọn {quantity} loại',
    chooseBujianStoreQuantity:
      'Có tổng cộng {total} tấm bổ sung, {quantity} tấm có thể bổ sung thêm, đã chọn {chooseQuantity} tấm',
    isConfirmPushToAwaitStore:
      'Bạn có chắc chắn muốn thêm các tấm ván đã chọn vào kho chờ xếp ván không?',
    enterAutoCreateMatCode: 'Nhấn Enter để tự động tạo vật liệu',
    chooseAwaitStoreQuantity:
      'Có tổng cộng {total} tấm nhỏ trong kho chờ xếp ván, {quantity} tấm có thể đưa vào xếp ván, đã chọn {chooseQuantity} tấm',
    defaultFold: 'Dữ liệu tấm bảng được ẩn theo mặc định',
    startArrange: 'Bắt đầu điều hình',
    importMaterial: 'Nhập danh sách tài liệu cắt gỗ',
    saveToHistory: 'Lưu vào lịch sử',
    addPlank: 'Thêm tấm gỗ',
    deletePlank: 'Xóa tấm gỗ',
    batchEdit: 'Sửa đổi hàng loạt',
    moreSetting: 'Cài đặt thêm',
    plankSize: 'Kích thước tấm gỗ',
    plankSize2: 'Kích thước (Dài * Rộng * Dày)',
    searchPlaceholder: 'Tìm kiếm tên tấm gỗ/hộp',
    checkArranged: 'Xem điều hình',
    checkErr: 'Chưa điều hình, không thể xem!',
    downloadSheet: 'Tải xuống mẫu bảng',
    downloadSheetShowScientific:
      'Tải về mẫu (cho thấy phương pháp đếm khoa học)',
    exportHoleSlotPDF: 'Xuất hình ảnh các lỗ và khe của tấm gỗ',
    style1: 'Kiểu 1',
    style2: 'Kiểu 2',
    style3: 'Kiểu 3',
    importNoCover: 'Nhập danh sách tài liệu không ghi đè',
    openBujianPlankRecommend: 'Mở các tấm nhỏ được đề nghị',
    openAwaitStorePlankRecommend: 'Mở sẵn sàng để sắp xếp bảng giới thiệu',
    openSurplusPlankRecommend: 'Mở dư liệu tấm đề nghị',
    pushToAwaitStore: 'Gửi vào kho để sắp xếp',
    setTableHead: 'Cài đặt tiêu đề danh sách tài liệu cắt gỗ',
    syncConfirm:
      'Dữ liệu nguyên liệu của Quì Quì và Điều hình trên đám mây không khớp! Đồng bộ hóa với dữ liệu nguyên liệu của Quì Quì?',
    handleSync: 'Chú ý: Có thể đồng bộ hóa thủ công trong quản lý nguyên liệu',
    noMoreTip: 'Không còn thông báo nữa',
    checkMore: 'Xem thêm',
    noHistory: 'Hiện tại chưa có lịch sử lưu trữ...',
    sameOrder:
      'Có đơn hàng đã xuất file sản xuất, hãy chú ý đến vấn đề sản xuất lặp!',
    markOrder: 'Có ghi dấu đã sản xuất trên trang đơn hàng?',
    editErrTip: 'Đa đơn hàng hiện tại không hỗ trợ sửa đổi thông tin',
    autoSaveSuccess: 'Đã tự động lưu vào lịch sử!',
    goAdd: 'Đi đến trang thêm',
    noProductionLine:
      'Dây chuyền sản xuất của biên bản này đã bị xóa, vui lòng đi đến danh sách tài liệu cắt gỗ để điều hình lại',
    fileErr:
      'Thất bại trong việc phân tích nội dung tệp, vui lòng kiểm tra nội dung tệp!',
    noCheckData: 'Không tìm thấy dữ liệu!',
    noArrangeTip:
      'Không thể xem trực tiếp điều hình, vui lòng nhấn Bắt đầu điều hình!',
    tooOld: 'Cài đặt máy khoan 5/6 mặt quá cũ',
    contactUs: 'Vui lòng liên hệ với nhân viên hậu mãi để kết nối lại',
    pathErr:
      'Tìm thấy dữ liệu bất thường bên trong tấm gỗ (hộp: {errorPathPlankID}), vui lòng kiểm tra!',
    sizeErr:
      'Bảng điều khiển có dữ liệu bất thường, vui lòng kiểm tra! Kích thước tấm nhỏ：{width}-{height}',
    noKnife:
      'Chưa cài đặt đường kính dao cắt, vui lòng đi đến máy khắc để cài đặt dao!',
    sendFactorySuccess: 'Gửi đến nhà máy thành công',
    sendFactoryErr:
      'Gửi đến nhà máy thất bại, vui lòng liên hệ với nhân viên hậu mãi để được trợ giúp',
    createTaskCard: 'Có tạo thẻ nhiệm vụ tương ứng?',
    surplusStatistics: 'Tổng số dư liệu có thể sử dụng là',
    surplusStatistics2: 'Đã chọn',
    suplementStatistic:
      'Có {total} tấm gỗ bổ sung, có thể sử dụng cùng với {useable} tấm gỗ khác, đã chọn {selected} tấm',
    expand: 'Mở rộng',
    packUp: 'Thu gọn',
    importErr: 'Vui lòng chọn tệp chính xác!',
    sendLoadingText: 'Đang xử lý và gửi tệp NC...',
    arrangeLoading: 'Cộng có',
    arrangeLoading2: 'tấm gỗ nhỏ đang được tối ưu hóa điều hình...',
    surplusHead: {
      shape: 'Hình dạng',
      type: 'Loại',
      remark: 'Ghi chú',
      area: 'Diện tích (m²)',
    },
    surplusTip: 'Không có vật liệu dư thừa nào. Bạn có thể đi đến',
    creatSurplus: 'Tạo Dư thừa',
    orderInfo: 'Thông tin Đơn hàng',
    suplement: {
      statistic:
        'Tổng cộng {total} bộ phận bổ sung, {useable} có thể được giao cùng, {selected} đã chọn',
      exportSuplement: 'Xuất danh sách bổ sung',
      suplementLoading: 'Đang tải dữ liệu bổ sung',
      exportSuplementIng: 'Đang xuất danh sách bổ sung',
      noMatchSup: 'Không tìm thấy dữ liệu bổ sung phù hợp',
      exportTip: 'Vui lòng chọn ít nhất một bộ phận bổ sung trước khi xuất',
    },
    empty: 'Không có dữ liệu bảng',

    paibanSetting: {
      title: 'Cài đặt Bố trí',
      selectProduction: 'Chọn Dây chuyền Sản xuất',
      arrangeWay: 'Chọn Phương pháp Bố trí',
      suitText: 'Áp dụng cho',
      suitEquipmentNormal: 'Máy Khắc thông thường',
      suitEquipmentSpecial: 'Máy Cắt Kính, Máy Cắt Gỗ',
      lessRoll: 'Ít Lật',
      computedSpecial: 'Tính toán Đặc biệt',
      specialTip: 'Tốc độ thấp',
      irregularNesting: 'Săn lùng bất quy tắc',
      primaryNesting: 'Săn lùng chính',
      specialShapedPanel: 'Bảng hình dạng đặc biệt',
      limitedTimeTrial: 'Thử dùng trong thời gian giới hạn',
      seniorShape: 'Hình dạng cao cấp',
      heteromorphicInlay: 'Phông lắp vào dị hình',
      autoCutSurplus: 'Tự động Cắt Vật liệu dư thừa',
      cutWay: 'Phương pháp Cắt',
      maxRect: 'Cắt thành Hình chữ nhật Tối đa',
      fixedRect: 'Cắt thành Kích thước Cố định',
      minRectSetting: 'Cài đặt Kích thước Cắt Tối thiểu',
      shortLine: 'Đoạn Ngắn Lớn hơn',
      longLine: 'Đoạn Dài Lớn hơn',
      surplusTemplate: 'Mẫu Vật liệu dư thừa Thông thường',
      add: 'Thêm',
      templateErr:
        'Khi chọn kích thước cắt thông thường, bạn cần chọn ít nhất một mẫu dữ liệu',
      cutLong: 'Chiều dài Cắt',
      cutWidth: 'Chiều rộng Cắt',
      baseInfo: 'Thông tin Cơ bản',
      continue: 'Xác nhận và Tiếp tục',
      standardSelect: 'Tiêu chuẩn lựa chọn tấm lớn',
      customPlankOrder: 'Tuỳ chỉnh thứ tự sắp đặt bảng lớn',
      customPlankOrderDetail: 'Danh sách bảng nhỏ chưa sắp xếp',
      customPlankOrderTip:
        'Chức năng nhúng bản đồ mây không có hiệu lực cho tính năng này',
      surplusPlank: 'Danh sách bảng nhỏ còn lại',
      overSizePlank: 'Danh sách các mảnh gỗ vượt quy định kích thước',
      plankOrder: 'Thứ tự typography',
      plankType: 'Loại tấm',
      plankSize: 'Kích thước tấm',
      plankEdgeOff: 'Giá trị cắt tỉa',
      amount: 'Số lượng xả',
      amountMax: 'Số lượng xả tối đa',
      numAndOverSizeTip: 'Có bảng nhỏ và bảng lớn không đủ để sắp chữ',
      numErrorTip: 'Không dùng đủ bảng để sắp chữ',
      overSizeTip: 'Có những tấm nhỏ không thể sắp chữ',
      restPartList: 'Danh sách bảng nhỏ chưa bố trí',
      layoutOrder: 'Thứ tự bố trí',
      arrangeAmount: 'Số lượng cắt',
    },

    filter: {
      title: 'Lọc Bảng',
      search: 'Tìm kiếm',
      all: 'Chọn tất cả',
      resetFilter: 'Đặt lại Bộ lọc',
    },
    edit: {
      title: 'Chỉnh sửa Nhóm',
      target: 'Chỉnh sửa Mục tiêu',
      onlineSelectRow: 'Chỉ chọn Các hàng đã chọn',
      allRow: 'Tất cả Các hàng',
      editKey: 'Nội dung để Chỉnh sửa',
      editValue: 'Chỉnh sửa thành',
      warningTip:
        'Lưu ý: Chỉnh sửa sẽ tự động xóa các lỗ, khe và hình dạng không đều',
      allEdge: 'Tất cả Các cạnh',
    },
    add: {
      title: 'Thêm Bảng',
      continue: 'Xác nhận và Tiếp tục',
      matCodeErr: 'Vật liệu không thể để trống',
      thickErr: 'Độ dày không thể để trống',
      colorErr: 'Màu sắc không thể để trống',
      longErr: 'Chiều dài hoàn thành không thể để trống',
      widthErr: 'Chiều rộng hoàn thành không thể để trống',
      countErr: 'Số lượng phải ít nhất là 1',
    },
    delete: {
      tip: 'Dữ liệu này không thể khôi phục sau khi xóa. Bạn có chắc chắn muốn xóa không?',
      errTip:
        'Vui lòng chọn ít nhất một bảng trước khi thực hiện thao tác này!',
      confirm: 'Xác nhận Xóa',
    },

    save: {
      errTip: 'Không có thông tin để lưu!',
      title: 'Lưu giải pháp',
      subTitle: '(Chú ý: lịch sử chỉ lưu trữ trong một năm)',
      name: 'Tên giải pháp',
      historyName: 'Giải pháp lịch sử',
      successTip: 'Lưu thành công!',
      fieldTip: 'Lưu thất bại!',
    },
    exportErr: 'Hiện chưa có tấm gỗ, không thể xuất',
    exportPicErr:
      'Gặp lỗi không xác định khi xuất hình ảnh khe chân của tấm gỗ, vui lòng liên hệ với chúng tôi để giải quyết',
    plankNotFound:
      'Không tìm thấy tấm gỗ liên quan! Vui lòng xác nhận đã vẽ tấm gỗ chưa!',
    noPlankName: 'Không tìm thấy tên tấm gỗ',
    plankDataErr:
      'Dữ liệu tấm gỗ không chính xác, vui lòng kiểm tra kỹ, để tránh tổn thất sản xuất',
    exportThreeViewErr:
      'Xuất hình ảnh tam chiếu khe chân thất bại, vui lòng liên hệ với hậu mãi để xử lý',
    heighGlossWarning:
      'Chú ý: Khi chất liệu là tấm gỗ có độ bóng cao, sau khi thay đổi chất liệu, tấm gỗ sẽ không thể khôi phục lại độ bóng cao!',
    reset: 'Khôi phục mặc định',
    settingHead: {
      title: 'Cài đặt tiêu đề danh sách tài nguyên mở rộng',
      head: 'Tiêu đề',
      example: 'Mẫu',
      tip: 'Ghi chú: Có thể kéo trái-phải để thay đổi vị trí nhãn dữ liệu tiêu đề',
    },
    copyLine: 'Nhấn để sao chép dòng hiện tại ở bên dưới',
    copySuccess: 'Sao chép dữ liệu {name} thành công',
    arranging: 'Tổng cộng có {count} tấm gỗ nhỏ đang được tối ưu hóa bố cục...',
    overSize: {
      title: 'Tấm gỗ quá lớn',
      subTitle: 'Danh sách tấm gỗ quá lớn',
      overSizeTip: 'Thông báo tấm gỗ quá lớn',
      nonWorkInfo: 'Chi tiết tấm gỗ không thể chế tạo',
      warning:
        'Phát hiện có kích thước tấm gỗ nhỏ quá dài, dẫn đến không thể bố cục! Vui lòng điều chỉnh kích thước tấm gỗ nhỏ hoặc thêm tấm nguyên liệu có kích thước tương ứng',
      checkInfo: 'Xem chi tiết tấm gỗ',
      tipContent:
        'Hệ thống phát hiện có tấm gỗ nhỏ trong bố cục có kích thước quá lớn, dưới đây là gợi ý thêm tấm đặc biệt, có thể sửa đổi kích thước tấm gỗ bằng tay sau đó nhấn nút <span class="bold color-0">“Thêm nhanh”</span> ở dưới bảng, để nhanh chóng thêm tiếp tục bố cục, hoặc cũng có thể thêm tấm gỗ bằng tay tại <span class="bold color-0">Cài đặt tấm nguyên liệu - Tấm đặc biệt</span>',
      add: 'Thêm nhanh',
      addPlank: 'Thêm tấm nguyên liệu',
      error: 'Tấm gỗ quá lớn, bố cục thất bại',
      successTip: 'Thêm tấm gỗ thành công! Đang bố cục lại',
      warningTip: 'Vui lòng điền kích thước dài và rộng của tấm gỗ!',
    },
    arrangeErr:
      'Dữ liệu bên trong tấm gỗ có bất thường, không thể tiến hành bố cục sản xuất',
    nonDelete: 'Không thể xóa',
    addOverSizeTip: 'Có kích thước quá khổ, vui lòng thêm lại',
    addPlankSuccess: 'Thêm thành công tấm bảng đặc biệt',
    addPlankFail: 'Thêm tấm bảng đặc biệt không thành công',
  },
  arrangedPage: {
    useFolderIsNot:
      'Phát hiện vị trí lưu tệp tải xuống "{type}" không tồn tại! Vui lòng kiểm tra xem tệp có bị xóa không. Nếu sử dụng ổ USB, vui lòng đảm bảo rằng nó đã được kết nối đúng cách',
    useFolderIsNot1:
      'Phát hiện bạn chưa đặt vị trí tải tệp, vui lòng chọn thư mục trước',
    downloadNC: 'Tải xuống NC',
    downloadNC1: 'Tải xuống tệp NC',
    reArrange: 'Bố cục lại',
    saveToHistory: 'Lưu vào lịch sử',
    moreSetting: 'Thêm',
    followExportTag: 'Tải xuống NC cùng với xuất tệp nhãn',
    tagTemplateSelect: 'Chọn mẫu nhãn',
    showCuttingSequence: 'Hiển thị trình tự cắt',
    showPlankSize: 'Hiển thị kích thước tấm gỗ nhỏ',
    sendToFactory: {
      title: 'Gửi đến xưởng',
      fileType: 'Loại tệp nhãn',
    },
    checkData: 'Đang kiểm tra tính toàn vẹn của dữ liệu...',
    dataLoading: 'Đang nỗ lực tải dữ liệu...',
    addToBujianStore: 'Đang thêm vào kho phụ kiện...',
    updateTaskStatus: 'Đang cập nhật trạng thái nhiệm vụ...',
    sending: 'Đang gửi đến xưởng',
    waitLoadding: 'Đang chờ',
    loadding: 'Đang',
    finish: 'Hoàn thành',
    loadSetting: 'Tải cài đặt',
    loadPreference: 'Tải cài đặt tùy chọn',
    dealTemplate: 'Xử lý mẫu',
    initTagData: 'Khởi tạo dữ liệu nhãn',
    prepareDrawData: 'Chuẩn bị dữ liệu vẽ nhãn',
    drawDataText: 'Dữ liệu vẽ nhãn',
    genPdfTagFile: 'Tạo file nhãn PDF',
    genPicTagFile: 'Tạo file hình ảnh nhãn',
    uploadFile: 'Tải lên file',
    dealNCFile: 'Xử lý file NC',
    zipFile: 'Tạo file nén địa phương',
    plankCount: 'Số lượng tấm lớn',
    rollCount: 'Số lần lật bản',
    totalRate: 'Tỷ lệ tối ưu hóa tổng thể',
    rate: 'Tỷ lệ tối ưu hóa',
    materialList: 'Danh sách nguyên liệu',
    saveSurplus: 'Nhập kho dư liệu',
    workeShop: 'Đơn mua hàng',
    holeSlotErr: 'Đã phát hiện tấm gỗ có độ sâu khe/lỗ bất thường!',
    knivesErr: 'Phát hiện bảng không tìm thấy dụng cụ gia công!',
    checkDetail: 'Xem chi tiết',
    deepErr: {
      title: 'Độ sâu khe/lỗ tấm gỗ bất thường',
      errStatistic: 'Có {count} điểm bất thường ở tấm nhỏ',
      errText: 'Bộ phận bất thường',
      markErrPart: 'Đánh dấu bộ phận bất thường',
    },
    warningTip:
      'Đối với tấm gỗ có bề mặt sáng hai mặt: xử lý trước <span class="font-style">mặt sáng</span>, sau đó xử lý <span class="font-style">mặt mờ</span>; Đối với tấm gỗ có bề mặt sáng một mặt: xử lý trực tiếp <span class="font-style">mặt mờ</span>',
    showDetail: {
      tip1: 'Nhấn để chọn một tấm gỗ',
      tip2: 'Xem nhãn tấm gỗ',
    },
    arrangeTip: '(Đã hỗ trợ bố cục lồng nhau cho tấm hình dị)',
    plankOpration: 'Thao tác tấm gỗ',
    rotate: 'Xoay 90 độ',
    notCare: 'Tấm gỗ không có họa tiết',
    editRemark: 'Biên tập chú thích',
    clearRemark: 'Xóa chú thích',
    recalcIndex: 'Tính toán lại điểm cắt và trình tự cắt',
    storageArea: {
      title: 'Khu vực tạm lưu tấm gỗ',
      empty: 'Không có tấm gỗ tạm lưu',
      storageTip: 'Bạn có thể kéo tấm gỗ đến đây để tạm lưu',
    },
    subtleArrange: 'Bố cục chi tiết',
    addPlank: 'Thêm tấm lớn',
    deletePlank: 'Xóa tấm lớn',
    deletePlankErr: 'Tấm lớn hiện tại có tấm nhỏ, không thể xóa tấm lớn!',
    useSurplus: {
      title: 'Sử dụng dư liệu',
    },
    dragWarning: 'Vui lòng kéo tấm gỗ đến tấm lớn!',
    areaWarning: 'Không thể đặt tấm gỗ, chú ý kích thước không gian trống',
    subtlePage: {
      cut: 'Cắt dư liệu',
      cancelCut: 'Hủy cắt',
      cutPoint: 'Điểm hấp thụ cắt dư liệu',
      storageArea: 'Khu vực tạm lưu',
      cutErr: 'Chỉ có thể cắt trên tấm lớn, không được chạm vào tấm nhỏ!',
      saveErr: 'Vui lòng chọn một dư liệu để cắt trước',
      cutErr2: 'Chỉ có thể cắt trên tấm lớn, không được vượt quá tấm lớn!',
      selectTip: 'Vui lòng chọn một tấm dư liệu để cắt',
    },
    shortCut: 'Phím tắt',
    updownRoll: 'Trượt lên xuống',
    leftRightRoll: 'Trượt trái phải',
    zoomScale: 'Phóng to thu nhỏ',
    dragCanvas: 'Kéo trang',
    mouseRoll: 'Cuộn chuột',
    mouseRight: 'Nhân chuột phải',
    doublePlank: 'Nhân đúp tấm lớn',
    keyboard: 'Bàn phím',
    picSymble: 'Bản đồ',
    funished: 'Sản phẩm hoàn thành',
    holeSlotConflict1: 'Xung đột khe/lỗ',
    plankErr: 'Xung đột tấm gỗ',
    frontSlot: 'Khe mặt trước',
    backSlot: 'Khe mặt sau',
    frontHole: 'Vị trí lỗ mặt trước',
    backHole: 'Vị trí lỗ mặt sau',
    resetScale: 'Đặt lại tỷ lệ phóng to',
    maxLong: 'Dài nhất',
    tempErr: 'Thêm thất bại, vị trí mẫu không chính xác hoặc mẫu quá lớn!',
    sendErr:
      'Gửi thất bại đến xưởng, vui lòng kiểm tra mẫu nhãn và dữ liệu tấm gỗ là chính xác trước khi gửi lại',
    someTips:
      'Bạn đang dùng thử chức năng bố cục hình dị trên đám mây có thời gian giới hạn, hãy chú ý tránh các vấn đề trong sản xuất!',
    cutOrderErr:
      'Thứ tự cắt không chính xác, vui lòng nhấn để tính toán lại điểm cắt và thứ tự cắt!',
    holeSlotConflict:
      'Phát hiện xung đột lỗ khe: Tấm bảng có xung đột lỗ khe hoặc mảnh nhỏ bay ra',
    exportSetting: 'Cài đặt xuất',
    specialPaibanErr:
      'Có tấm gỗ có công nghệ đặc biệt, không hỗ trợ bố cục lại!',
    moreResult: 'Nhiều kết quả bố cục',
    batchBujian: 'Bổ sung hàng loạt',
    stored: 'Đã nhập kho',
    onlySurplus: 'Chỉ xem dư liệu',
    unit: 'Khối',
    cutSurplus: 'Cắt dư liệu',
    frontWarningTip:
      'Đối với tấm gỗ có bề mặt sáng hai mặt: xử lý trước <span class="font-style">mặt mờ</span>, sau đó xử lý <span class="font-style">mặt sáng</span><span>; &nbsp; Đối với tấm gỗ có bề mặt sáng một mặt: xử lý trực tiếp </span <span class="font-style">mặt sáng</span>',
    selectTip: 'Vui lòng chọn tấm nhỏ cần bổ sung ở phía dưới!',
    plankKeyWordPlaceholder: 'Vui lòng nhập tên tấm gỗ hoặc số tấm',
    surplusStoreSuccess: 'Nhập kho thành công',
    editCutOrder: 'Sửa đổi thứ tự cắt',
    setCutPoint: 'Đặt điểm cắt',
    specialPaibanChangeErr:
      'Có tấm gỗ có công nghệ đặc biệt, không hỗ trợ chuyển đổi bố cục!',
    storedSuccess:
      'Bổ sung thành công! Tấm gỗ đã chọn đã được đưa vào quản lý bổ sung!',
    noSurplus: 'Không tìm thấy dư liệu có thể sử dụng',
    selectErrTip:
      'Tấm dư liệu không thể bổ sung, vui lòng chọn tấm nhỏ bình thường trước',
    noRoomIDTip:
      'Tấm gỗ này không có ID phòng, không thể bổ sung và nhập kho, vui lòng chọn tấm nhỏ khác trước',
    noPlank: 'Không tìm thấy tấm gỗ',
    noPaibanData: 'Không có dữ liệu bố cục, không thể tiến hành',
    historyNameErr: 'Vui lòng nhập tên bố cục',
    tempErrTip:
      'Chúng tôi phát hiện bạn chưa thêm mẫu dùng cho máy khoan 5/6 mặt, vì vậy không thể xuất tập tin gia công máy khoan 5/6 mặt. Lưu ý: bạn cần bố cục lại sau khi thêm mẫu',
    changeDataTip:
      'Lưu ý: Chúng tôi phát hiện dữ liệu của bạn đã thay đổi. Bạn có muốn tạo một tệp lịch sử mới để lưu trữ thay đổi này không?',
    upgrade: 'Nâng cấp',
    upgradeTip: 'Bạn có thể nhấn nút bên dưới để nâng cấp phiên bản',
    plankOverEdge:
      'Tải xuống NC thất bại! Vui lòng kiểm tra xem có tấm gỗ chồng lên nhau hoặc nằm ngoài tấm lớn không',
    useSurplusTip: 'Có muốn khấu trừ dư liệu không?',
    noDataExport: 'Không có dữ liệu bố cục có thể xuất',
    noPlankExport: 'Không có dữ liệu tấm nhỏ có thể xuất',
    lockTip1: 'Nhấn để mở khóa, sau khi mở khóa bạn có thể',
    lockTip2: 'sửa đổi vị trí và thuộc tính tấm nhỏ',
    plankLockErr: 'Tấm lớn này đã bị khóa không thể đặt',
    lockPartEditTip: 'Tấm gỗ bị khóa, không thể sửa',
    glassEquipmentTip:
      'Khi dây chuyền là máy cắt thủy tinh, tấm gỗ không thể di chuyển',
    dragErrTip:
      'Không thể đặt, vui lòng kiểm tra thuộc tính chất liệu, màu sắc và độ dày của tấm gỗ!',
    subtleSaveTip:
      'Hiện tại đã có thao tác trên tấm gỗ nhưng chưa lưu, bạn có muốn thoát không?',
    rollTip:
      'Có lỗ thông/khe thông trên tấm lớn, không khuyến khích đảo ngược!',
    glossRollErr: 'Tấm gỗ có bề mặt sáng không được đảo ngược',
    lockDeleteErr: 'Tấm gỗ bị khóa, không thể xóa',
    deleteConfirmTip: 'Thao tác xóa không thể khôi phục',
    deleteContinueTip: 'Bạn có muốn tiếp tục không?',
    deleteConfirm: 'Vui lòng xác nhận xóa',
    selectPlankTip: 'Vui lòng chọn tấm gỗ trước khi tiến hành thao tác này',
    storeFailTip:
      'Nhập kho dư liệu thất bại! Vui lòng kiểm tra xem có tấm gỗ chồng lên nhau hoặc nằm ngoài tấm lớn không',
    repeatStoreErr: 'Tấm dư liệu cắt hiện tại đã được nhập kho',
    surplusStoreFail:
      'Nhập kho thất bại, vui lòng kiểm tra xem tấm gỗ có hình chữ nhật hoặc chữ L không!',
    plankConfilictErr:
      'Không hỗ trợ đặt điểm ở đây, sẽ gây xung đột với các tấm gỗ khác',
    surplusErrTip: 'Không hỗ trợ đặt điểm ở đây, sẽ tạo ra dư liệu không đúng',
    changeArrageSuccess: 'Bố cục thành công',
    trialProduction: {
      trialProductionSample: 'Thử nghiệm mẫu tủ',
      trialProduced: 'Tôi đã thử nghiệm sản xuất',
      trialWarning:
        'Bởi vì bạn đã không làm mẫu tủ sản xuất xác nhận, và gây ra cho bản thân mình, những người khác và phần mềm phân loại đám mây tất cả thiệt hại và hậu quả bất lợi của phần mềm sẽ là cá nhân của bạn chịu đầy đủ trách nhiệm',
      pleaseUploadTrial:
        'Xin vui lòng tải lên hình ảnh thử nghiệm sau khi lắp ráp mẫu请上传组装好后的试生产样品柜照片',
      uploadTrial: 'Tải lên mẫu tủ',
    },
    awaitPlankStore: {
      store: 'Hội đồng quản trị bảng xếp hàng',
      notTip: 'Không còn nhắc đến việc không được đưa vào kho',
      justLookCurrent: 'Chỉ nhìn vào bảng đặt hàng hiện thời',
      notInPushStorage: 'Không có trong kho',
      beInStorage: 'Đã được lưu trữ',
      searchPlaceholder: 'Nhập đơn đặt hàng tìm kiếm các bảng nhỏ để sắp xếp',
      plankUploading: 'Bảo quản các mảng',
      oneClickStorage: 'Một phím được gửi vào kho hàng chờ đợi',
      notUsePlank: 'Không có bảng điều khiển',
      notPlank: 'Không có tấm tạm thời lưu trữ',
      dragPlankInHere: 'Tấm có thể được kéo đến đây để lưu trữ tấm tạm thời',
      surplusCannotBeStorage: 'Dư liệu không thể vào kho',
      plankHasBeenDeduction:
        'Bảng nhỏ đã được rút hoặc không có dữ liệu thô, không thể được lưu trữ',
      plankLoadErr: 'Lỗi tải thông tin bảng',
      taskTitle: 'Tải lên các mảng để sắp xếp thư viện',
      dataGenErr: 'Lỗi sản xuất dữ liệu',
      pushSuccess: 'Kho lưu trữ thành công',
      pushErr: 'Lỗi không xác định, lỗi nhập cảnh',
      awaitBoxExistPlank:
        'Sẵn sàng để sắp xếp hàng tồn kho trong các bảng nhỏ không được nhập, được đề nghị để hoạt động sau khi nhập',
      checkPlankStatus: 'Đang kiểm tra trạng thái hội đồng quản trị …',
    },
    batchSetCut: 'Đặt điểm cắt hàng loạt',
    applyTo: 'Áp dụng cho',
    ncDownloadLifeText: {
      checkData: 'Đang kiểm tra dữ liệu',
      checkDone: 'Kiểm tra dữ liệu hoàn thành',
      checkDataText: 'Phát hiện dữ liệu',
      loadSetting: 'Đang tải cài đặt',
      settingLoadDone: 'Cài đặt tải xong',
      loadSettingText: 'Tải cài đặt',
      downloadNc: 'Đang tải xuống NC',
      ncDownloadDone: 'Tải xuống NC hoàn thành',
      downloadText: 'Tải xuống NC',
      loadPreferenceSetting: 'Đang tải cài đặt tùy chọn',
      PreferenceSettingLoadDone: 'Cài đặt tùy chọn tải xong',
      loadPreferenceText: 'Tải các tùy chọn',
      dealTemp: 'Đang xử lý mẫu',
      TempDealDone: 'Xử lý mẫu hoàn thành',
      dealTempText: 'Xử lý mẫu',
      beBeingGenTagFile: 'Đang tạo tệp {fileType}',
      genTagFileDone: 'Tạo tệp {fileType} hoàn thành',
      genTagFileText: 'Tạo tệp {fileType}',
      zip: 'Đang tạo tệp nén cục bộ',
      zipDone: 'Tạo tệp nén cục bộ hoàn thành',
      genZipText: 'Tạo tệp nén',
      fileTypeText: 'Hình ảnh',
    },
    surplusCommonTemp: 'Mẫu thừa phổ biến',
    plankErrTip:
      'Kế hoạch vật liệu hiện tại có bảng nhỏ không có bảng lớn có sẵn để bố trí!',
    overSizeTip: 'Số lượng bảng lớn không đủ hoặc quá khổ',
    uselessPart: 'Không có bảng nhỏ nào có thể sử dụng trên bảng lớn hiện tại',
    splitArranged:
      'Số lượng bảng nhỏ quá nhiều, khuyến nghị sắp xếp theo từng đợt',
    arrangedErrTip:
      'Bố trí thất bại do số lượng bảng lớn không đủ hoặc bảng nhỏ quá khổ',
    knivesModalTitle: 'Có lỗ khoét nhưng không tìm thấy dao cụ',
    cannotDownloadByKnivesErr:
      'Không thể tải xuống NC, hệ thống phát hiện các lỗ rãnh sau không tìm thấy dao cụ',
    viewPart: 'Xem bảng câu hỏi',
  },
  historyPage: {
    download: 'Tải xuống máy',
    allType: 'Lưu tất cả',
    handleSave: 'Lưu thủ công',
    autoSave: 'Lưu tự động',
    allArrange: 'Bố cục tất cả',
    searchPlaceholder: 'Tìm kiếm tên đơn hàng/đợt đặt chữ',
    trailProduction: {
      title: 'Tủ mẫu thử sản xuất',
      lastTime: 'Ngày thử sản xuất lần cuối',
      recordList: 'Lịch sử thử sản xuất',
      start: 'Bắt đầu thử sản xuất',
      date: 'Thời gian',
      outputFrom: 'Vị trí xuất',
      empty: 'Không có dữ liệu',
      record: 'Lịch sử ảnh tủ mẫu thử sản xuất đã tải lên',
      tip1: 'Lưu ý: Đơn hàng thử nghiệm hiện tại đang sử dụng dữ liệu sản xuất mặc định',
      tip2: 'Lưu ý: Đơn hàng thử nghiệm hiện tại không sử dụng các tham số cài đặt công nghệ mới nhất, bạn có thể đến phòng thử nghiệm để lưu lại!',
      download: 'Tải xuống tập tin gia công',
    },
    deleteTip: 'Bạn có chắc chắn muốn xóa lịch sử sau đây không?',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
    lastWeek: 'Tuần gần đây',
    lastMonth: 'Tháng gần đây',
    lastSeason: 'Ba tháng gần đây',
    toArrangePage: 'Đang chuyển đến trang bố cục',
    notArrangedTip:
      'Bản ghi này chưa được bố cục, vui lòng đi đến danh sách nguyên liệu!',
    toMaterialPage: 'Đang chuyển đến trang danh sách nguyên liệu',
    lessSelectTip:
      'Vui lòng chọn ít nhất một bản ghi để tiến hành thao tác này',
    downloadNc: 'Tải xuống tệp xử lý',
  },
  workShopPage: {
    warningTip:
      'Chức năng này là dùng thử giới hạn thời gian, vui lòng sắp xếp sản xuất đơn hàng của bạn hợp lý!',
    fileManager: 'Quản lý tệp sản xuất',
    selected: 'Đã chọn',
    suplement: 'Bù thiếu phụ tùng',
    size: 'Kích thước',
    saveToStore: 'Thêm vào kho bù thiếu phụ tùng',
    enterArrange: 'Đang chuyển đến trang bố cục...',
    deleteTip:
      'Sau khi xóa dữ liệu này, nó sẽ không thể khôi phục lại, bạn có chắc chắn muốn xóa dữ liệu này không?',
    arrangeTip: 'Đang phân tích dữ liệu bù thiếu phụ tùng, vui lòng đợi...',
    store: 'Kho bù thiếu phụ tùng',
    downloadFile: 'Tải xuống tệp sản xuất',
    notBujianFile: 'Không tìm thấy tệp bổ sung',
    productLineDelete:
      'Dòng sản xuất này đã bị xóa, không thể tải xuống tệp NC!',
  },
  basePlankManager: {
    saveTip: 'Chú ý: Nhấn Enter để lưu',
    offsetSize: 'Kích thước chiết rìa',
    overSizeTip:
      'Khi kích thước quá lớn, hệ thống sẽ tự động chọn tấm lớn hơn để bố cục',
    addTip:
      'Khi bố cục, nếu chi tiết bàn quá lớn, hiện cửa sổ tự động thêm tấm lớn đặc biệt',
    onlyLayoutTooBig:
      'Tấm ván nhỏ quá khổ chỉ được xếp trên tấm ván lớn quá khổ',
    settingRemark:
      'Lưu ý: Chức năng ban đầu ở đây đã được điều chỉnh sang phần cài đặt bên phải.',
    searchPlaceholder: 'Tìm kiếm chất liệu/màu sắc tấm',
    syncData: 'Đồng bộ hóa dữ liệu tủ',
    deletePlank: 'Xóa tấm lớn đặc biệt',
    addPlank: 'Tạo mới tấm đặc biệt',
    matCodePlaceholder: 'Vui lòng chọn chất liệu tấm',
    thickPlaceHolder: 'Vui lòng chọn độ dày',
    widthErr: 'Chiều rộng tấm không được để trống',
    heightErr: 'Chiều dài tấm không được để trống',
    matCodeErr: 'Chất liệu tấm không được để trống',
    colorErr: 'Màu sắc tấm không được để trống',
    editPlank: 'Chỉnh sửa tấm',
    deleteTip:
      'Sau khi xóa dữ liệu này, nó sẽ không thể khôi phục lại, bạn có chắc chắn muốn xóa dữ liệu này không?',
    plankKnifeSelect: 'Chọn dao cắt nguyên tấm',
    knifeSelectTip: 'Chú ý: Bố cục nhúng hiện tại không hỗ trợ chức năng này',
    knife: 'Dao cắt',
    matcodeInput: 'Vui lòng nhập chất liệu tấm',
    thickInput: 'Vui lòng nhập độ dày tấm',
    default: 'Mặc định hệ thống',
    unessential: 'Cột này: Không bắt buộc điền',
    emptyTip:
      'Chú ý: Khi không điền vào cột này, khớp phù hợp nguyên tấm sẽ không phân biệt dữ liệu cột này!',
    defaultUse: 'Chọn mặc định',
    editMatcode: 'Chỉnh sửa chất liệu',
    syncTip:
      'Sẽ lấy dữ liệu từ {fromMc} và ghi đè lên dữ liệu quản lý nguyên tấm của bố cục đám mây hiện có, có đồng bộ hóa không?',
    sameErr: 'Dữ liệu tương tự đã tồn tại',
    cancelDelete: 'Đã hủy xóa',
    syncErr: 'Đồng bộ hóa thất bại',
    syncSuccess: 'Đồng bộ hóa thành công',
    syncDataTip: 'Đồng bộ hóa dữ liệu từ ${fromMc}',
    editErrTip: 'Có thông tin chưa điền, vui lòng kiểm tra!',
    numberCheck: '{type} chỉ được nhập số!',
    deleteStandardPlate: 'Loại bỏ các tấm tiêu chuẩn',
    createStandardPlate: 'Bảng tiêu chuẩn mới',
    editStandardPlate: 'Chỉnh sửa bảng tiêu chuẩn',
    trimmingDimension: 'Kích thước cắt bớt',
  },
  bujianAndStore: {
    bujianStore: 'Kho bổ sung',
    awaitStore: 'Kho chờ xếp ván',
    depositAwaitStore: 'Lưu vào kho chờ xếp ván',
    pushToAwaitStore: 'Đưa vào kho chờ xếp ván',
    useTwoTableArrange: 'Sử dụng hai bảng để xếp ván',
    confirmUseTwoTableTip:
      'Các tấm nhỏ được chọn hiện tại bao gồm cả kho bổ sung và kho chờ xếp ván. Bạn có muốn tiếp tục xếp ván không?',
    confirmAddPlankToAwaitStore:
      'Bạn có chắc chắn muốn thêm các tấm ván đã chọn vào kho chờ xếp ván không?',
    pushStoreSuccess: 'Nhập kho thành công',
    dataGenErr: 'Lỗi tạo dữ liệu',
    unknownErr: 'Đã xảy ra lỗi không xác định, nhập kho thất bại',
  },
  surplusPage: {
    surplusMaterial: 'Danh sách vật liệu thừa',
    surplusStore: 'Kho vật liệu thừa',
    surplusShape: 'Hình dạng vật liệu thừa',
    batchTransfer: 'Chuyển hàng loạt',
    createNewSurplusStore: 'Tạo kho thừa mới',
    pushStoreSetting: 'Cài đặt lưu trữ vật liệu thừa',
    surplusStoreName: 'Tên kho thừa',
    surplusStoreName2: 'Tên kho thừa',
    addSurplusStore: 'Thêm kho thừa',
    inventoryCeiling: 'Giới hạn tồn kho',
    autoTransferTip:
      'Sau khi xóa, vật liệu thừa trong kho sẽ tự động chuyển sang danh sách vật liệu thừa',
    asIndexPushStore: 'Lưu trữ theo thứ tự theo tình trạng lưu trữ kho',
    pushToSurplusMaterial: 'Lưu vào danh sách vật liệu thừa',
    surplusStoreTable: {
      number: 'Số thứ tự',
      name: 'Tên kho thừa',
      quantity: 'Số lượng vật liệu thừa đã lưu (cái)',
      capacity: 'Giới hạn tồn kho vật liệu thừa (cái)',
      remark: 'Ghi chú',
      operation: 'Thao tác',
    },
    editSurplusStore: 'Chỉnh sửa kho thừa',
    addSurplus: 'Thêm dư liệu',
    deletSurplus: 'Xóa dư liệu',
    editSurplus: 'Chỉnh sửa dư liệu',
    surplusNoRoll: 'Kích hoạt chế biến dư liệu không đảo ván',
    type: {
      all: 'Tất cả hình dạng',
    },
    allMatcode: 'Tất cả chất liệu',
    allThick: 'Tất cả độ dày',
    surplusName: 'Tên dư liệu',
    rectSurplus: 'Dư liệu hình chữ nhật',
    LTypeSurplus: 'Dư liệu hình chữ L',
    noMatchSup: 'Không tìm thấy dữ liệu liên quan',
    jumpTo: 'Chuyển đến',
    page: 'Trang',
    newMatcode: 'Tạo mới chất liệu',
    newColor: 'Tạo mới màu sắc',
    autoCreateColor: 'Nhấn Enter để tự động tạo màu sẵn',
    nameLimit: 'Tên dư liệu tối đa 10 ký tự',
    nameLimitNameErr: 'Tên dư liệu không được để trống',
    tempLongTip: 'Chiều dài tấm chỉ được nhập số',
    minLongLimit: 'Chiều dài cạnh ngắn chỉ được nhập số',
    minLongEmptyTip: 'Chiều dài cạnh ngắn không được để trống',
    minLongLimitMax: 'Chiều dài cạnh ngắn không được vượt quá chiều dài tấm',
    widthLimit: 'Chiều rộng tấm chỉ được nhập số',
    minWidthLimit: 'Chiều rộng cạnh ngắn chỉ được nhập số',
    minWidthEmptyLimit: 'Chiều rộng cạnh ngắn không được để trống',
    minWidthLimitMax: 'Chiều rộng cạnh ngắn không được vượt quá chiều rộng tấm',
    thickLimit: 'Độ dày tấm chỉ được nhập số',
    thickEmptyLimit: 'Độ dày tấm không được để trống',
    countInputLimit: 'Số lượng chỉ được là số nguyên dương',
    countEmptyLimit: 'Số lượng không được để trống',
    remarkMaxlimit: 'Ghi chú tối đa 50 ký tự',
    createTime: 'Thời gian thêm',
    branchName: 'Kho dư liệu',
  },
  printPage: {
    deleteWarning: 'Gợi ý ấm áp',
    print: 'In',
    defaultTemplate: 'Mẫu mặc định（60 x 40）',
    editTemplate: 'Chỉnh sửa mẫu',
    deleteTip:
      'Mẫu sẽ không thể khôi phục sau khi xóa, bạn có chắc chắn muốn xóa mẫu này?',
    tagSetting: 'Cài đặt nhãn',
    rememberSetting: 'Cài đặt sẽ được ghi nhớ sau khi thay đổi',
    genCutingTag: 'Tạo nhãn các phần đã cắt',
    genSurplusTag: 'Tạo nhãn dư liệu',
    genPlankTag: 'Tạo nhãn tấm lớn',
    tagPosition: 'Vị trí nhãn',
    tagPositionTip: '(Chỉ cần điền vào tọa độ X/Y nếu sử dụng máy dánh nhãn)',
    Xposition: 'Tọa độ X',
    Yposition: 'Tọa độ Y',
    genPlankSuffix: 'Hậu tố tên tệp của nhãn tấm lớn',
    arrangeMirror: 'Hình ảnh nhãn phản chiếu',
    drillPlankBase: 'Chuẩn mực đặt tấm của máy khoan 5-6 mặt',
    suitAuto: 'Sắp xếp nhãn phù hợp với tự động đặt/hạ tám:',
    up: 'Lên',
    down: 'Xuống',
    default: 'Mặc định',
    printSetting: 'Cài đặt in',
    oneToMore: 'Một trang nhiều trang',
    perPage: 'Mỗi trang',
    coloumn: 'Cột',
    tagMargin: 'Khoảng cách nhãn',
    coloumnMargin: 'Khoảng cách giữa các cột',
    specialMark: 'Điểm đặc biệt',
    opacity: 'Độ mờ',
    suite: 'Tự động thích nghi',
    size: 'Kích thước',
    lightSide: 'Bên nhìn thấy',
    locFrontSide: 'Mặt trước tủ',
    openSide: 'Hướng mở cửa',
    extraTip:
      'Chú ý: Khi sử dụng trực tiếp để in lần đầu tiên, vui lòng thêm một tờ giấy với kích thước sử dụng và chiều dài/chiều rộng điều chỉnh trong cài đặt máy in, sau đó sử dụng giấy mới để in (ví dụ: kích thước giấy gốc 60×40, kích thước giấy mới 40×60).',
    noMore: 'Không có nữa',
    prepareData: 'Đang chuẩn bị dữ liệu...',
    genTagFile:
      'Đang tạo nhãn, vui lòng không chuyển đổi trang, thu nhỏ trình duyệt, cuộn nhãn lên/xuống!',
    waitGen: 'Vui lòng đợi nhãn tải hoàn thành trước khi tải xuống',
    tempLoading: 'Đang tải mẫu nhãn, vui lòng chờ',
    front: 'Trước',
    open: 'Mở',
    toEdit: 'Chuyển đến chỉnh sửa',
    simplifyOneCodeTip:
      'Chú ý: Thuộc tính mã vạch của mẫu nhãn này không hỗ trợ chức năng "Mã vạch đơn giản", vui lòng sửa đổi thuộc tính mã vạch (Thuộc tính mã vạch khuyến nghị: CODE128)',
    plankTemp: 'Mẫu nhãn tấm nhỏ',
    surplusTemp: 'Mẫu nhãn dư liệu',
    tempNamePlaceHolder: 'Vui lòng nhập tên mẫu',
    editTip:
      'Bạn có thể kéo các nút bên trái vào nhãn bên phải để chỉnh sửa mẫu nhãn theo nhu cầu',
    oneCode: 'Mã vạch',
    image: 'Hình ảnh',
    editPage: {
      title: 'Chỉnh sửa mẫu nhãn',
      reset: 'Khôi phục mặc định',
      exit: 'Thoát chỉnh sửa',
      tagInfo: 'Thông tin nhãn',
      tagName: 'Tên nhãn',
      tagSize: 'Kích thước nhãn',
      tagData: 'Dữ liệu nhãn',
      dragTip: 'Nhấn chuột trái kéo ra nhãn dữ liệu',
      dataTip: 'Vui lòng chọn dữ liệu bên phải',
      text: 'Văn bản',
      dataSource: 'Dữ liệu thông tin',
      graph: 'Hình ảnh đồ họa',
      typograpyPic: 'Hình ảnh bản in',
      barCode: 'Mã vạch',
      QRCode: 'Mã QR',
      plankMark: 'Đánh dấu tấm',
      holeSlotPic: 'Hình ảnh lỗ khoan',
      holeSlotPicZoom: 'Phóng to hình ảnh lỗ khoan',
      rotateDeg: 'Góc xoay',
      colorDeep: 'Độ sâu màu',
      surplusMark: 'Đánh dấu dư liệu',
      surplusSizePic: 'Hình ảnh kích thước dư liệu',
      dataSize: 'Kích thước dữ liệu',
      extraList: 'Danh sách phụ',
      QRCodeFormat: 'Định dạng mã QR',
      formatQR: 'Định dạng QR',
      formatDM: 'Định dạng DM',
      locIndex: 'Số thứ tự tủ',
      plankFrontSign: 'Đánh dấu mặt trước tấm',
      genNCSuffix: 'Tạo hậu tố NC',
      table: 'Bảng',
      inputPlaceholder: 'Vui lòng nhịp đúp để nhập văn bản',
      attribute: 'Thuộc tính',
      fontFamily: 'Khoảng trực tiếp',
      fontSize: 'Kích thước chữ',
      fontWeight: 'Đen chữ',
      autoRow: 'Chuyển dòng tự động',
      rowMargin: 'Khoảng cách giữa các dòng',
      autoFontSize: 'Tự động điều chỉnh kích thước chữ khi dữ liệu quá nhiều',
      alignWay: 'Cách cách liên kết',
      JustifyLeft: 'Căn trái',
      JustifyRight: 'Căn phải',
      JustifyCenter: 'Căn giữa',
      otherOpration: 'Hành động khác',
      attributeReset: 'Thiết lập lại tham số',
      barCodeSize: 'Kích thước mã vạch',
      installPic: 'Hình ảnh lắp đặt',
      plankCode2: 'Mã vạch tấm (noMark)',
      frontCode: 'Mã vạch mặt trước',
      backCode: 'Mã vạch mặt sau',
      orderRemark: 'Ghi chú đơn hàng',
      NCFileName: 'Tên tệp tách liệu',
      NCFileNameTip:
        'Tên tệp tách liệu không chứa tiếng Trung và độ dài không quá 10 ký tự',
      showOpenSide: 'Hiển thị hướng mở cửa',
      limitFixed: 'Giới hạn số chữ số sau dấu phẩy',
      showFrontHS: 'Hiển thị lỗ khoan mặt trước',
      showBackHS: 'Hiển thị lỗ khoan mặt sau',
      showPlankSize: 'Hiển thị kích thước tấm',
      tempNamePlaceholder: 'Vui lòng nhập tên nhãn',
      deleteAreaTip: 'Vui lòng chọn khu vực để xóa',
      noHoleSlot: 'Hiển thị mã vạch khi không có lỗ khoan',
      rotate: 'Xoay mã vạch',
      barcodeAtrribute: 'Thuộc tính mã vạch',
      imgSize: 'Kích thước hình ảnh',
      noEdgeDisplay: 'Không hiển thị nếu cạnh đóng biên bằng 0',
      imgRotate: 'Xoay hình ảnh',
      circleD: 'Đường kính khung tròn',
      roomNo: 'Số phòng',
      roomOrder: 'Thứ tự tủ (Sắp xếp theo phòng)',
      alphabetically: 'Sắp xếp theo bảng chữ cái',
      numberOrder: 'Sắp xếp theo số',
      specialMarkup: 'Ký hiệu đánh dấu đặc biệt',
    },
    autoSaveToHistory: 'Đã tự động lưu vào lịch sử!',
    firstPrintTip:
      'Khi sử dụng in trực tiếp lần đầu tiên, vui lòng thêm kích thước giấy mới với chiều dài và chiều rộng hoán đổi trong cài đặt máy in, sau đó sử dụng giấy mới thêm để in',
    tagDownloading: 'Đang tải nhãn...',
  },
  fileManagerPage: {
    notOpeFolderPermission: 'Thao tác thư mục không được ủy quyền',
    notFindFolder:
      'Không thể phát hiện thư mục hiện tại trên máy khách hiện tại',
    productLineUniversal: 'Phổ biến cho tất cả các dây chuyền sản xuất',
    effectCondition:
      'Thay đổi cài đặt này sẽ chỉ có hiệu lực sau khi sắp xếp lại',
    addPath: 'Thêm đường dẫn',
    downloadPositionChangeByType:
      'Vị trí tải xuống cho các tệp đã tạo đã được thay đổi thành {type}',
    isDeleteFolder:
      'Bạn có chắc chắn muốn xóa vị trí lưu trữ tệp đã sử dụng không?',
    netWorkIsNotSafe:
      'Môi trường mạng của bạn dường như không an toàn, chúng tôi không thể kích hoạt tính năng này, bạn có thể phản hồi với chúng tôi',
    NotSupportBrowse:
      'Trình duyệt của bạn hiện không hỗ trợ tính năng này. Vui lòng tải xuống phiên bản mới nhất của trình duyệt <a href="https://www.microsoft.com/vi-vn/edge/download" target="_blank">Edge</a> để sử dụng.',
    notSupportDisk:
      'Không thể chọn các thư mục cấp hệ thống như [Desktop] và [Ổ đĩa C]',
    useFolderDownload: 'Tệp sản xuất được tải xuống dưới dạng giải nén',
    downloadPosition: 'Vị trí tải xuống tệp',
    changeUseFolder: 'Thay đổi',
    folderNameSetting: 'Thiết lập tên thư mục',
    mainFolder: 'Thư mục cấp 1',
    exportNameSetting: 'Tên xuất tệp chế biến theo số đơn hàng',
    fileMax: 'Tên tệp có tối đa',
    char: 'kí tự',
    overTip:
      'Nội dung vượt quá sẽ không hiển thị (bộ nén hình ảnh nhãn và tệp json sẽ theo tên thư mục cấp 1)',
    example: 'Ví dụ',
    engravingFolder: 'Thư mục máy khắc',
    electronicSawFolder: 'Thư mục máy cưa điện tử',
    labellingFolder: 'Thư mục máy ghi nhãn',
    sideHoleDrillFolder: 'Thư mục máy khoan lỗ bên',
    fiveDrillFolder: 'Thư mục máy khoan năm mặt',
    sixDrillFolder: 'Thư mục máy khoan sáu mặt',
    PTPFolder: 'Thư mục PTP',
    labellingTagFolder: 'Thư mục hình ảnh ghi nhãn',
    folderMergeSetting: 'Thiết lập hợp nhất thư mục',
    folderMerge: 'Hợp nhất thư mục',
    mergeName: 'Tên hợp nhất',
    subMergeFolder: 'Hợp nhất theo chất liệu, màu sắc, độ dày vào thư mục con:',
    subFolderName: 'Tên thư mục con',
    splitChar: 'Dấu phân cách',
    splitTip: '(Không hỗ trợ nhập kí tự như / : ? < > | . ” * ...)',
    folderAutoAdd: 'Tự động tăng số thư mục bắt đầu từ',
    resetTip: '(Đặt lại về số bắt đầu mỗi ngày lúc 00:00)',
    emptyErrTip: 'Thông tin không được để trống',
    repeatErrTip: 'Tên tệp đã trùng lặp',
    mergeDataTip:
      'Phải có thông tin về chất liệu, màu sắc, độ dày, chiều dài, chiều rộng của tấm hoặc tự động tăng số tệp',
  },
  // 更多设置页面
  moreSettings: {
    generalSetting: 'Thiết lập chung',
    outSyncTip: 'Tạo ra một gợi ý khi dữ liệu gốc không đồng bộ với tủ',
    secondarySwitchShow:
      'Khắc máy thiết lập chuyển mạch điều khiển nội dung thứ hai tắt chuyển tiếp sau khi hiển thị',
  },
  lineManager: {
    add: 'Thêm dây chuyền sản xuất',
    engraving: 'Thiết bị cắt gọt',
    holeSlotEquip: 'Thiết bị gia công lỗ và khe',
    workSetting: 'Thiết lập gia công',
    noUse: 'Chưa kích hoạt',
    warningTip:
      '* Chú ý: Sau khi thiết lập các chức năng sau, vui lòng không lưu tại trang kết nối thiết bị của khách hàng Khung Khung để tránh thiết lập lại thông số!',
    engravingSetting: 'Thiết lập máy cắt gọt',
    workWay: 'Cách thức gia công',
    cutedSpecial: 'Lỗ và khe đặc biệt được gia công:',
    noWork:
      'Tùy chọn này không có hiệu lực với mẫu mực mặc định của máy khoan năm/sáu mặt',
    onlyCut: 'Chỉ cắt gọt',
    onlyFrontHoleSlot: 'Chỉ gia công lỗ và khe mặt trước của tấm',
    onlyHoleSlot: 'Chỉ gia công lỗ và khe mặt trước và sau của tấm',
    sideHoleSetting: 'Thiết lập máy khoan lỗ bên',
    cutHoleSlot: 'Lỗ và khe được gia công',
    minSize: 'Kích thước cạnh ngắn nhỏ hơn',
    maxSize: 'Và kích thước cạnh dài nhỏ hơn',
    minSize2:
      'tất cả lỗ, khe và phần hình không phù hợp trên tấm có kích thước {minSize}mm',
    singleHole: 'Lỗ đơn',
    singleSlot: 'Khe đơn',
    diameterRange: 'Phạm vi điện tính lỗ',
    hDeepRange: 'Phạm vi độ sâu lỗ',
    widthRange: 'Phạm vi chiều rộng khe',
    sDeepRange: 'Phạm vi độ sâu khe',
    to: 'đến',
    specialHS: 'Lỗ, khe đặc biệt và hình không phù hợp',
    allowRoll: 'Cho phép máy cắt gọt đảo mặt gia công lỗ, khe đặc biệt',
    engravingPath: 'Gia công phần hình không phù hợp trên máy cắt gọt',
    drillSetting: 'Thiết lập máy khoan năm/sáu mặt',
    engravingCuted: 'Lỗ, khe đã gia công trên máy cắt gọt sẽ',
    noGen: 'Không tạo',
    genCut: 'Tạo và gia công',
    genNoCut: 'Tạo nhưng không gia công',
    cutTip:
      'Chú ý: Tùy chọn này chỉ có hiệu lực với tệp máy khoan năm/sáu mặt tùy chỉnh',
    maxShortSize: 'Kích thước cạnh ngắn tối đa',
    maxLongSize: 'Kích thước cạnh dài tối đa',
    normalSlot: 'Khe thông thường',
    specialSlot: 'Khe đặc biệt',
    combinSetting: 'Thiết lập thiết bị kết hợp',
    allowShortSize: 'Cho phép kích thước cạnh ngắn',
    equipment1: 'Thiết bị 1',
    equipment2: 'Thiết bị 2',
    noSameTip: 'Chú ý: Không thể chọn hai thiết bị giống nhau',
    workRange: 'Phạm vi gia công của thiết bị 1',
    rangeWarning:
      'Chú ý: Chỉ có thể thiết lập phạm vi gia công của thiết bị 1, phạm vi ngoài phạm vi của thiết bị 1 sẽ được gia công hoàn toàn trên thiết bị 2, vui lòng thao tác cẩn thận!',
    deleteWarning:
      'Xóa dây chuyền sản xuất này sẽ không thể khôi phục (tất cả thiết lập của dây chuyền sản xuất này sẽ bị xóa), bạn có chắc chắn muốn xóa dây chuyền sản xuất này?',
    top: 'Đầu',
    inputCheck: '{msg} Giá trị tối thiểu không được lớn hơn giá trị tối đa',
    lockTip: 'Dây chuyền sản xuất đã bị khóa, không được thao tác!',
    reNameTip: 'Dây chuyền sản xuất mặc định không thể sửa đổi tên',
    permissonTip:
      'Thông tin nhận dạng đã hết hạn, vui lòng mở lại trang này từ Khung Khung!',
    permissonErr:
      'Không có quyền truy cập, vui lòng nâng cấp phiên bản tài khoản!',
    newLine: 'Tạo dây chuyền sản xuất mới {idx}',
    deleteCheck: 'Không thể xóa tất cả',
    deving: 'Đang phát triển',
    combinTip:
      'Vui lòng nhấn vào thiết lập thiết bị kết hợp bên dưới để cài đặt',
    noUseEquip: 'Thiết bị gia công chưa được kích hoạt',
    equipmentSelectTip:
      'Khi thiết bị kết hợp chọn máy cắt gọt, thiết bị cắt gọt không thể chọn máy cưa điện tử',
    lineLoading: 'Dữ liệu đang tải',
    strenchType: 'Loại khe máy kéo thẳng',
    aioProcessTip:
      'Bạn đã bật thiết bị "Tất cả trong một", chế độ xử lý mặc định là xử lý các lỗ và rãnh trên cả hai mặt của tấm ván',
    aioProcessTip2:
      'Sau khi bật thiết bị Tất cả trong một, các lỗ và rãnh đặc biệt sẽ được xử lý trong phần máy khắc',
    aioProcessTip3:
      'Lưu ý: Chức năng này không tương thích với các máy khắc đã bật chức năng hoán đổi trục XY. Vui lòng sử dụng cẩn thận',
    specialHoleTip:
      'Lưu ý: Khi sử dụng chức năng "xử lý lỗ rãnh đặc biệt" trên, vui lòng đặt "phương pháp khoan lỗ thông" và "phương pháp xuyên rãnh" để khoan qua một lần',
    specialHoleTip2:
      'Lưu ý: Khi sử dụng chức năng "xử lý lỗ rãnh đặc biệt" trên, vui lòng đặt máy khắc "phương pháp khoan lỗ thông" và "phương pháp xuyên rãnh" để khoan qua một lần',
    fiveSixNotTake:
      'Tùy chọn này không áp dụng cho các mẫu khoan mặc định năm/sáu mặt',
    onlyFrontSide: 'Chỉ gia công mặt trước',
    onlyBackSide: 'Chỉ gia công mặt sau',
    bothFrontBack: 'Gia công cả mặt trước và mặt sau',
  },
  // 雕刻机-开料对接
  cuttingDock: {
    title: 'Đối tiếp Khai Liệu',
    other: 'Khác',
    autoStockCode: 'Mã tự động lên/xuống liệu',
    stationStart: 'Bán vị bắt đầu',
    stationStartCode1: 'Mã bắt đầu bán vị 1',
    stationStartCode1Placeholder: 'Nhập mã bắt đầu bán vị 1',
    stationStartCode2: 'Mã bắt đầu bán vị 2',
    stationStartCode2Placeholder: 'Nhập mã bắt đầu bán vị 2',
    stationEnd: 'Bán vị kết thúc',
    stationEndCode1: 'Mã kết thúc bán vị 1',
    stationEndCode1Placeholder: 'Nhập mã kết thúc bán vị 1',
    stationEndCode2: 'Mã kết thúc bán vị 2',
    stationEndCode2Placeholder: 'Nhập mã kết thúc bán vị 2',
    frontBackStart: 'Bán mặt bắt đầu',
    frontBackEnd: 'Bán mặt kết thúc',
    frontStartCode: 'Mã bắt đầu mặt trước',
    frontStartCodePlaceholder: 'Nhập mã bắt đầu mặt trước',
    frontEndCode: 'Mã kết thúc mặt trước',
    frontEndCodePlaceholder: 'Nhập mã kết thúc mặt trước',
    backStartCode: 'Mã bắt đầu mặt sau',
    backStartCodePlaceholder: 'Nhập mã bắt đầu mặt sau',
    backEndCode: 'Mã kết thúc mặt sau',
    backEndCodePlaceholder: 'Nhập mã kết thúc mặt sau',
    plankStart: 'Bàn khung lật mặt bắt đầu',
    plankEnd: 'Bàn khung lật mặt kết thúc',
    plankRollCode1: 'Mã lật mặt bán vị 1',
    plankRollCode1Placeholder: 'Nhập mã lật mặt bán vị 1',
    plankRollCode2: 'Mã lật mặt bán vị 2',
    plankRollCode2Placeholder: 'Nhập mã lật mặt bán vị 2',
    autoBaitingCode: 'Mã tự động xuống liệu',
    autoBaitingCode1: 'Mã xuống liệu bán vị 1',
    autoBaitingCode1Placeholder: 'Nhập mã xuống liệu bán vị 1',
    autoBaitingCode2: 'Mã xuống liệu bán vị 2',
    autoBaitingCode2Placeholder: 'Nhập mã xuống liệu bán vị 2',
    preStartEquipment: 'Thiết bị khởi động trước',
    firstKnifePreCode: 'Mã khởi động trước của dao đầu tiên',
    secondKnifePreCode: 'Mã khởi động trước của dao thứ hai và sau đó',
    rowInsert: 'Chèn hàng',
    pumpPause: 'Tạm dừng bơm chân không',
    pumpPauseTip:
      'Lưu ý: đơn vị thiết bị cần phải được điền vào vị trí 1 và vị trí 2 cùng một "tự động nạp mã" và "hai mặt xử lý tích cực chú thích mã"',
    pumpPause2Tip:
      'Lưu ý: thiết bị đơn vị cần phải điền vào vị trí 1 và vị trí 2 cùng một "mã ngừng bơm chân không"',
    pumpPauseCode1: 'Mã tạm dừng bơm chân không bán vị 1',
    pumpPauseCode1Placeholder:
      'Vui lòng điền mã tạm dừng bơm chân không bán vị 1',
    pumpPauseCode2: 'Mã tạm dừng bơm chân không bán vị 2',
    pumpPauseCode2Placeholder:
      'Vui lòng điền mã tạm dừng bơm chân không bán vị 2',
    annotationCode1: 'Mã chú thích mặt trước bán vị kép 1',
    annotationCode1Tip:
      'Thích hợp cho thiết bị có chỉ lệnh khác nhau khi tự động lên liệu mặt trước',
    annotationCode1Placeholder: 'Nhập mã chú thích mặt trước bán vị kép 1',
    annotationCode2: 'Mã chú thích mặt trước bán vị kép 2',
    annotationCode2Placeholder: 'Nhập mã chú thích mặt trước bán vị kép 2',
    upSpeedTip: 'Vui lòng điền vào tốc độ tăng cộng cạnh',
    cutThickTip: 'Vui lòng điền vào độ dày cắt cạnh chung',
    sizeErrTip:
      'Vui lòng đặt kích thước chính xác của bản in điều hướng trong máy dán nhãn!',
    offEdgeKnifeDiameter: 'Vui lòng nhập đường kính dao hàng cạnh',
    slowDownSpeedTip: 'Nhập tham số giảm tốc độ góc cắt không chính xác',
    permissionErr: 'Hiện tại là phiên bản miễn phí, không thể lưu trữ',
    labellingTempErr: 'Vui lòng chọn mẫu máy dán nhãn',
    autoRollInputTip: 'Bật tự động xoay nhãn, cần nhập giá trị hợp lệ',
    offlineTempFormat:
      'Định dạng tệp máy dán nhãn hiện tại đã ngừng hoạt động, vui lòng xác nhận',
    autoStockCode1: 'Mã tự động nạp liệu vị trí 1',
    autoStockCode1PlaceHolder: 'Nhập mã tự động nạp liệu vị trí 1',
    autoStockCode2PlaceHolder: 'Nhập mã tự động nạp liệu vị trí 2',
    autoStockCode2: 'Mã tự động nạp liệu vị trí 2',
    pauseCode: 'Mã tạm dừng',
    pauseCodePlaceHolder: 'Nhập mã tạm dừng',
    cuttingParams: {
      title: 'Các tham số cắt',
      holeSlotMainSide: 'Mặt tập trung của lỗ khoan và khe',
      holeMainSide: 'Mặt tập trung của lỗ khoan',
      slotMainSide: 'Mặt tập trung của khe',
      workPriority:
        '(Ưu tiên chức năng: mặt có lỗ khoan/khe sẽ đi theo tấm nhỏ chưa có lỗ khoan/khe > vị trí gia công của lỗ khoan của chân thẳng và vòi nối ở mặt sau của tấm lớn > mặt tập trung của khe > mặt tập trung của lỗ khoan)',
      hingeRollWork:
        'Đặt lỗ khoan của chân thẳng và vòi nối ở mặt sau của tấm lớn để gia công',
      followWork: 'Mặt có lỗ khoan/khe đi theo tấm nhỏ chưa có lỗ khoan/khe',
      autoMatchSide: 'Phân chia tỷ lệ mặt trước và sau của lỗ khoan/khe',
      backHSRate: 'Tỷ lệ lỗ khoan/khe ở mặt sau',
      matchTip:
        '（Cài đặt này sẽ không có hiệu lực đối với các bản in với bảng cao ánh hoặc tính toán các bản in hình dị hình khác nhau）',
      highGlossSetting: 'Cài đặt bảng cao ánh',
      heighGlossFront:
        'Trang trí tấm nhỏ có mặt cao ánh ở mặt trước của tấm lớn',
      sideSlotPut: 'Đặt khe của tấm nằm gần cạnh',
      optimize: 'Đang tối ưu hóa chức năng',
      sysDefault: 'Mặc định của hệ thống',
      plankInSide: 'Bên trong của tấm lớn',
      plankOutSide: 'Bên ngoài của tấm lớn',
      specialNotWork:
        'Chức năng này không có hiệu lực đối với các tấm hình dị hình',
      commonSide: 'Cắt theo cạnh chung',
      plankThickRange: 'Độ dày của tấm nhỏ không quá',
      useCommonSide: 'Sử dụng cắt theo cạnh chung khi',
      zeroTip:
        '（Khoảng cách giữa các tấm cần được thay đổi thủ công thành 0, khi gia công đến cạnh đã cắt thì máy sẽ ngang dao để bỏ qua cạnh đó）',
      useTip:
        'Khi chức năng này được bật, sẽ không hỗ trợ gia công nghiêng dao, vui lòng lưu ý!',
      commonSpeed: 'Tốc độ tăng chung',
      highSpeed: 'Tốc độ cát cao',
      min: 'phút',
      notWorkTip:
        '（Khi gia công tấm đáp ứng cắt theo cạnh chung, tốc độ tăng chung sẽ không có hiệu lực）',
      changeStartPoint: 'Chuyển điểm bắt đầu hai vị trí',
      changeStartPointTip:
        'Sau khi kích hoạt chuyển điểm bắt đầu hai vị trí, chức năng này sẽ không tương thích với chức năng hoán đổi trục XY, hãy sử dụng cẩn thận',
      flatOffset: 'Biến mất mặt phẳng của máy',
      xRowChange: 'Hoán đổi cơ sở tọa độ trục X',
      bigplankPointChange: 'Hoán đổi tọa độ gốc ở mặt sau của bảng lớn',
      reverseXRowChange: 'Hoán đổi tọa độ X',
      changebigplankPointTips:
        'Sau khi kích hoạt tính năng này, nó không tương thích với các chức năng "hoán đổi trục XY" và "chuyển đổi điểm bắt đầu hai vị trí". Hãy sử dụng cẩn thận.',
      reverseYRowChange: 'Hoán đổi tọa độ Y',
      plankMirrorTip:
        'Sử dụng chức năng này khi hình ảnh của tấm được gia công trên máy cắt phải được phản hành',
      notSameSize: 'Hai vị trí có kích thước khác nhau',
      maxSize1: 'Kích thước tối đa của vị trí 1',
      notSameSizeTip:
        '（Sau khi kích hoạt hai vị trí có kích thước khác nhau, chức năng này sẽ không tương thích với chức năng hợp nhất hai vị trí, hãy sử dụng cẩn thận）',
      maxSize2: 'Kích thước tối đa của vị trí 2',
      autoSelectTip:
        '（Khi hai bàn máy có kích thước khác nhau, sử dụng chức năng này sẽ tự động chọn vị trí để gia công tấm lớn)',
      bigFlatLimit:
        'Vị trí lớn chỉ gia công tấm lớn mà vị trí nhỏ không thể gia công:',
      surplusSetting: 'Cài đặt dư thừa',
      surplusPosition: 'Vị trí đặt dư thừa',
      cutHoleWay: 'Cách cắt đục lỗ',
      knifeFlat: 'Điểm chuẩn mài dao',

      flat: 'Bàn máy',
      plankFlat: 'Bề mặt tấm lớn',
      freeHeightZ: 'Chiều cao Z khi không hoạt động',
      relativeHeight: 'Chiều cao tương đối với bề mặt trên của tấm lớn',
      cutDeepZ: 'Chiều sâu cắt Z',
      cutDeepH: 'Cách đục lỗ thông',
      onceThrough: 'Đục lỗ một lần',
      rollThrough: 'Đục lỗ hai lần bằng cách lật mặt',
      cutDeepS: 'Cách đục khe thông',
      plankCutDirection: 'Hướng cắt tấm nhỏ',
      sDirection: 'Đoàn tròn',
      nDirection: 'Đoàn trục',
      curveReplenish: 'Cách cắt bằng chỉ dẫn hoàn cung cho tấm hình cung',
      curveReplenishTip:
        'Khi cắt tấm hình cung xuất hiện răng saw có thể sử dụng chức năng này',
      noGen: 'Không tạo lệnh mặt G17/G18/G19',
      curveCutTip: 'Cắt hoàn cung bằng I, J',
      coverRate: 'Tỷ lệ chồng chéo khi cắt khe:',
      roughTip:
        'Sử dụng chức năng này khi dao cắt khe còn có chích cọc bên trong khe',
      clearCurve: 'Khi cắt khe chưa thông sẽ tự động làm sạch góc tròn:',
      curveUseTip:
        'Khi cần làm sạch góc tròn khi cắt khe, sử dụng chức năng này, chú ý chức năng này không áp dụng cho các mối nối',
      slotCutWay: 'Cách di chuyển dao khi cắt khe',
      outToIn: 'Cắt khe từ ngoài vào trong',
      inToOut: 'Cắt khe từ trong đến ngoài',
      slotWorkLimit: '(Chỉ có hiệu lực khi lớp cắt khe > 1)',
      workSpeedOp: 'Tối ưu hóa tốc độ gia công',
      obliqueCut: 'Cắt bằng dao nghiêng',
      backOblique: 'Điều chỉnh cạnh bằng dao nghiêng ở mặt sau',
      obliqueSpeed: 'Tốc độ cắt bằng dao nghiêng',
      arcSpeed: 'Tốc độ gia công đường cong',
      cuttingPreDistance: 'Khoảng cách cắt trước',
      backKnife: 'Tốc độ giảm tốc khi thu dao',
      openKnifeSpeed: 'Tốc độ thu dao',
      cutCornerSlow: 'Tốc độ giảm tốc khi cắt góc',
      addRow: 'Thêm mới',
      bettwenDistance: 'Khoảng cách trước và sau góc',
      cuttingSpeed: 'Tốc độ cắt',
      stepCutting: 'Cắt tầng',
      splitSlot: 'Cắt khe hai lần',
      splitPlank: 'Cắt tấm nhỏ hai lần',
      maxWH: 'Chiều dài hoặc chiều rộng nhỏ hơn',
      areaLimit: 'Và diện tích nhỏ hơn',
      secondDeep: 'Chiều sâu cắt lần thứ hai',
      splitPlankThick: 'Định cỡ cài đặt cắt theo lớp',
      saveLayeredCuttingTip: 'Tổng của ba lớp cắt phải bằng với độ dày của tấm',
      saveLayeredRepeatTip:
        'Không nên đặt dữ liệu phân lớp khác nhau cho độ dày của thuộc tính đánh dấu của cùng một vật liệu, vui lòng xóa dữ liệu thừa!',
      splitPlankThick: 'Cắt tầng khác nhau theo độ dày tấm',
      movePlankSetting: 'Cài đặt bảng chống trượt',
      newCutSequence: 'Thuật toán cắt mới',
      newCutSequenceText:
        'Theo mặc định, trung tâm của tấm lớn có lực hút chân không mạnh nhất, và các tấm nhỏ ở khu vực này sẽ được cắt cuối cùng',
      totalThickLimit: 'Tổng độ dày của ba tầng cắt phải bằng với độ dày tấm',
      offsetFollowStep: 'Điều chỉnh cạnh cũng theo cắt tầng',

      longPlankTip1:
        "1. Sau khi kích hoạt chức năng này, phải thiết lập mã tạm dừng chân không bơm trong 'Đối kết cắt gọt - Tạm dừng chân không bơm', nếu không sẽ không thể giải quyết vấn đề về cắt gọt vênh mạch của tấm dài)",
      longPlankTip2:
        '2. Sau khi bật hoặc tắt chức năng này, cần lưu lại lịch sử bố trí và cần tái bố trí khi tải xuống NC',
      secondCutting: 'Đối tượng cắt gọt lần hai',
      normalPlank: 'tấm thông thường',
      normalPlankTip:
        'Tấm thông thường: các tấm ngăn ngang, dọc trong tủ, các tấm không phải là cửa tủ, và các tấm được thêm trực tiếp vào danh sách cắt gọt',
      plankLongLimit: 'Khi chiều dài của tấm dài vượt quá',
      plankShortLimit: 'chiều ngắn vượt quá',
      cuttingSplit: 'Cắt gọt hai lần',
      longExpand: 'Điều chỉnh chiều dài lần đầu tiên:',
      firstExpand: 'Lần cắt đầu tiên thêm kích thước',
      longExpandTip: 'Thêm kích thước cạnh dài',
      longLimit: 'Cạnh dài vượt quá',
      cuttingSplitTip: 'Điều kiện cắt bảng dài thành hai lần',
      shortExpand: 'Điều chỉnh chiều ngắn',
      expandTip:
        '（Chiều dài/ngắn tăng thêm x mm, các tấm dài có hình dạng khác đều tăng chiều dài)',
      pumpOffWay: 'Cách tắt chân không',
      afterOnePlank: 'Sau khi cắt gọt một tấm dài',
      afterAllPlank: 'Sau khi cắt gọt toàn bộ tấm lớn',
      numberFixed: 'Số chữ số giữ nguyên',
      bufferCode: 'Chỉ dẫn làm mịn bề mặt tấm trước khi cắt gọt',
      layoutGap: 'Cài đặt khoảng cách bố trí',
      layoutGapTip:
        'Lưu ý: Cài đặt khoảng cách bố trí là 0 có thể gây chồng chéo bố trí',
      wasteCutting: 'Tự động cắt gọt phế liệu',
      zkPointCustomize:
        'Tùy chỉnh điểm tham chiếu xoay gia công lỗ xiên khóa Z',
      sawSetting: 'Cài đặt cưa cắt',
      cuttingLimit: 'Kích thước cắt gọt lớn hơn',
      waste: 'phế liệu',
      wasteSizeLimit: 'Kích thước nhỏ nhất không được nhỏ hơn 100mm',
      topLeft: 'Bên trái, bên trên',
      topLeftD: 'Góc trên trái',
      bottomLeft: 'Bên trái, bên dưới',
      bottomLeftD: 'Góc dưới trái',
      topRight: 'Bên phải, bên trên',
      topRightD: 'Góc trên phải',
      bottomRight: 'Bên phải, bên dưới',
      bottomRightD: 'Góc dưới phải',
      firstTime: 'Lần đầu tiên',
      secondTime: 'Lần thứ hai',
      thirdTime: 'Lần thứ ba',
      startCutting: 'Bắt đầu cắt gọt',
      thickRange: 'Độ dày lớn hơn hoặc bằng',
      longCut: 'Gia công tấm dài',
      edgeSetting: 'Cài đặt điều chỉnh cạnh',
      cuttingOrder: 'Thứ tự cắt gọt:',
      cuttingOrderTip:
        'Cách này có nguy cơ gây ra sự cố, vui lòng sử dụng thận trọng',
      plankFirst: 'Cắt tấm nhỏ trước',
      wasteFirst: 'Cắt phế liệu trước',
      offTip: 'Khi tắt, sẽ theo cài đặt điều chỉnh cạnh trong thông số cắt gọt',
      edgeCutDirection: 'Hướng điều chỉnh cạnh',
      onlyLong: 'Chỉ điều chỉnh chiều dài',
      backCutOrder: 'Thứ tự điều chỉnh cạnh mặt sau',
      backFirst: 'Điều chỉnh cạnh mặt sau trước',
      backSecond: 'Điều chỉnh cạnh sau khi gia công lỗ, khe',
      frontReCut: 'Điều chỉnh cạnh mặt trước lại',
      frontReCutTip: '（Làm mịn hơn缸định vị, ngăn ngừa dấu tích)',
      useNewCut: 'Kích hoạt quy tắc điều chỉnh cạnh mới',
      newCutTip:
        'Sử dụng chức năng này khi hai mặt của tấm lớn sẽ được sửa chữa khi một cạnh kích thước',
      fixedTip:
        'Lưu ý: Giữ số chữ số thập phân ở mức 0 hoặc 1 có thể gây chồng chéo bố cục',
      cuttingSlow: 'Bề mặt tấm cắt chậm',
      holeSlow: 'Bề mặt tấm đục lỗ chậm',
      slotSlow: 'Bề mặt tấm rãnh/ phay lỗ/ phay rãnh chậm',
      slowCode: 'Sử dụng mã đệm',
      code: 'Mã lệnh',
      zkRotatePoint: 'Điểm gốc xoay',
      zkRotateDir: 'Hướng tăng góc xoay',
      restSizeLimit: 'Kích thước còn lại nhỏ hơn',
      useSaw: 'Sử dụng lưỡi cưa',
      unGenSawPath: 'Không tạo đường cắt lưỡi cưa dọc',
      suitMachine: 'Phù hợp cho gia công tấm hàn nhôm toàn bộ',
      plankBack: 'Mặt sau của tấm ván lớn',
      plankLeft: 'Mặt trái của tấm ván lớn',
      plankRight: 'Mặt phải của tấm ván lớn',
      plankFront: 'Mặt trước của tấm ván lớn',
      frontSide: 'Mặt trước',
      backSide: 'Mặt sau',
      rightSide: 'Mặt phải',
      leftSide: 'Mặt trái',
      singleMachineLimit:
        'Tạo đường cắt biên trên mặt trước của bảng gia công đơn và cắt biên trước',
      singleMachineLimitTip:
        'Chức năng này không áp dụng cho máy tích hợp và cắt bằng lưỡi cưa',
      setBatchPositionWaring:
        'Gợi ý: Nếu bạn cần hủy chế độ chỉnh sửa nhanh điểm cắt, vui lòng bỏ chọn nút "Chỉnh sửa điểm cắt" ở phía bên phải',
      setBatchPosition: 'Chỉnh sửa hàng loạt các điểm cắt',
      setBatchPositionAs: 'Chỉnh sửa hướng cắt thành:',
      activeBatchPosition: 'Đã chọn {activePartCount} tấm ván nhỏ',
    },
    labelingSettings: {
      title: 'Cài đặt ghi nhãn',
      output: 'Xuất tệp ghi nhãn tự động',
      specification: 'Định dạng tệp ghi nhãn',
      codeFrontGenerate: 'Tự động tạo mã nạp liệu trước mã ghi nhãn',
      templateOffline: 'Bản mẫu đang sử dụng đã ngừng hoạt động!',
      rollLabelling: 'Cài đặt ghi nhãn mặt sau',
      flipSetting: {
        title: 'Cài đặt mặt sau',
        engravingFlipProcess: 'Bật chế độ ghi nhãn mặt sau cho máy khắc',
        flipTip: 'Bật chức năng này khi máy khắc cần gia công mặt sau',
      },
      rotateSetting: {
        title: 'Cài đặt xoay nhãn',
        rotateTip:
          '(Bật điều kiện xoay theo nhu cầu, nếu đồng thời bật, thì giá trị đã chọn sẽ có hiệu lực ưu tiên)',
        horizontal: 'Bên ngang của ván ≤',
        vertical: 'Bên dọc của ván ≤',
        either: 'Bên ngang | Bên dọc của ván ≤',
      },
      countertopSetting: {
        title: 'Cài đặt bàn làm việc',
        startPoint: 'Điểm khởi đầu vị trí:',
        upLeft: 'Trên góc trái',
        upRight: 'Trên góc phải',
        downLeft: 'Dưới góc trái',
        downRight: 'Dưới góc phải',
        baseExchange: 'Điểm chuẩn trao đổi tọa độ:',
        exchangeX: 'Trao đổi trục x',
        exchangeY: 'Trao đổi trục y',
      },
      offset: 'Điểm lệch tiêu đề nhãn',
      offsetTip:
        'Thông thường điểm nhãn nằm ở trung tâm của tấm nhỏ, một số thiết bị cần điều chỉnh',
      imgSetting: {
        title: 'Cài đặt hình ảnh nhãn',
        suffix: 'Hậu tố hình ảnh nhãn:',
        code: 'Định dạng mã hóa hình ảnh:',
        codeTip:
          'Chú ý: png, jpeg sẽ đồng bộ sửa đổi định dạng mã hóa của bố cục hình ảnh, và tính năng này chưa được hỗ trợ trong sắp xếp đám mây nhúng',
        imgRatio: 'Độ phân giải hình ảnh nhãn:',
        slowDown: 'Sẽ làm giảm hiệu suất xuất hình ảnh nhãn',
        color: 'Màu sắc thật',
        bit: 'Đen trắng',
        resolution: 'Độ phân giải hình ảnh nhãn',
        resolutionPlaceholder: 'Độ phân giải',
        labelPool: 'Hồ bơi nhãn dữ liệu',
        dragTip: 'Có thể kéo thả nhãn trong hồ bơi nhãn',
        labelImgName: 'Tên hình ảnh nhãn',
        labelImgNameErrTip: 'Tên hình ảnh nhãn không được để trống',
        labelTip1:
          "Chú ý: 1. Khi tùy chỉnh, phải sử dụng 'mã vạch tấm' hoặc 'số thứ tự nhãn' làm một trong hai, nếu không sẽ có vấn đề về chồng lên nhau của hình ảnh;",
        labelTip2:
          '2. Mẫu hệ thống máy nhãn không có hiệu lực cho chức năng này',
        delimiter: 'Dấu phân cách:',
        inputTip: 'Không hỗ trợ nhập các ký tự như / : ? < > | . ” *',
        numStart: 'Giá trị bắt đầu số thứ tự nhãn',
        example: 'Tên hình ảnh nhãn tùy chỉnh (ví dụ)',
        divideFolders:
          'Hình ảnh nhãn được phân chia thành các thư mục khác nhau theo tấm lớn',
        foldersTip1:
          "Chú ý: 1. Chức năng này không hỗ trợ sử dụng cùng với chức năng 'hợp nhất tệp máy cắt gỗ';",
        foldersTip2:
          '2. Chức năng này chưa có hiệu lực đối với sắp xếp đám mây nhúng',
        stickIntoFolders:
          'Tệp nhãn được chèn vào thư mục hình ảnh nhãn tương ứng với tấm lớn:',
        stickTip1:
          "Chú ý: 1. Chức năng này cần được sử dụng cùng với chức năng 'tải xuống NC đồng thời xuất nhãn';",
        stickTip2:
          "2. Quy tắc tạo tệp trong mẫu nhãn cần chọn 'tạo tệp riêng' để chức năng này có hiệu lực;",
        stickTip3:
          '3. Chức năng này chưa có hiệu lực đối với sắp xếp đám mây nhúng',
      },
      paibanImgSetting: {
        title: 'Cài đặt sắp xếp hình ảnh',
        reparation: 'Tự động bù số thứ tự sắp xếp hình ảnh đến',
        figure: 'chữ vị',
        inputTip: 'Vui lòng nhập số từ 1-99',
        paibanImg: {
          export: 'Xuất sắp xếp hình ảnh',
          prefix: 'Tiền tố tên sắp xếp hình ảnh',
          size: 'Kích thước sắp xếp hình ảnh',
          format: 'Định dạng sắp xếp hình ảnh',
          directional: 'Hướng sắp xếp',
          vertical: 'Dọc',
          horizontal: 'Ngang',
        },
      },
      autolabel: 'Cài đặt thứ tự dán nhãn tự động',
      customTemp: 'Chỉ áp dụng cho mẫu tùy chỉnh',
      cuttingLabelOder: 'Dán nhãn theo thứ tự cắt',
      labelToUp: 'Dán nhãn từ dưới lên theo kết quả bố trí',
      labelToDown: 'Dán nhãn từ trên xuống theo kết quả bố trí',
      labelDodge: 'Dán nhãn tự động tránh',
      settingValue: 'Chọn giá trị thiết lập',
      enabelTip:
        'Khi bật, nếu bảng nhỏ đáp ứng các giá trị thiết lập dưới đây, nhãn sẽ xoay',
      fixedHori:
        'Chọn cạnh ngang của nhãn song song với cạnh cố định của bảng nhỏ',
      fixedHoriTip:
        'Chỉ áp dụng cho bảng nhỏ không có kết cấu, khi bật, nhãn sẽ xoay sao cho cạnh ngang của nhãn song song với cạnh cố định của bảng nhỏ',
      tagHori: 'Cạnh ngang của nhãn',
    },
    drillSettings: {
      title: 'Cài đặt khoan hàng',
      enable: 'Kích hoạt khoan hàng',
      toolTable: {
        use: 'Kích hoạt',
        number: 'Số khoan hàng',
        number2: 'Station 2 Drill Number',
        diameter: 'Điện tích khoan',
        xOffset: 'Bị lệch X',
        yOffset: 'Bị lệch Y',
        moveZF: 'Tốc độ di chuyển nhanh trục Z',
        feedRate: 'Tốc độ gia công trục Z',
        moveF: 'Tốc độ di chuyển nhanh trục XY',
        benchmarkDrill: 'Khoan chuẩn',
        addKnife: 'Thêm dao',
        deleteKnife: 'Xóa dao',
      },
      prefix: 'Tiền tố khoan hàng',
      suffix: 'Hậu tố khoan hàng',
      startCode: 'Mã khởi động khoan hàng',
      endCode: 'Mã kết thúc khoan hàng',
      combineKnife: 'Khoan hàng kết hợp',
      combineTable: {
        prefix: 'Tiền tố số khoan hàng',
        suffix: 'Hậu tố số khoan hàng',
        mainHole: 'Lỗ chính',
        extraHole: 'Lỗ phụ',
        notes: 'Ghi chú kết hợp',
        firstUseNotes: 'Ghi chú kích hoạt lần đầu',
        addExtraHole: 'Thêm lỗ phụ',
        deleteExtraHole: 'Xóa lỗ phụ',
        example: 'Ví dụ',
      },
      processSetting: {
        title: 'Cài đặt gia công',
        mode: 'Chế độ gia công khoan hàng',
        processTip:
          'Sau khi chọn phương thức này, chức năng khoan hàng kết hợp sẽ không có hiệu lực',
        recentHole: 'Khoan gần (thay dao nhanh)',
        groupHole: 'Khoan theo loại (thay dao sau khi hoàn thành một loại lỗ)',
      },
      changeBenchDrillTip:
        'Vui lòng nhập số khoan hàng trước khi thực hiện lựa chọn này',
    },
    NCExportSettings: {
      title: 'Cài đặt Xuất NC',
      tab1: 'Cài đặt Tên Tệp Máy Khoan',
      tab2: 'Hợp Nhất Tên Tệp Máy Khoan',
      tab3: 'Ghi chú Trong Tệp Máy Khoan',
      tab4: 'Loại Tệp Máy Khoan',
      tab5: 'Thư mục Máy Khoan',
      tab6: 'Cài đặt Chỉ Dẫn Gia Công',
      tab7: 'Cài đặt Định Dạng Tệp',
      fileNameSettings: {
        twoStationAutoSort: 'Tự động Sắp Xếp Đôi Công Vị',
        reparation: 'Bù Số Thứ Tự Đa Tầng Đến',
        figure: 'chữ vị',
        labelerBegin: 'Đầu Máy Dán Nhãn',
        machineCenter: 'Đầu Trung Tâm Gia Công',
        front: 'Mặt Trước:',
        back: 'Mặt Sau:',
        customizeName: 'Tên Tệp Tùy Chỉnh (Định Dạng):',
        example: 'Tên Tệp Tùy Chỉnh (Ví Dụ):',
        emptyTip: 'Điều Khiển Mặt Trước/Sau Không Được Để Trống Đồng Thời',
        labelPool: 'Hồ Bơi Dánh Dấu Dữ Liệu',
        dragTip:
          'Có Thể Kéo hoặc Nhấn để Sao Chép Dánh Dấu Từ Hồ Bơi Dánh Dấu Đến Trong Hộp Nhập',
      },
      fileMerge: {
        engravingMerge: 'Hợp Nhất Tệp Máy Khoan',
        zfMerge: 'Hợp Nhất Mặt Trước/Sau',
        singleMerge: 'Hợp Nhất Đơn Công Vị',
        doubleMerge: 'Hợp Nhất Đôi Công Vị',
        fEndCode: 'Mã Kết Thúc Mặt Sau',
        zBeginCode: 'Mã Bắt Đầu Mặt Trước',
        displayfbInfo: 'Tên Tệp Hiển Thị Thông Tin Mặt Trước/Sau',
        fBeginCode: 'Mã Bắt Đầu Mặt Sau',
        zEndCode: 'Mã Kết Thúc Mặt Trước',
      },
      marginalNotes: {
        addPlankInfo: 'Thêm Thông Tin Đa Tầng Hiện Tại Đến Đầu Tệp Khoan:',
        plankInfo: 'Thông Tin Đa Tầng:',
        addStationCodeFirst: 'Thêm Mã Công Vị Đến Đầu Tệp Khoan:',
        addStationCodeSecond:
          'Thêm Mã Công Vị Đến Trước Thay Dao Trong Tệp Khoan:',
      },
      fileType: {
        zAddtion: 'Bổ Sung Tọa Độ Z:',
        addSemicolon: 'Thêm Dấu Chấm Phẩy Đến Cuối Mỗi Hàng NC:',
        semicolonTip: 'Chọn này sẽ có hiệu lực cho Tệp Gia Công Tay Cọc',
        fileName: 'Tên Tệp',
        defaultName: 'Tên Tệp Mặc Định',
        dedicatedName: 'Tên Tệp Đặc Biệt Thiết Bị Xinghui',
        xhNotes: 'Ghi chú Xinghui E2-9',
        format: 'Định Dạng Tệp Máy Khoan Xuất',
        example: 'Ví Dụ Tệp: Tệp NC',
        stillGenerate:
          'Tạo Tệp NC Mặt Sau Chỉ Chứa Mã Kết Thúc Mặt Sau Khi Mặt Sau Không Gia Công',
        generateTip:
          'Chức Năng Này Không Hỗ Trợ Cùng Sử Dụng Với Chức Năng Hợp Nhất Tệp',
        fileNameErrTip: 'Tên Tệp Không Được Chứa Những Ký Tự Sau: / : * ? "',
      },
      categorize:
        'Phân Loại Theo Màu Đa Tầng, Độ Dày, Chất Liệu Đến Khác Nhau Thư Mục (Tệp Dán Nhãn Cùng Múi):',
      simplifyG: 'Viết Tắt Chỉ Dẫn Gia Công G',
      ZToW: 'Đổi Mã Từ Trục Z Sang Trục W',
      knives: 'Dao Khoan Đa Bàn',
      lineBreakSetting: 'Cài Đặt Định Dạng Dòng Mới',
      codeFormatSetting: 'Cài Đặt Định Dạng Mã Hóa Tệp',
      utf8bom: 'UTF-8 Đeo BOM',
      oneCodeSetting: 'Cài Đặt Mã Vạch Bàn',
      simplifyOneCode: 'Tạo Mã Vạch Bàn Đơn Giản',
      codeLocation: 'Cài Đặt Vị Trí Mã',
      generateLocation: 'Tạo Mã Kết Thúc Công Vị Trước Mã Kết Thúc Mặt Sau',
      emergencySetting: 'Cài Đặt Khẩn Cấp',
      show: 'Hiển Thị Cài Đặt Khẩn Cấp',
      emergencyCode: 'Mã Khẩn Cấp',
      params: 'Tham Số',
      emergencyTip:
        'Có Thể Thực Hiện Một Số Chức Năng Khác Nhau Tạm Thời Với Hỗ Trợ Từ Nhân Viên Hậu Mãi',
      holeSetting: 'Cài Đặt Tệp Khoan Động',
      holeFileCategorize:
        'Tệp Khoan Động Được Phân Loại Đến Khác Nhau Thư Mục Theo Đơn Hàng',
      holeTip:
        'Không Có Hiệu Lực Đối Với Bố Cục Đám Mây Khách Hàng Nội Tính Tủ',
      specialCodeErr: 'Không Hỗ Trợ Nhập Ký Tự Đặc Biệt!',
      copySuccess: 'Nhân Thành Công!',
      copyErr: 'Nhân Thất Bại, Vui Lòng Thử Kéo Nhãn!',
      lengthLimit: 'Chỉ Hỗ Trợ Nhập 15 Chữ Vị!',
      plankInfo: 'Thông Tin Bàn',
      otherInfo: 'Thông Tin Khác',
      uniqueBarCode: 'Mã Vạch Bàn Duy Nhất',
      machineOrder: 'Tên tệp cắt tạo số thứ tự gia công',
      folderSetting:
        'Tệp cắt của bảng lớn cùng kích thước đặt trong cùng một thư mục',
      folderSettingTip: 'Lưu ý: Bố trí tích hợp chưa hỗ trợ chức năng này',
      settingMoveTip: 'Chức năng "{func}" đã được chuyển đến trang "{module}"!',
      fileManagerFunction:
        'Tệp khoan được đặt riêng theo từng đơn hàng trong các thư mục khác nhau',
      gotoSetting: 'Đi đến cài đặt',
    },
    aioMachineSetting: {
      openFunction: 'Cần bật các chức năng sau để kết nối',
      openCarving: 'Bật máy chạm khắc',
      xyReverseFunction: 'Chức năng hoán đổi trục XY',
      carvingStartSet: 'Đặt vị trí bắt đầu của máy chạm khắc thành',
      rightTop: 'Góc trên bên phải',
      carvingEdgeSet:
        'Điều chỉnh giá trị cắt cạnh trước của máy chạm khắc thành',
      finalEdgeSame: 'Bằng với giá trị cắt cuối cùng',
      beginCode: 'Mã bắt đầu chương trình',
      endCode: 'Mã kết thúc chương trình',
      drillHole: 'Khoan lỗ',
      pullTrough: 'Kéo rãnh',
      showDataTemp: 'Hiển thị mẫu dữ liệu',
      dataTemp: 'Mẫu dữ liệu',

      title: 'Cài Đặt Máy Một Thân',
      outputFile: 'Xuất Tệp Gia Công Máy Một Thân',
      outputFileTips:
        'Một số thương hiệu máy một thân không tương thích với hoán đổi XY, hợp nhất tệp, không khoan hình dạng khác, vui lòng sử dụng thận trọng',
      fileType: 'Định Dạng Tệp',
      select: 'Vui lòng chọn',
      diameter: 'Đường Kính Dao Chi Trimming',
      jidongTip:
        'Vui lòng đặt điểm khởi tạo của máy khắc của Máy Một Thân Jidong ở góc trên phải, và bật chức năng A',
      skill: 'Chức Năng',
    },
    oneDragTwo: {
      title: 'Máy Khắc Một Kéo Hai',
      outputMachineFile: 'Xuất Tệp Gia Công Máy Khắc Một Kéo Hai',
      outputMachineFileTips:
        'Bật Máy Khắc Một Kéo Hai sau đó không tương thích với chức năng hợp nhất tệp với máy cắt, vui lòng sử dụng thận trọng',
      fileType: 'Định Dạng Tệp',
      select: 'Vui lòng chọn',
      machineStart: 'Điểm Khởi Tạo Công Vị',
      positionClose:
        'Khi đóng sẽ đi theo thiết đặt điểm khởi tạo công vị trong thông số cắt',
      engravingPlus: 'Máy Khắc Tốc Độ',
      a: '',
    },
    firstSave:
      'Detected that this is the first time the user uses cloud layout. After saving the settings, please check if the trimming direction in the file is reasonable to avoid unnecessary losses.',
    // 雕刻机-开料对接-刀库
    knives: {
      xOffset1: 'X Coordinate Offset',
      yOffset1: 'Y Coordinate Offset',
      zOffset1: 'Z Coordinate Offset',
      insert: 'Chèn Hàng',
      delete: 'Xóa Hàng',
      tip: '（Chú ý: Khi điền tên dao, phải giống với số biên hiệu dao）',
      number: 'Biên Hiệu',
      name: 'Tên',
      diameter: 'Đường Kính DaoΦ',
      cutting: 'Cắt Nguyên Liệu',
      use: 'Mục Đích Sử Dụng',
      moveZF: 'Tốc Độ Di chuyển Trục Z',
      cutZF: 'Tốc Độ Gia Công Trục Z',
      moveF: 'Tốc Độ Di chuyển Trục XY',
      cutF: 'Tốc Độ Gia Công Trục XY',
      startCode: 'Mã Bắt Đầu Thay Dao',
      stopCode: 'Mã Kết Thúc Thay Dao',
      startCode2: '工位2换刀开始代码',
      stopCode2: '工位2换刀结束代码',
      preStartCode: 'Mã Khởi Động Trước',
      punchHoles: 'Khoan Động',
      pullSlots: 'Kéo Khay',
      millHoles: 'Máy Xí Khoan Động',
      millSlots: 'Máy Xí Khay',
      sawBlade: 'Dao Cưa',
      doubleSaw: 'Bật mã bắt đầu thay dao khác nhau cho hai trạm làm việc',
      singleStation: 'Chỗ làm việc đơn',
      doubleStation: 'Bàn làm việc đôi',
      sawBladeTip:
        'Chức năng này không có hiệu lực với Máy Khắc Một Kéo Hai và Máy Một Thân',
      serialNum: 'Biên Hiệu',
      sawWidth: 'Chiều Rộng Dao Cưa',
      sawType: 'Loại Dao Cưa',
      cutDirection: 'Hướng Cắt',
      moveSpeed: 'Tốc Độ Di chuyển Không Gia Công',
      cutSpeed: 'Tốc Độ Gia Công',
      startCode: 'Mã Bắt Đầu Thay Dao', // 注意：这里重复了，可能需要检查源数据
      endCode: 'Mã Kết Thúc Thay Dao',
      sawDownCode: 'Mã Dao Xuống',
      sawUpCode: 'Mã Dao Lên',
      abscissa: 'Tiền tố Tọa Độ Ngang',
      ordinate: 'Tiền tố Tọa Độ Dọc',
      sawHor: 'Dao Cưa Ngang',
      sawVer: 'Dao Cưa Dọc',
      towardsZero: 'Cắt Hướng Điểm 0',
      awayFromZero: 'Cắt Từ Điểm 0',
      diameterDesc: 'Đường Kính Dao Φ',
      xOffset: 'Biến Chuyển Tọa Độ Ngang',
      yOffset: 'Biến Chuyển Tọa Độ Dọc',
      zOffset: 'Biến Chuyển Tọa Độ Thăng Hạ',
      offsetX: 'Biến Chuyển X',
      offsetY: 'Biến Chuyển Y',
      offsetZ: 'Biến Chuyển Z',
      offsetNo: 'Biến Chuyển Mã',
      knifeWidth: 'Chiều Rộng Dao',
      drill: 'Dao Xuống',
      cut: 'Gia Công',
      speed: 'Tốc Độ',
      type: 'Loại',
      millHoleSlot: 'Máy Xí Khoan Động/Khay',
      rapidFeedSpeed: 'Tốc Độ Tiến Khoáng Nhanh',
    },
    other: {
      title: 'Khác',
      tip: '（Chú ý: Thiết bị một vị trí cần điền mã tự động tải liệu cho vị trí 1 và vị trí 2 đều giống nhau）',
    },
  },
  // 侧孔机
  sideHoleMachine: {
    coordSwitch: 'Chuyển Đổi Tham Chiếu Tọa Độ',
    updownCoordSwitch: 'Chuyển Đổi Tham Chiếu Tọa Độ Nâng',
    knifes: {
      number: 'Số hiệu',
      diameter: 'Chiều rộng dao',
      type: 'Loại',
      moveSpeed: 'Tốc độ tiến nhanh',
      doSpeed: 'Tốc độ gia công',
      startCode: 'Mã bắt đầu đổi dao',
      endCode: 'Mã kết thúc đổi dao',
    },
    ymSetting: 'Cài đặt kết nối Yimu',
    flanking: 'Công tác cạnh',
    frontProcess: 'Công tác mặt trước',
    oldSetting: 'Cài đặt cũ',
    equipParamsSetting: 'Cài đặt thông số thiết bị',
    holeSetting: 'Cài đặt khoan động',
    raminoSetting: 'Cài đặt kết nối Ramino',
    mortiseTenonSetting: 'Cài đặt kết nối móc卯',
    coordinateValue: 'Giá trị tọa độ',
    codeFormat: 'Định dạng mã hóa tập tin',
    suffixSetting: 'Cài đặt hậu tố tập tin',
    insertRow: 'Chèn hàng',
    deleteRow: 'Xóa hàng',
    equipmentParams: {
      equipPauseCode: 'Mã tạm dừng thiết bị',
      wiggleCode: 'Mã dao động',
      equipStartCode: 'Mã khởi động thiết bị:',
      flipPauseCode: 'Mã tạm dừng khi lật mặt:',
      equipEndCode: 'Mã kết thúc thiết bị:',
      equipStartCodePlaceholder: 'Nhập mã khởi động thiết bị',
      flipPauseCodePlaceholder: 'Nhập mã tạm dừng khi lật mặt',
      equipEndCodeTip: 'Nhập mã kết thúc thiết bị',
      sideChangeSetting: 'Cài đặt đổi mặt bên',
      workTip: 'Mặc định bắt đầu từ bên trái ① theo hướng nghịch hành tròn',
      frontTurnCode: 'Mã bắt đầu mặt trên:',
      frontTurnEndCode: 'Mã kết thúc mặt trên',
      frontTurnCodePlaceholder: 'Nhập mã bắt đầu mặt trên',
      frontTurnEndCodePlaceholder: 'Nhập mã kết thúc mặt trên',
      leftTurnCode: 'Mã bắt đầu mặt trái:',
      leftTurnEndCode: 'Mã kết thúc mặt trái:',
      leftTurnCodePlaceholder: 'Nhập mã bắt đầu mặt trái',
      leftTurnEndCodePlaceholder: 'Nhập mã kết thúc mặt trái',
      downTurnCode: 'Mã bắt đầu mặt dưới:',
      downTurnEndCode: 'Mã kết thúc mặt dưới:',
      downTurnCodePlaceholder: 'Nhập mã bắt đầu mặt dưới',
      downTurnEndCodePlaceholder: 'Nhập mã kết thúc mặt dưới',
      rightTurnCode: 'Mã bắt đầu mặt phải:',
      rightTurnEndCode: 'Mã kết thúc mặt phải:',
      rightTurnCodePlaceholder: 'Nhập mã bắt đầu mặt phải',
      rightTurnEndCodePlaceholder: 'Nhập mã kết thúc mặt phải',
      topFlipCode: 'Mã lật mặt - trên',
      downFlipCode: 'Mã lật mặt - dưới',
      leftFlipCode: 'Mã lật mặt - trái',
      rightFlipCode: 'Mã lật mặt - phải',
      cylinderOutCode: 'Mã tung xi lanh',
      cylinderbackCode: 'Mã thu xi lanh',
      cylinderbackDirective: 'Chỉ thị thu xi lanh',
      moveDirective: 'Chỉ thị di chuyển',
      moveAxisCode: 'Trục di chuyển',
      pushAxisCode: 'Trục đẩy xi lanh',
      swingAxisCode: 'Trục dao động',
      moveCode: 'Mã di chuyển',
      punchCode: 'Mã gia công',
      free: 'Di chuyển không gia công',
      punchPart: 'Phần gia công',
      cutFaceCode: 'Mã gia công mặt phẳng',
      horVerCutFace: 'Mặt phẳng ngang và dọc',
      horCutFace: 'Mặt phẳng ngang',
      verCutFace: 'Mặt phẳng dọc',
    },
    tagPool: 'Hồ bơi nhãn dữ liệu',
    dragTip: 'Có thể kéo nhãn từ hồ bơi nhãn dữ liệu',
    knives: 'Công cụ gia công:',
    directives: 'Chỉ thị gia công:',
    slot: 'Gia công khe thẳng',
    cirWork: 'Gia công vòng tròn',
    cirInter: 'Chèn bù vòng tròn',
    cirOption1: 'Thoáng tay: G03 Ngược thoáng tay: G02',
    cirOption2: 'Thoáng tay: G02 Ngược thoáng tay: G03',
    coordinate: {
      xPointSymbol: 'Tọa độ ngang:',
      yPointSymbol: 'Tọa độ dọc:',
      zPointSymbol: 'Tọa độ thăng hạ:',
      upright: 'Giá trị dương',
      negative: 'Giá trị âm',
    },
    encodeFormat: {
      format: 'Định dạng mã hóa tệp:',
      wrap: 'Cài đặt định dạng ký tự xuống dòng:',
      formatOption1: 'Định dạng UTF-8',
      formatOption2: 'Định dạng ANSI',
    },
    suffix: 'Định dạng hậu tố tệp:',
    enableFP: 'Kích hoạt gia công mặt trước',
    slotSetting: 'Cài đặt khe nhú',
    takeUpDirective: 'Chỉ thị thu dao',
    workSeparately: 'Gia công khe thông hai mặt thành hai đoạn',
    secondSlotLong: 'Độ dài khe đoạn thứ hai',
    enableOld: 'Kích hoạt cài đặt máy khoan bên cũ',
    default: 'Cài đặt mặc định',
    customizable1: 'Tùy chỉnh 1',
    customizable2: 'Tùy chỉnh 2',
    customizable3: 'Tùy chỉnh 3',
    customizable4: 'Tùy chỉnh 4',
    knifeSetting: 'Cài đặt dao',
    soltTip: '(Bao gồm khai khoan/khe)',
    specialSetting: 'Cài đặt đặc biệt',
    ramino: 'Ramino',
    sun: 'Sūn Mǎo',
    please: 'Xin vui lòng chờ đợi',
    designatedKnives: 'Dao chỉ định',
    designated: 'Chỉ định',
    workOrder: 'Lệnh gia công',
    other: 'Khác',
    nameSetting: 'Cài đặt tên hậu tố',
    repeatErr: 'Số biên hiệu trùng lặp, vui lòng cài đặt lại',
  },
  // 电子锯
  electronicSaw: {
    importMaterial: 'Nhập danh sách nguyên liệu',
    formSetting: 'Cài đặt biểu mẫu máy cưa điện tử',
    fileFormat: 'Định dạng tệp danh sách tài liệu màu cắt:',
    isSmallFile: 'Xuất tệp hình ảnh tấm nhỏ:',
    fileGroupRule:
      'Tệp máy cưa điện tử được phân chia thành các biểu mẫu khác nhau dựa trên màu sắc, chất liệu, độ dày tấm:',
    roomsByCategory:
      'Máy cưa điện tử tập tin theo các hình thức khác nhau của phòng:',
    delimiter: 'Dấu phân cách:',
    fileName: 'Tên tệp danh sách tài liệu màu cắt:',
    fineNameTip: 'Mở danh sách tập tin tên không phải là trống rỗng!',
    fileNamePlaceholder: 'Tên tùy chỉnh danh sách tài liệu màu cắt',
    outputFileEncoding: 'Định dạng mã hóa tệp đầu ra:',
    utf8: 'UTF-8 với BOM',
    cellTab: 'Tạo dấu tab ô biểu:',
    texture: 'Biểu diễn kết cấu:',
    special: 'Biểu diễn hình không đều:',
    notSpecial: 'Không phải hình không đều',
    genSimpleLabelBarcode: 'Tạo mã vạch nhãn mãc đơn giản',
    uniqueBarCode: 'Mã Vạch Bàn Duy Nhất',
    uniqueBarCodeTip:
      'Khi được kích hoạt, chức năng tạo mã vạch nhãn mãc đơn giản sẽ không được kích hoạt',
    customeForm: 'Biểu mẫu tùy chỉnh',
    tagPool: 'Hồ bơi nhãn dữ liệu',
    tagPoolTips: 'Có thể kéo hoặc nhấn để sao chép nhãn từ hồ bơi nhãn dữ liệu',
    displayHead: 'Không hiển thị tiêu đề biểu',
    addTableHead: 'Thêm tiêu đề biểu',
    deleteTableHead: 'Xóa tiêu đề biểu',
    generateExample: 'Tạo mẫu biểu mẫu đầu ra',
    importExample: 'Nhập mẫu biểu mẫu',
    importExampleTip:
      'Mẫu biểu mẫu nhập, chỉ dùng làm tham khảo cho máy cưa điện tử tùy chỉnh',
    importExampleErrTip:
      'Chưa nhập danh sách nguyên liệu, vui lòng nhập danh sách nguyên liệu',
    outputExample: 'Mẫu biểu mẫu đầu ra',
    outputExampleTip: 'Mẫu đầu ra của biểu mẫu tùy chỉnh',
    outputExampleErrTip:
      'Chưa tạo mẫu biểu mẫu đầu ra, vui lòng tạo mẫu biểu mẫu đầu ra',
    defaultTableHead: 'Tiêu đề biểu mặc định',
    deleteHeadErrTip: 'Không có tiêu đề biểu để xóa!',
  },
  // 锁定弹窗组件
  lockModal: {
    passwordInput: 'Vui lòng nhập mật khẩu',
    lockPasswordInput: 'Vui lòng nhập mật khẩu mở khóa',
    passwordInputPlaceholder: 'Vui lòng nhập mật khẩu bốn chữ số',
    editPassword: 'Chỉnh sửa mật khẩu',
    confirmPassword: 'Xác nhận mật khẩu',
    setLockPwd: 'Đặt mật khẩu mở khóa',
    pwdFormatErr: 'Định dạng mật khẩu không đúng',
    sendMessageTo: 'Mã xác nhận SMS sẽ được gửi đến',
    captchaPlaceholder: 'Vui lòng nhập mã xác nhận',
    sendCaptcha: 'Gửi mã xác nhận',
    sendSeccess: 'SMS đã được gửi thành công',
    sendFail: 'Gửi SMS thất bại, vui lòng thử lại sau',
    updateSeccess: 'Đã thành công trong đặt mật khẩu',
    editSuccess: 'Đã thành công trong chỉnh sửa mật khẩu',
    lockSeccess: 'Mở khóa thành công',
    lock: 'Mở khóa',
    lockSeccessTip: 'Đã thành công trong khóa',
    inputErrLimit:
      'Đã đạt giới hạn lỗi hiện tại, vui lòng đặt lại mật khẩu mở khóa!',
  },
  // 五六面钻
  dock56Drill: {
    equipType: 'Loại thiết bị',
    equipment: 'жабдық',
    fiveDock: 'Boring machine 5 mặt',
    sixDock: 'Boring machine 6 mặt',
    fileType: 'Loại tệp tạo ra',
    templateOffline: 'Mẫu đã ngừng sử dụng!',
    showExample: 'Hiển thị mẫu dữ liệu',
    conNoticeTip: 'Chú ý khi đối tiếp:',
    conNoticeTip1:
      "1. Máy điêu khắc có 'Hoán đổi trục XY' cần sử dụng mẫu chuyên dụng cho boring machine 5/6 mặt có hoán đổi mặt trước và mặt sau",
    conNoticeTip2:
      '2. Để hiển thị hoặc gia công phần hình không đều của tấm, cần cấu hình dữ liệu phần hình không đều trong mẫu',
    conNoticeTip3:
      '3. Khi sử dụng boring machine 5/6 mặt lần đầu hoặc thay đổi mẫu, vui lòng <span class="note">thử sản xuất tủ thử nghiệm</span>, sau đó tiến hành sản xuất thông thường khi đã xác nhận không có lỗi',
    conNoticeTip4:
      '4. Khi thử sản xuất, vui lòng kiểm tra kỹ kích thước, độ sâu, mặt, và vị trí của lỗ và khe trên mặt trước và mặt sau, <span class="note">để tránh lỗi hình ảnh phản chiếu của tủ</span>',
    example: 'Mẫu dữ liệu',
    commonSetting: 'Cài đặt thông thường',
    throughHoleSetting: 'Lỗ thông điền hai mặt',
    throughSlotSetting: 'Khe thông điền hai mặt',
    nonHolesNotFiles: 'Tấm hình chữ nhật không có lỗ hoặc khe không xuất tệp:',
    frontFileIdentifier: 'Đánh dấu tệp mặt trước:',
    oppositeFileIdentifier: 'Đánh dấu tệp mặt sau:',
    oppositeFileIdentifierTips:
      'Định dạng tạo: Mã tấm 13 chữ số gốc + ký tự đánh dấu',
    arcDescription: 'Cách tả vòng tròn',
    linePoints: 'Cách tả hình thành đoạn thẳng',
    arcCommand: 'Cách tả chỉ lệnh vòng tròn',
    slotWidthSplit: 'Phân giải khe rộng thành nhiều khe hẹp để gia công:',
    slotSplitWidth: 'Khe đơn sau khi phân giải có chiều rộng:',
    mm: 'Chú ý: Đối tượng phân giải là khe hình chữ nhật thông thường',
    isNotMergeSlot: 'Kết nối khe có cùng chiều rộng và cùng chiều sâu:',
    slotPunchTip:
      'Chức năng này chỉ có hiệu lực với khe backboard và khe đáy tủ kéo sử dụng mẫu tùy chỉnh',
    sizeRange: 'Kích thước liền kề nhỏ hơn hoặc bằng',
    enableWorningTip:
      'Chức năng này không thể đồng thời kích hoạt với hướng đặt cạnh trước',
    longDirection: 'Hướng đặt cạnh dài tấm',
    horizontal: 'Ngang',
    vertical: 'Dọc',
    slotDirection:
      'Hướng đặt khe hoặc hình không đều của boring machine 5/6 mặt',
    default: 'Mặc định hệ thống',
    frontLeft: 'Trước và trái',
    frontRight: 'Trước và phải',
    backLeft: 'Hậu và trái',
    backRight: 'Hậu và phải',
    frontDirection: 'Hướng đặt cạnh trước',
    enableTip: 'Chú ý: Chỉ có hiệu lực với bố cục đám mây',
    fixedOn: 'Định vị trên:',
    front: 'Mặt trước',
    back: 'Mặt sau',
    left: 'Trái',
    right: 'Phải',
    coordinateValue: 'Số chữ số giữ lại trong giá trị tọa độ:',
    decimalPlaces: 'chữ số',
    specialParameter: 'Cài đặt tham số đặc biệt',
    output5FDrillSlotKnifeCode: 'Mã dao cắt khe XML2',
    output5FDrillShapeKnifeCode: 'Mã dao cắt hình XML2',
    plankTexture: 'Kết cấu bảng XML2',
    offset: 'Bù trù dao cắt hình XML2',
    mprConHoleDir: 'Mặt chuẩn Z của các lỗ bên của tập tin MPR 5/6 mặt khoan',
    plankTop: 'Mặt trên của bảng',
    plankBottom: 'Mặt dưới của bảng',
    codeInfo: 'Tập tin định dạng ban chứa thông tin Mã:',
    bomFile: 'Xuất tập tin định dạng bom:',
    plankHeight: 'Mô tả chiều cao của bảng:',
    plankNum: 'Mô tả số hiệu bảng:',
    backZpoint: 'Tọa độ Z của khe sau:',
    plankThick: 'Độ dày của bảng',
    slotDeep: 'Độ sâu của khe',
    outputMprFile: 'Loại tập tin',
    oldMPR: 'MPR cũ',
    newMPR: 'MPR mới',
    mprFileZside: 'Mặt chuẩn Z của các lỗ bên của tập tin MPR 5/6 mặt khoan',
    fileTypeOffline:
      'Loại tập tin khoan 5/6 mặt đã ngừng sử dụng, vui lòng xác nhận!',
    permissionErr:
      'Thông tin nhận dạng đã mất, vui lòng mở lại trang này từ cabine!',
    banFileSetting: 'Cài đặt tập tin định dạng ban',
    banFileCodeInfo: 'Tập tin định dạng ban chứa thông tin Mã:',
    huahuaLine: 'Dây chuyền tự động Huahua',
  },
  // PTP
  PTP: {
    title: 'PTP',
    outputFileFormat: 'Định dạng tập tin đầu ra',
    outputDefaultFileFormat: 'Đầu ra tập tin định dạng mặc định',
    outputMPRFormat: 'Đầu ra tập tin định dạng MPR',
    fileFormatSettings: 'Cài đặt định dạng tập tin',
    wrapSettings: 'Cài đặt định dạng dòng mới:',
    enCodeSettings: 'Cài đặt định dạng mã hóa tập tin:',
    utf8bom: 'UTF-8 với BOM',
    partCoordinate: 'Tọa độ phẳng nhỏ',
    xPointSymbol: 'Tọa độ ngang:',
    yPointSymbol: 'Tọa độ dọc:',
    zPointSymbol: 'Tọa độ thang máy:',
    upRight: 'Giá trị dương',
    negative: 'Giá trị âm',
    plankMirroring: 'Hình ảnh phản chiếu phẳng',
    plankMirrorSetting: 'Cài đặt hình ảnh phản chiếu phẳng:',
    xAxis: 'Hình ảnh phản chiếu trục X',
    yAxis: 'Hình ảnh phản chiếu trục Y',
    mirrorTip: '（Sử dụng chức năng này khi hình ảnh phản chiếu phẳng）',
    knifeTable: {
      type: 'Loại',
      diameter: 'Đường kính Φ',
      offsetX: 'Điểm dịch chuyển X',
      offsetY: 'Điểm dịch chuyển Y',
      offsetZ: 'Điểm dịch chuyển Z',
      offsetNo: 'Số hiệu dịch chuyển',
      drillVelocity: 'Tốc độ xuống dao [F] mm/phút',
      cutVelocity: 'Tốc độ gia công [F] mm/phút',
      rotationSeep: 'Tốc độ quay [S] vòng/phút',
      insertRow: 'Chèn hàng',
      deleteRow: 'Xóa hàng',
    },
    labelPool: 'Hồ bể nhãn mã dữ liệu',
    dragTip:
      'Có thể kéo hoặc nhấn để sao chép nhãn từ hồ bể nhãn mã dữ liệu vào ô đầu vào',
    procedure: 'Chương trình',
    beginCode: 'Mã bắt đầu chương trình:',
    endCode: 'Mã kết thúc chương trình:',
    zzdKnifeBeginCode: 'Mã bắt đầu thay dao trục chính:',
    spindleCutter: 'Dao trục chính',
    zzdKnifeEndCode: 'Mã kết thúc thay dao trục chính:',
    verticalDrill: 'Khoan thẳng đứng',
    czzBeginCode: 'Mã khởi động khoan thẳng đứng:',
    czzEndCode: 'Mã kết thúc khoan thẳng đứng:',
    czzKnifeBeginCode: 'Mã bắt đầu thay dao khoan thẳng đứng:',
    czzKnifeEndCode: 'Mã kết thúc thay dao khoan thẳng đứng:',
    sidetrack: 'Khoan bên',
    czBeginCode: 'Mã khởi động khoan bên:',
    czEndCode: 'Mã kết thúc khoan bên:',
    czKnifeBeginCode: 'Mã bắt đầu thay dao khoan bên:',
    czKnifeEndCode: 'Mã kết thúc thay dao khoan bên:',
    pauseCode: 'Mã tạm dừng:',
    zSafeHeight: 'Chiều cao an toàn trục Z',
    auxiliaryFunction: 'Chức năng phụ trợ',
    xhDedicatedInfo: 'Tạo thông tin chuyên dụng cho thiết bị Xinghui',
    xhCheckBox: 'Dữ liệu:',
    outputSettings: 'Cài đặt đầu ra:',
    partExegesis: 'Tạo chú thích kích thước phẳng nhỏ trong tập tin',
    knifeNo: 'Số hiệu dao',
    specailKnifeNo: 'Số hiệu dao gia công hình dị:',
    slotKnifeNo: 'Số hiệu dao khoan khe:',
    fileNamePlaceholder: 'Tên tự định danh sách nguyên liệu cắt',
    slotDrill: 'Dao kéo thẳng',
  },
  // 玻璃切割机
  glassEquip: {
    title: 'Máy cắt kính',
    fileType: 'Loại tập tin gia công:',
    sizeReparation: 'Bù đắp kích thước tấm:',
    cutPreSpace: 'Khoảng cách dự trữ cắt phẳng:',
    divideIntoFolders:
      'Phân loại vào các thư mục khác nhau dựa trên màu sắc, chất liệu, độ dày của kính:',
  },
  translate: {
    search: 'Nhập tiếng Trung hoặc dịch chính thức để tìm từ điển',
    en: 'Tiếng Anh',
    kz: 'Tiếng Kazakh',
    vi: 'Tiếng Việt',
    customTranslate: 'Nhấn để thêm dịch tùy chỉnh',
    customText: 'Dịch tùy chỉnh',
    text: 'Tiếng Trung',
    targetLang: 'Dịch chính thức',
  },
  preLayoutSetting: {
    title: 'Cài đặt bố trí trước',
    titleTip:
      'Có thể thiết lập trước các thông số bố trí, trước tiên bố trí, sau đó chọn dây chuyền sản xuất',
    settingTip:
      'Các thông số thiết lập trong dây chuyền sản xuất cần phải phù hợp với các thông số bố trí trước dưới đây để có thể xuất tệp sản xuất, chỉ áp dụng cho máy cắt',
    emptyKnife: 'Chưa điền dao cắt',
    noModify: 'Thiết lập không có thay đổi, không cần lưu',
    reSet: 'Khôi phục',
    gayPosition: 'Khối màu xám có thể chuyển đổi vị trí đặt dư liệu',
    surplusPosition: 'Là vị trí đặt dư liệu hiện tại',
    click: 'Nhấn vào',
    grayStart: 'Khối màu xám có thể chuyển đổi điểm bắt đầu của công đoạn',
    currentStart: 'Là điểm bắt đầu của công đoạn hiện tại',
    holeSlotSetting: 'Cài đặt gia công thẳng, khoan lỗ và rãnh',
    knifeDiameter: 'Đường kính dao cắt',
    currentVersion: 'Phiên bản hiện tại',
    on: 'Bật',
    off: 'Tắt',
    firstShort: 'Cắt lần đầu - Dự phòng cạnh ngắn',
    firstLong: 'Cắt lần đầu - Dự phòng cạnh dài',
    cuttingSplitLong: 'Cắt bảng dài làm hai lần - Cạnh dài vượt quá',
    cuttingSplitShort: 'Cắt bảng dài làm hai lần - Cạnh ngắn vượt quá',
    plankThick: 'Độ dày bảng thông thường ≥ ',
    normalPlank: 'Bảng thông thường',
    confilictTip: 'Có xung đột cài đặt: {confilictTotal} mục',
    selectLine: 'Chọn dây chuyền sản xuất',
    selectLinePlaceholder: 'Vui lòng chọn dây chuyền sản xuất',
    lineConfilictTip: 'Lựa chọn dây chuyền sản xuất có xung đột với {line}',
    preWay: 'Phương án bố trí trước',
    paibanErrTip:
      'Khi thay đổi lượng dư của cạnh dài và cạnh ngắn của bảng dài, có thể dẫn đến thất bại trong bố trí',
    paibanTip: 'Bố trí lần này có sử dụng dao cắt vật liệu lớn, cần bố trí lại',
    reLayout: 'Bố trí lại theo dây chuyền sản xuất hiện tại',
    fetchErr: 'Lấy cài đặt bố trí trước thất bại',
    resetSuccess: 'Khôi phục thành công',
    resetConfirm: 'Bạn có chắc chắn muốn khôi phục về phiên bản lịch sử không?',
    prolineName: 'Tên dây chuyền sản xuất',
    layoutGap: 'Khoảng cách bố trí',
    generalThickness: 'Độ dày bảng thông thường - Đối tượng cắt lần hai',
    cutGeneralPlank: 'Cắt bảng thông thường - Đối tượng cắt lần hai',
    cutSingleDoor: 'Cắt cửa đơn - Đối tượng cắt lần hai',
    changeProline: 'Chuyển đổi dây chuyền sản xuất',
    backTip: 'Vui lòng quay lại bố trí tổng thể để chọn dây chuyền sản xuất',
    preLayout: 'Bố trí theo kế hoạch bố trí trước',
    preWayEmpty: '(Trống)Kế hoạch bố trí trước',
    specialConfilictTip:
      'Dây chuyền sản xuất đã chọn ảnh hưởng đến các thông số quy trình phay đáy cung, cần bố trí lại',
  },
  lunbo: {
    tips: 'Kỹ năng thao tác',
    introduction: 'Giới thiệu chức năng',
    qtDownloadText:
      'Lời nhắc ấm áp: Nếu xảy ra trường hợp tải xuống tập tin không đầy đủ, vui lòng truy cập vào trang web trình biên soạn đám mây để tải xuống!',
  },
}
